@font-face {
    font-family: 'Neon';
    src: url('./assets/fonts/Neon-Regular.woff') format('woff'), url('./assets/fonts/Neon-Regular.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Neon';
    src: url('./assets/fonts/Neon-Medium.woff') format('woff'), url('./assets/fonts/Neon-Medium.woff2') format('woff2');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Neon';
    src: url('./assets/fonts/Neon-Black.woff') format('woff'), url('./assets/fonts/Neon-Black.woff2') format('woff2');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: url('./assets/fonts/Montserrat-Regular.woff') format('woff'), url('./assets/fonts/Montserrat-Regular.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: url('./assets/fonts/Montserrat-Medium.woff') format('woff'), url('./assets/fonts/Montserrat-Medium.woff2') format('woff2');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: url('./assets/fonts/Montserrat-Bold.woff') format('woff'), url('./assets/fonts/Montserrat-Bold.woff2') format('woff2');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

.kaUdeg:hover .knTWyw a {
    color: #000 !important;
}

.text-blue {
    color: #11D6B9;
}

.text-yellow {
    color: #fdbc64;
}

.text-purple {
    color: #1554F0;
}

.text-orange {
    color: #f9896b;
}

h1 {}

h2 {
    font-size: 26px;
    line-height: 35px;
    margin: 0;
    font-weight: 900;
    font-family: 'Neon';

}

h3 {
    font-size: 24px;
    font-weight: 900;
    line-height: 38px;
    margin: 0;
    font-family: 'Neon';

}

h4 {
    font-size: 22px;
    font-weight: 900;
    line-height: 32px;
    margin: 0;
    font-family: 'Neon';

}

h5 {
    font-size: 20px;
    line-height: 30px;
    font-weight: 900;
    margin: 0;
    font-family: 'Neon';

}

p {
    margin-bottom: 0;
}

a {
    text-decoration: none;
    color: #fff;
}

a:hover {
    text-decoration: none;
    cursor: pointer;
    color: inherit;
}

a:active,
a:focus {
    color: inherit;
    text-decoration: none;
}

ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

body {
    font-family: "Montserrat";
    font-size: 16px;
    color: #F2F2F2;
    background-color: #1A1A1A;
    overflow-wrap: break-word;
}

body .wrapper {
    /* overflow-x: hidden;
    position: relative;
    min-height: 100vh; */
    overflow-x: hidden;
    overflow-y: clip;
    position: relative;
    min-height: 100vh;
}

.wrapper::before {
    content: '';
    background: url('./assets/images/home-overlay.png') repeat;
    background-position: top center;
    background-size: contain;
    width: 100%;
    height: 100%;
    /* position: absolute; */
    position: fixed;
    left: 0;
    top: 0;
    /* opacity: 0.5; */
    z-index: -1;
}

.custom-padding {
    padding: 0 60px;
}

.btn-gradient-pink {
    background: transparent linear-gradient(72deg, #fd24d8 0%, #fed130 100%) 0% 0% no-repeat padding-box;
    border-radius: 0;
    min-width: 220px;
    font-weight: 500 !important;
    margin-left: 50px;
    border: none;
}

.delete_button {
    background: rgb(183, 0, 0);
    border-radius: 0;
    min-width: 105px;
    font-size: 14px;
    margin-left: 50px;
    border: none;
    float: right;
    border-radius: 4px;
    font-weight: 600;
}

.delete_button:hover {
    background: rgb(251, 3, 3);
}

.request-status {
    color: #ffffff;
    background-color: green;
    padding: 3px 8px;
    border-radius: 5px;
}

.btn-gradient-blue {
    background: transparent linear-gradient(64deg, #8136fb 0%, #2be7fd 100%) 0% 0% no-repeat padding-box;
    border-radius: 4px;
    font-weight: 500 !important;
    padding: 7px 15px;
    color: #fff;
    border: none;
}

audio {
    width: 100%;
    height: 30px;
    background: aliceblue;
    border-radius: 5px;
    margin-top: 8px;
}

.btn-gradient-red {
    background: transparent linear-gradient(64deg, #f80000 0%, #9f0b0b 100%) 0% 0% no-repeat padding-box;
    border-radius: 4px;
    font-weight: 500 !important;
    padding: 7px 15px;
    color: #fff;
    border: none;
}

.btn-dark {
    background: rgba(132, 233, 244, 0.1);
    color: #84e9f4;
}

.btn-dark:hover {
    background: rgba(132, 233, 244, 0.5);
}

.header .custom-padding {
    padding-right: 0;
}

.header .navbar-brand {
    color: rgb(255 255 255);
    font-weight: 500;
    font-size: 22px;
    display: flex;
    align-items: center;
}

.header .navbar-brand:hover {
    color: #fff;
}

.header .navbar-brand .image {
    width: 50px;
    height: 50px;
    margin-right: 5px;
}

.header .navbar-brand .image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.header .navbar-toggler-icon {
    filter: invert(1);
}

.header .navbar-nav .nav-link {
    color: rgb(255 255 255);
    padding: 30px 30px;
    font-weight: 500;
}

.header .navbar-nav .nav-link:focus {
    color: rgb(255 255 255);
}

.header .navbar-nav .nav-link:hover {
    color: #fff;
}

.breadcrum {
    background: #2a2d3a6b;
    margin-bottom: 30px;
}

.breadcrum ul {
    display: flex;
    padding: 12px 0;
    color: #ffffff80;
}

.breadcrum ul li a {
    color: #fff;
    text-decoration: none;
    margin-right: 20px;
}

.loadingClass.spinner-border {
    width: 1.5rem !important;
    height: 1.5rem !important;
    margin: 5px;
}

.loadingClass.spinner-grow {
    width: 1.4rem !important;
    height: 1.4rem !important;
    /* margin: auto;
    margin-inline: 6px;
    padding: 0.4rem; */
    background-color: #757575;
    /* border: 1px solid; */
    /* margin-right: 8px; */
    /* border-color: #757575; */
}

.sharing-grow {
    background-color: transparent !important;
    margin: 0px !important;
    width: 0.8rem !important;
    height: 0.8rem !important;
    margin-right: 1px !important;
}

.loadingClass.spinner-grow.text-danger {
    /* border-color: #bb0000; */
}

.loadingClass.spinner-grow.text-success {
    /* border-color: #1d910e; */
}

.main-profile {
    margin-bottom: 20px;
}

.leaderboard-table .user-details {
    display: flex;
    align-items: center;
}

.leaderboard-table .user-details .image {
    width: 50px;
    height: 50px;
}

.leaderboard-table .user-details .image img {
    height: 100%;
    object-fit: cover;
}

.leaderboard-table .user-details .user-info {
    text-align: left;
    margin-left: 10px;
    margin-bottom: 0;
}

.leaderboard-table .user-details .user-info h5 {
    font-family: 'Montserrat';
    font-weight: 500;
    font-size: 14px;
}

.leaderboard-table .user-details .user-info span {
    font-size: 12px;
}

.badges ul {
    display: flex;
    justify-content: space-evenly;
}

.badges ul li {
    background: #ffffff14;
    width: 35px;
    height: 35px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.badges ul li img {
    width: 22px;
}

.section-dark-bg {
    background: #2a2d3a6b;
    box-shadow: 0px 8px 21px #00000045;
    /* border-radius: 12px; */
    padding: 25px;
    height: 100%;
}

.section-dark-bg .heading,
.section.heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-items: center;
    margin-bottom: 30px;
    flex-wrap: wrap;
}

.section-dark-bg .heading .view-all,
.section.heading .view-all {
    text-decoration: none;
    font-size: 14px;
    color: rgb(255, 255, 255, 0.5);
    font-weight: 500;
}

.section-dark-bg .heading .view-all:hover,
.section.heading .view-all:hover {
    color: #fff;
}

/* edit-avatr-modal */
.d-edit-image .overlay {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #343a40;
    overflow: hidden;
    width: 100%;
    height: 0;
    transition: 0.5s ease;
}

.image.d-edit-image:hover .overlay {
    height: 100%;
}

.video-uploader {
    position: absolute;
    top: 44%;
    /* left: 0;
    right: 0; */
    /* bottom: 0; */
    color: aliceblue;
}

.hove-edit {
    color: white;
    font-size: 20px;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    text-align: center;
}

.hove-edit button {
    font-size: 14px;
}

.post-submittioins .sd-panel__header h4 {
    display: none !important;
}

.sd-paneldynamic__buttons-container .sd-action-bar {
    /* margin-left: 8px !important; */
    width: 100% !important;
    margin: 0 auto !important;
}

.sd-paneldynamic__panel-footer {
    margin-right: 0 !important;
}

.terms-list {
    list-style-type: disc !important;
    margin-left: 20px;
}

#edit-avatr-modal .modal-content {
    background-color: #1a1a1a !important;
    box-shadow: 0px 8px 21px #00000045;
    border: 1px solid #1554F0;
    border: 10px solid;
    border-image-slice: 1;
    border-width: 2px;
    border-image-source: linear-gradient(79deg, #1554F0 0%, #11D6B9 100%);
    border-radius: 0;
}

#edit-avatr-modal .modal-header {
    border-bottom: 1px solid hsla(0, 0%, 100%, 0.1) !important;
    background: transparent linear-gradient(79deg, #1554F0 0%, #11D6B9 100%) 0% 0% no-repeat padding-box;
    border-radius: 0;
    margin-bottom: 10px;
}

#edit-avatr-modal .modal-header .btn-close {
    filter: invert(1);
}

#edit-avatr-modal .title {
    /* color: #11D6B9; */
    margin-bottom: 20px;
    font-size: 32px;
    line-height: 45px;
    font-weight: 500;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-bottom: 0;
}

#edit-avatr-modal .modal-footer {
    border-top: 1px solid hsla(0, 0%, 100%, 0.1);
}

/* .d-edit-image img{background-color: red;} */
img.select-img {
    border: 2px solid transparent;
    -webkit-transition: 0.3s ease-in-out;
    transition: 0.3s ease-in-out;
}

img.select-img:hover {
    cursor: pointer;
    border: 2px solid #0d6efd;
    border-radius: 18px;
    transform: scale(1.1);
}

/*  */
.user-info .user-about {
    display: flex;
    margin-bottom: 30px;
}

.user-info .user-about .image {
    width: 100px;
    height: 100px;
    margin-right: 30px;
    position: relative;
    /* background: transparent linear-gradient(0deg, #FD24D8 0%, #FED130 100%) 0% 0% no-repeat padding-box;
  clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
  padding: 7px; */
}

.user-info .user-about .image img {

    position: relative;
    height: 100%;
    width: 100%;
    object-fit: cover;
    clip-path: circle;
    left: 50%;
    transform: translate(-50%);
}

.about-details p {
    opacity: 0.75;
    margin-bottom: 15px;
}

.user-stats ul {
    display: flex;
    flex-wrap: wrap;
}

.user-stats ul li {
    width: 50%;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    height: 85px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.user-stats ul li h4 {
    font-size: 18px;
}

.user-stats ul li span {
    font-size: 14px;
    font-weight: 500;
    opacity: 0.3;
}

.user-stats ul li:first-child {
    border-right: 1px solid rgba(255, 255, 255, 0.1);
}

.user-stats ul li:nth-child(2) {
    padding-left: 20px;
}

.user-stats ul li:nth-child(3) {
    border-bottom: none;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    border-right: 1px solid rgba(255, 255, 255, 0.1);
}

.user-stats ul li:last-child {
    border-bottom: none;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding-left: 20px;
}

.badges-earned {
    margin-top: 20px;
    margin-bottom: 20px;
}

.notification-section {
    padding: 0 12px;
}

.badges-list ul {
    display: flex;
    flex-wrap: wrap;
}

.badges-list ul li {
    /* margin-right: 10px; */
    width: 25%;
    /* height: 30px; */
    /* background: rgba(47, 47, 54, 1); */
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px;
    margin-bottom: 10px;
    border-radius: 50%;
}

.shine {
    position: relative;
    border-radius: 50%;
    overflow: hidden;
}

.shine::before {
    content: '';
    position: absolute;
    top: 0;
    left: -38px;
    width: 25px;
    height: 100%;
    background: rgba(255, 255, 255, 0.3);
    transform: skewX(-30deg);
    transition: 1s;
}

.shine:hover::before {
    left: 100px !important;
}

.widget_create_post {
    position: relative;
    z-index: 3;
    padding: 80px 20px 20px;
    text-align: center;
    font-size: 17px;
}

.widget {
    clear: both;
    display: block;
    margin-bottom: 40px;
    width: 100%;
    height: 300px;
    padding: 0;
    background: #333134;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px;
    border-radius: 14px;
}

.widget .icon-bg {
    position: relative;
    width: 80px;
    height: 62px;
    border-radius: 16px;
    background-color: #2967f1;
    margin-bottom: 40px;
}

.widget .icon-bg .icon {
    position: absolute;
    color: #fff;
    left: 50%;
    transform: translateX(-50%);
    bottom: -15px;
}

.widget .icon-bg .icon svg {
    font-size: 45px;
}

.widget_create_post:before {
    /* content: "\f067";
    font-family: "FontAwesome";
    position: absolute;
    z-index: 2;
    top: -8px;
    left: 50%;
    transform: translateX(-50%);
    width: 65px;
    height: 65px;
    line-height: 78px;
    font-size: 35px;
    text-align: center;
    vertical-align: middle;
    border-radius: 50%;
    background: #ffffff;
    color: #3858f6;
    ;
    box-shadow: 1px 2px 2px rgb(0 0 0 / 18%);
    display: flex;
    justify-content: center;
    align-items: center; */
}

.widget-title {
    position: relative;
    margin-bottom: 15px;
    text-transform: uppercase;
    text-align: center;
    font-size: 22px;
    font-weight: 600;
}

.widget_create_post .text {
    margin-bottom: 25px;
    font-size: 18px;
    line-height: 20px;
    color: #a3a3a3;
}

.widget_create_post a {
    /* margin-bottom: 3px;
    padding: 13px 35px;
    text-align: center;
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    text-transform: uppercase;
    vertical-align: middle;
    display: inline-block;
    border: 1px solid transparent;
    cursor: pointer;
    font-family: inherit;
    background-color: #cdcdcd;
    color: #fff;
    transition: all .2s ease-out;
    text-decoration: none;
    color: #3858f6;
    background-color: #ffffff; */
}

.widget_create_post:after {
    /* content: "";
    position: absolute;
    z-index: -1;
    top: 25px;
    bottom: 0;
    left: 0;
    width: 100%;
    border: 5px solid #ececec;
    border-radius: 6px;
    background-color: #333134;
    border-color: #333134;
    min-height: 190px; */

}


/* .badges-list ul .shine {
	position: relative;
	overflow: hidden;
}

.badges-list ul .shine::before {
    background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0.13) 0%,
    rgba(255, 255, 255, 0.13) 77%,
    rgba(255, 255, 255, 0.5) 92%,
    rgba(255, 255, 255, 0.0) 100%
  ) !important;
    content: "";
    display: block;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    transform: skewX(-27deg);
    width: 50%;
    z-index: 2;
}

.badges-list ul .shine::before,
.badges-list ul .shine:focus::before {
    animation: shine 2s infinite;
	}   
    @keyframes shine {
		100% {
			left: 125%;
		}
	} */



.about-sec p {
    opacity: 0.4;
}

.stats-sec {
    margin-bottom: 20px;
}

.stats-sec .heading ul {
    display: flex;
}

.stats-sec .heading ul li {
    margin-right: 10px;
}

.stats-sec .heading ul li:last-child {
    margin-right: 0;
}

.stats-sec .heading ul li a {
    text-decoration: none;
    font-size: 14px;
    color: #fff;
    opacity: 0.3;
}

.collectibles {
    /* height: 100%; */
}

.collectibles-list ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.collectibles-list ul li {
    width: 30%;
    margin-bottom: 20px;
    height: 110px;
}

/* .collectibles-list ul li:not(:last-child) {
    margin-right: 10px;
} */

.collectibles-list ul li .image {
    width: 100%;
    height: 100%;
}

.collectibles-list ul li .image img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-radius: 14px;
}

.nft-list ul {
    display: flex;
    flex-wrap: wrap;
    /* justify-content: space-between; */
}

.nft-list ul li:first-child {
    /* height: 200px; */
    /* width: 36%; */
}

.nft-list ul li:nth-child(2) {
    /* width: 60%; */
    /* height: 200px; */
}

.nft-list ul li {
    width: 32%;
    margin-bottom: 20px;
    padding: 0 10px;
}

.nft-list ul li .image {
    width: 100%;
    height: 200px;
    position: relative;
}

.nft-list ul li .image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 14px;
}

.collection-list ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.collection-list ul li {
    width: 48%;
}

.collection-list ul li .image {
    width: 100%;
    /* height: 160px; */
}

.collection-list ul li .image img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-radius: 14px;
}

.sidebar-links li a {
    width: 100% !important;
}

.activities {
    margin-bottom: 20px;
    margin-top: 20px;
}

.activities ul li {
    /* border-bottom: 1px solid #ffffff29; */
    /* padding-bottom: 20px; */
    margin-top: 10px;
    padding: 20px 30px;
}

.activities ul li:last-child {
    /* padding: 0; */
    /* border: none; */
    /* margin: 0; */
}

.recent-offers ul li {
    border-bottom: 1px solid #ffffff29;
    padding-bottom: 20px;
    margin-bottom: 20px;
}

.recent-offers ul li:last-child {
    padding: 0;
    border: none;
    margin: 0;
}

.about-details h5 {
    text-align: center;
    font-family: 'Montserrat';
    font-weight: 500;
}

/* Dashboard */

.main-dashboard .sidebar {
    width: 100%;
    max-width: 380px;
    min-height: 100vh;
}

.main-dashboard .right-sidebar {
    max-width: 100%;
}

.sidebar .logo {
    display: flex;
    align-items: center;
    margin-bottom: 70px;
    padding: 0 30px;
}

.sidebar .logo .image {
    width: 64px;
    height: 64px;
    margin-right: 10px;
}

.sidebar .logo .image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.sidebar .logo .name span {
    font-size: 14px;
    opacity: 0.5;
}

.sidebar-links li {
    /* margin-bottom: 30px; */
}

.sidebar-links li.static {
    margin-top: 0;
    margin-bottom: 0;
}

.sidebar-links li a {
    font-size: 16px;
    font-weight: 500;
    line-height: 29px;
    opacity: 0.5;
    display: flex;
    align-items: center;
    padding: 15px 30px;
}

.sidebar-links li a:hover,
.sidebar-links li.active a {
    opacity: 1;
    background: linear-gradient(79deg, #1554F0 0%, #11D6B9 100%);
}

.sidebar-links li a .icon {
    margin-right: 30px;
    width: 20px;
}

.sidebar-links li.active a .icon svg,
.sidebar-links li a:hover .icon svg {
    fill: rgb(132, 233, 244);
}

.sidebar-links li.active a .icon svg path,
.sidebar-links li a:hover .icon svg path {
    stroke: rgb(132, 233, 244);
}

.main-content-area {
    flex: 1 1;
    padding-bottom: 30px;
    width: calc(100% - 380px);
}

.points-list {
    margin-bottom: 40px;
}

.points-list h3 {
    font-size: 26px;
    font-weight: 900;
    margin-bottom: 8px;
    text-align: center;
}

.points-list h5 {
    font-weight: 500;
    /* opacity: 0.5; */
    font-size: 14px;
    text-align: center;
}

.user-info {
    margin-bottom: 30px;
    position: relative;
}

.user-info .user-about {
    flex-direction: column;
    align-items: center;
}

.user-info .user-about .image {
    margin-right: 0;
    margin-bottom: 20px;
    background: url("./assets/images/User-bg-grey.svg");
    /* padding: 10px 10px 10px; */
    background-size: cover;
}

.image.d-edit-image {
    position: relative;
    width: 100%;
}

.custom-avatar-img+img>img {
    clip-path: none;
    background: none;
    border-radius: 12px;
    width: 56px;
    position: relative;
    left: 50%;
    transform: translate(-50%);
}

.about-details p {
    opacity: 0.5;
    font-size: 14px;
    text-align: center;
}

.my-nfts {
    margin-bottom: 40px;
}

/* .my-nfts .nft-list {
    display: flex;
    align-items: center;
} */

/* .my-nfts .nft-list .nft-slider {
    width: 30%;
    padding-left: 20px;
} */

.nft-list .slick-slide .item {
    padding: 0 12px;
}

.nft-list .slick-slide .item .image {
    height: 290px;
}

.nft-list .slick-slide img {
    height: 100%;
}

.my-nfts .nft-list ul li {
    width: 50%;
    padding: 0 10px;
}

.nft-list ul li .name {
    margin-top: 20px;
    margin-bottom: 20px;
    font-weight: 500;
    display: block;
}

.nft-image .sale {
    position: absolute;
    right: 28px;
    top: 28px;
    background: #f9896b;
    border-radius: 14px;
    font-size: 14px;
    font-weight: bold;
    padding: 6px 14px;
}

.nft-image .timer {
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 2px solid #1e1e24;
    border-radius: 16px;
    color: #1e1e24;
    padding: 6px 14px;
    font-size: 14px;
    font-weight: bold;
    position: absolute;
    bottom: -17px;
    right: 0;
    left: 0;
    text-align: center;
    width: 90%;
    margin: 0 auto;
}

.slick-slide .nft-image img {
    display: unset;
    /* width: 340px !important;
    height: 340px; */
}

.price label {
    font-size: 14px;
    opacity: 0.5;
    font-weight: 500;
}

.price .currency {
    font-weight: 500;
    display: flex;
    align-items: center;
}

.price .currency .image {
    width: 15px;
    height: 25px;
    margin-right: 5px;
}

.daily-rewards {
    margin-top: 30px;
}

.unread-notification td {
    background: linear-gradient(to right, #1f1f27, #1f1f27);
    ;
}

.nofifications-table .table>:not(caption)>*>* {
    padding: 18px 10px;
}

.daily-rewards li {
    border-bottom: 1px solid #4b4b50;
    padding-bottom: 20px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 25px;
}

.daily-rewards li span {
    font-weight: 500;
}

.daily-rewards li a {
    font-weight: 500;
}

.daily-rewards li.claim a {
    color: #84e9f4;
}

.daily-rewards li.claimed a {
    color: #f9896b;
}

.daily-rewards li.locked a {
    opacity: 0.5;
}

.daily-rewards li.locked a {
    color: #fff;
}

.daily-rewards li:last-child {
    margin: 0;
    border: none;
    padding: 0;
}

.game-purchase {
    margin-top: 40px;
}

.game-list {
    margin-top: 20px;
}

.game-list ul {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.game-list ul li {
    border-radius: 25px;
    padding: 30px;
    width: 20%;
    position: relative;
    padding-bottom: 60px;
}

.game-list ul li.orange {
    background: transparent linear-gradient(30deg, #fdbc64 0%, #f9896b 100%) 0% 0% no-repeat padding-box;
}

.game-list ul li.pink {
    background: transparent linear-gradient(30deg, #fc3546 0%, #fc26e7 100%) 0% 0% no-repeat padding-box;
}

.game-list ul li.blue {
    background: transparent linear-gradient(30deg, #84e9f4 0%, #4f46ba 100%) 0% 0% no-repeat padding-box;
}

.game-list ul li h3 {
    height: 100px;
    margin-bottom: 30px;
    font-size: 24px;
    line-height: 30px;
}

.game-list ul li a {
    margin-top: 20px;
    display: block;
    text-align: right;
    position: absolute;
    right: 20px;
    bottom: 20px;
}

.game-list ul li.treasury-box {
    margin-top: 130px;
    padding-top: 100px;
    width: 32%;
    background: #2a2d3a6b;
    height: 250px;
}

.game-list ul li.treasury-box .image {
    position: absolute;
    top: -140px;
    width: 220px;
    height: 220px;
    left: 0;
    right: 0;
    margin: auto;
}

.game-list ul li.treasury-box p {
    text-align: center;
    font-size: 16px;
    margin: 0 auto;
    font-weight: 500;
}

.game-list ul li.treasury-box .btn-dark {
    text-align: center;
    font-size: 14px;
    font-weight: 500;
    padding: 15px;
    background: #1A1A1A;
    position: unset;
    display: block;
    width: 100%;
    margin-top: 20px;
    color: #B4B4B4;
    border-radius: 18px;
}

.game-list ul li.treasury-box .btn-dark:hover {
    background: transparent linear-gradient(84deg, #1554F0 0%, #11D6B9 100%) 0% 0% no-repeat padding-box;
    box-shadow: 0px 8px 21px #00000045;
    color: #fff;
}

.task-rewards li {
    display: flex;
    padding: 10px 20px;
    font-size: 14px;
    font-weight: 500;
    background: #33363940;
    margin-bottom: 13px;
}

.task-rewards li:hover {
    transform: scale(1.03);
    transition: .5s all ease-out;
}

.task-rewards li .task {
    width: 70%;
}

.task-rewards li .rewards {
    width: 30%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.task-rewards .head p {
    font-size: 15px;
    font-weight: 900;
    /* opacity: 0.5; */
    font-family: 'Neon';
}

.task-rewards .head {
    background: transparent;
    margin: 0;
}

.task-rewards .head:hover {
    transform: none;
}

.task-rewards li .rewards .link {
    color: #fff;
    opacity: 0.5;
}

.task-rewards li .rewards .link:hover {
    opacity: 1;
}

.task-rewards .view-all {
    text-align: center;
    margin-top: 20px;
    font-size: 14px;
}

.task-rewards .view-all a {
    /* color: #4f46ba; */
    font-weight: 500;
}

.game-list ul li .bg-image {
    position: absolute;
    right: 0;
    top: 0;
    width: 100px;
    height: 100px;
}

.game-list ul li .bg-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.promotion-wrapper {
    border-radius: 10px;
    text-align: center;
    padding: 0 12px;
}

.promotion-wrapper img {
    width: 100%;
    margin: 0 auto;
    height: 305px;
    object-fit: cover;
}

.slick-track .slick-slide {
    height: 100%;
}

.card-wrapper {
    background: transparent linear-gradient(79deg, #1554F0 0%, #11D6B9 100%) 0% 0% no-repeat padding-box;
    border-radius: 10px;
    text-align: center;
    padding: 30px;
    box-shadow: 0 42px 0px -22px #ffffff24;
}

.gift-card .section {
    margin-bottom: 44px !important;
}

.card-wrapper p {
    font-size: 12px;
    color: #111115;
    font-weight: 500;
    margin-top: 20px;
}

.card-wrapper .vibes {
    font-family: "Great Vibes";
    color: #000;
    font-weight: 400;
    z-index: 9;
    position: relative;
}

.card-wrapper h2 {
    line-height: 22px;
}

.card-wrapper .value-wrapper {
    border: 2px solid #fff;
    margin: 0 auto;
    margin-top: 20px;
    width: 200px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.card-wrapper .value-wrapper label {
    width: 50%;
    font-size: 14px;
    text-align: right;
    padding: 0 5px;
    line-height: 14px;
    font-weight: 200;
}

.card-wrapper .value-wrapper span {
    width: 50%;
    display: block;
    font-size: 30px;
    font-weight: bold;
    text-align: left;
    padding: 0 5px;
}

.about-details h5 span {
    background: #2f2f36;
    border-radius: 4px;
    padding: 5px;
    font-size: 10px;
    font-weight: 500;
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    height: 25px;
    margin-left: 5px;
}

.about-details h5 span img {
    width: 13px;
    height: 13px !important;
    margin-right: 3px;
}

.toggle-button {
    display: none;
    background: none;
    flex-direction: column;
    border: 1px solid #fff;
    padding: 00;
    border-radius: 4px;
    position: absolute;
    right: 30px;
    top: 0;
    bottom: 0;
    align-items: center;
    margin: auto;
    width: 50px;
    height: 45px;
    justify-content: center;
}

.toggle-button span {
    display: block;
    width: 25px;
    height: 2px;
    background: #fff;
    margin-bottom: 8px;
}

.toggle-button span:last-child {
    margin: 0;
}

.custom-modal .modal-content,
.connect-wallet .modal-content {
    background: #1e1e24;
    border: 1px solid #0077B5;
    overflow: hidden;
}

.custom-modal .modal-content .modal-header,
.connect-wallet .modal-content .modal-header {
    border: none;
    text-align: center;
    justify-content: center;
    border-bottom: 1px solid hsla(0, 0%, 100%, 0.1) !important;
    background: transparent linear-gradient(79deg, #1554F0 0%, #11D6B9 100%) 0% 0% no-repeat padding-box;
    border-radius: 0;
    height: 70px;
    position: relative;
    margin-bottom: 35px;
}

.custom-modal .modal-content .modal-header .image,
.connect-wallet .modal-content .modal-header .image {
    position: absolute;
    top: 22px;
}

.custom-modal .modal-content .modal-header .image .icon {
    width: 70px;
    height: 70px;
    background: green;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
}

.custom-modal .modal-content .modal-body .modal-title,
.connect-wallet .modal-content .modal-body .modal-title {
    font-weight: 600;
    font-size: 28px;
    margin-bottom: 15px;
    /* font-family: 'Neon'; */
}

.custom-modal .modal-content .modal-body .modal-main-body p strong,
.connect-wallet .modal-content .modal-body .modal-main-body p strong {
    color: #00FFD0
}

.custom-modal .modal-content .modal-body .modal-main-body,
.connect-wallet .modal-content .modal-body .modal-main-body {
    position: relative;
}

.custom-modal .modal-content .modal-body .modal-main-body::after,
.connect-wallet .modal-content .modal-body .modal-main-body::after {
    content: '';
    position: absolute;
    width: 70%;
    height: 1px;
    background-color: #00abf0;
    bottom: -15px;
    left: 50%;
    transform: translateX(-50%);
}

.single-badge-img {
    margin-bottom: 20px;
    width: 125px;
}

.custom-modal-content p {
    margin-bottom: 20px;
}

.custom-modal-content .form-check-input[type=checkbox] {
    margin: 0px 10px;
    width: 75px;
    height: 60px;
    background-color: transparent;
    border: 2px solid #fec041;
}

.custom-modal-content .form-check-input[type=checkbox]:focus {
    box-shadow: none;
}

.form-check-input:checked[type=checkbox] {
    background-image: url('./assets/images/logo.svg');
    background-size: cover;
    background-repeat: no-repeat;
}

.custom-modal-content label {
    padding-top: 17px;
    font-size: 13px;
}

.custom-modal-content .form-check {
    display: inline-grid;
    padding-left: 0px;
    margin-bottom: 0.125rem;
}

.custom-modal .modal-footer {
    border-top: 1px solid rgba(255, 255, 255, 0.1);
}

.main-btn-style,
.personal-info .information form .btn-primary,
.main-btn-style:disabled {
    background: #2cfafe;
    /* background: linear-gradient(84deg, #1554F0 0%, #11D6B9 100%); */
    border: 2px solid #2CFAFE;
    box-shadow: none;
    outline: none;
    font-weight: 500;
    text-decoration: none;
    color: #000;
    border-radius: 8px;
    padding: 8px 20px;
    position: relative;
    z-index: 1;
    transition: all 0.3s;
    font-family: 'Montserrat';
    font-weight: 700;
    opacity: 1;
    cursor: pointer;
    pointer-events: all;
    text-transform: uppercase;
}

.personal-info .information form .btn-primary,
.settings-sidenav ul li:last-child a {
    background: #2CFAFE;
    color: #000;
    font-family: "Montserrat";
    font-weight: bold;
    font-size: 17px;
    border: 2px solid #2cfafe;
    transition: all 0.3s;
}

.submit-post .main-btn-style {
    font-family: "Montserrat";
}

.settings-sidenav ul li:last-child a:hover,
.personal-info .information form .btn-primary:hover {
    background-color: transparent;
    color: #2cfafe;
}

a:focus {
    text-decoration: none;
}

.main-btn-style:hover {
    background: transparent;
    color: #2cfafe;
    border-color: #2CFAFE;
}

.main-btn-style:active,
.main-btn-style:focus-visible,
.profile-notifications button:active,
.profile-notifications button:focus-visible {
    background-color: #2CFAFE;
    border-color: #2CFAFE;
    color: #000;
    box-shadow: none;
}

.main-btn-style::before {
    /* position: absolute;
    content: "";
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: linear-gradient(84deg, #11D6B9 0%, #1554F0 100%);
    z-index: -1;
    border-radius: 8px;
    transition: opacity 0.3s linear;
    opacity: 0; */
}

.main-btn-style:focus {
    text-decoration: none;
    color: initial;
}

.main-btn-style:hover::before {
    /* opacity: 1; */
}


.custom-modal .modal-footer .btn-primary,
.create-gift-form .btn-primary,
.login-wrapper .btn-primary,
.form-modal .btn-actions .btn-primary {
    /* background: transparent linear-gradient(84deg, #1554F0 0%, #11D6B9 100%) 0% 0% no-repeat padding-box;
    border: none;
    box-shadow: none;
    outline: none;
    font-weight: 500; */
}

.connect-wallet .modal-content .modal-body {
    text-align: center;
}

.connect-wallet .modal-content .modal-body .image {
    width: 80px;
    height: 80px;
    margin: 20px auto 0;
}

.connect-wallet .modal-content .connect-btn {
    border: none;
    height: 45px;
    margin: 0;
    outline: none;
    box-shadow: none;
}

.custom-modal .modal-content .connect-btn,
.connect-wallet .close-btn {
    position: absolute;
    right: 12px;
    top: 12px;
    border: none;
    height: 30px;
    width: 30px;
    margin: 0;
    outline: none;
    box-shadow: none;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #000000;
}

.connect-wallet .modal-content .modal-body h4 {
    margin-bottom: 15px;
    font-family: 'Montserrat';
    font-weight: 500;
    font-size: 18px;
}

.connect-wallet .modal-content .modal-body .cursor-pointer {
    cursor: pointer;
    width: fit-content;
    margin: 0 auto;
}
.connect-wallet .modal-content .modal-body .cursor-pointer:hover svg{color: rgb(109,204,91);}
.edit-icon {
    position: absolute;
    right: 0;
    top: 0;
    cursor: pointer;
    background: transparent linear-gradient(72deg, #fd24d8 0%, #fed130 100%) 0% 0% no-repeat padding-box;
    padding: 8px;
}

.login-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
}

.login-box {
    max-width: 500px;
    margin: 0 auto;
    background: #1e1e24;
    padding: 20px;
    width: 100%;
}

.login-box h2 {
    text-align: center;
    margin-bottom: 20px;
    font-size: 30px;
}

.login-box .btn-action {
    text-align: right;
}

.form-modal .form-control,
.login-box .form-control {
    background: #000;
    border: none;
    color: #fff;
    outline: none;
    box-shadow: none;
    height: 45px;
}

.submission__value-container .css-qc6sy-singleValue {
    color: #fff !important;
}

.form-modal input#formFile,
.login-wrapper input#formFile {
    height: auto;
}

.upload-wrapper input {
    height: 100% !important;
}

.form-validate {
    color: #ff0000;
}


.form-modal .form-control {
    color: #fff;
    outline: none;
    /* height: auto; */
    box-shadow: none;
    height: 50px;
    /* margin-top: 10px; */
    background: #44444E !important;
    border: 2px solid #333639 !important;
    border-radius: 10px;
}

.create-game-wrapper .form-section .custom-select__control {
    min-height: 50px;
    height: 100%;
}

.form-modal textarea.form-control {
    height: 150px;
    resize: none;
}

.create-gift-form {
    max-width: 500px;
    margin: 0 auto;
}

.create-gift-form h3 {
    margin-bottom: 30px;
    text-align: center;
}

.create-gift-form .form-control {
    background: #000;
    border: none;
    color: #fff;
    outline: none;
    box-shadow: none;
    height: 45px;
}

.giftcard-table {
    height: 100%;
}


.giftcard-table h2 {
    text-align: center;
    margin-bottom: 20px;
    color: #84e9f4;
}

.form-check label {
    font-size: 12px;
    padding-top: 8px;
}

.badges-images li {
    margin: 0px 10px;
}

.badges-images {
    flex-wrap: wrap;
}

/* daily claim start */
.tresure-week .disable {
    pointer-events: none;
    opacity: 0.4;
}

/* daily claim end */


.giftcard-table .table {
    border: 2px solid #fd39c4;
    color: #fff;
}

.giftcard-table .table thead th {
    background: #fd39c4;
    color: #fff;
}

.giftcard-table .table tr td {
    /* border-color: #fff; */
}

.giftcard-table .table> :not(:first-child) {
    border-top: 1px solid #fff;
}

.giftcard-table .title {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.giftcard-table .title .create {
    cursor: pointer;
    font-weight: 500;
}

.giftcard-table .title .create:hover {
    color: #7e3dfb;
}

.notifications {
    display: flex;
    padding-right: 50px;
}

.notifications .list {
    flex: 1;
    padding-right: 50px;
}

.notifications .head {
    background: #1b1a21c4;
    margin: 0;
    padding: 12px 30px;
}

.notifications li {
    display: flex;
    padding: 15px 30px;
    font-size: 15px;
    font-weight: 500;
    background: #1e1e24;
    margin-bottom: 1px;
}

.notifications .head p {
    font-size: 14px;
    font-weight: 500;
}

.notifications li .task {
    width: 50%;
    padding-right: 10px;
}

.notifications li .rewards {
    width: 25%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.notifications li .action {
    width: 25%;
    display: flex;
    align-items: center;
}

.action .link .icon {
    margin-right: 5px;
}

.form-modal h2,
.form-modal h5 {
    margin-bottom: 15px;
}

.notice {
    background: #f8ef96 0% 0% no-repeat padding-box;
    border: 2px dashed #c9bd48;
    max-width: 400px;
    width: 100%;
    color: #000;
    padding: 30px;
    display: flex;
    flex-direction: column;
    max-height: 50vh;
}

.notice ul {
    list-style: number;
    padding-left: 0;
}

.notice li {
    background: none;
    color: rgba(0, 0, 0, 0.4);
    display: list-item;
    padding-left: 0;
    padding-right: 0;
    border-bottom: 1px solid rgba(201, 189, 72, 1);
}

.notice li:last-child {
    border: none;
    padding-bottom: 0;
}

.main-dashboard .main-content-area {
    padding-top: 50px;
    position: relative;
}

.main-content-area .page-title h2 {
    font-size: 36px;
    margin-bottom: 30px;
}

.main-dashboard .sidebar .section-dark-bg {
    padding: 30px 0;
}

.setting-wrapper {
    /* max-width: 990px; */
    margin: 0 auto;
    background: #323239 0% 0% no-repeat padding-box;
    border: 1px solid #333639;
    border-radius: 25px;
    padding: 25px;
}

.setting-wrapper .wallet-address h4 {
    font-size: 18px;
    font-family: 'Montserrat';
}

.field-wrapper {
    background: #1B1A21;
    position: relative;
    height: 55px;
    padding: 0px 60px 0px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 500;
    border: 2px solid #333639;
    border-radius: 8px;
    overflow: hidden;
}

.field-wrapper .btn {
    height: 100%;
}

.setting-wrapper label {
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 20px;
}

.personal-info {
    margin-top: 30px;
}

.personal-info .information {
    display: flex;
}

.personal-info .information .image-data p {
    display: none;
}

.personal-info .information .image-data .image {
    width: 200px;
    max-width: 100%;
    height: 200px;
    border-radius: 8px;
    overflow: hidden;
    background-color: #F0F0F0;
}

.image-data.user-desabled * {
    pointer-events: none;
}

.user-desabled .form-control:disabled {
    opacity: 0 !important;
}

.personal-info .information .image-data .image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    padding-top: 15px;
}

.personal-info .information .image-details p {
    font-size: 14px;
}

.personal-info .information form {
    flex: 1;
}

.personal-info .information form .form-control {
    background: #1B1A21;
    border: none;
    color: #fff;
    padding: 8px 12px;
    font-size: 14px;
    height: 55px;
    outline: none;
    box-shadow: none;
    border: 2px solid #333639;
    resize: none;
    /* border-radius: 30px; */
}

.personal-info .information form textarea {
    height: 235px !important;
    border-radius: 10px !important;
}

.user-info .RSPBprogressBar .RSPBstep {
    top: -25px;
}

.steps {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.steps img {
    width: 200px !important;
}

/* .user-info .RSPBprogressBar .RSPBstep:first-child {
    transform: none;
} */

/* .user-info .RSPBprogressBar .RSPBstep:nth-child(5) {
    left: 97% !important;
} */

.user-info .RSPBprogressBar {
    margin-bottom: 0;
}

.user-info .progress-stats h2 {
    font-size: 12px;
}

.progress-stats h2 {
    font-size: 18px;
    margin-top: 10px;
    text-align: center;
}

.left-sidebar .user-info .user-about {
    flex-direction: row;
}

.left-sidebar .user-info .user-about .image {
    margin-right: 10px;
}

.left-sidebar .about-details {
    flex: 1;
}

.left-sidebar .about-details h5 {
    text-align: left;
}

.left-sidebar .about-details p {
    text-align: left;
}

.progress-stats {
    max-width: 1080px;
    margin: 0 auto 40px;
    margin-top: 50px;
    width: 88%;
}

.progress-stats .notifications {
    padding: 0;
    margin-top: 40px;
    background: #33363940 0% 0% no-repeat padding-box;
    border: 2px solid #333639;
}

.progress-stats .notifications .list {
    padding: 0;
}

.progress-stats .notifications .list .head p {
    font-weight: 900;
    /* opacity: 0.5; */
    font-size: 16px;
    font-family: 'Neon';
}

.progress-stats .notifications .list li {
    font-size: 14px;
    font-weight: 400;
    border-bottom: 2px solid #333639;
}

.progress-stats .notifications .list li:last-child {
    border: none;
}

.progress-stats .notifications .list li.inactive {
    opacity: 0.3;
    cursor: not-allowed;
}

.progress-stats .RSPBprogressBar img {
    width: 30px !important;
    padding: 0;
}

.progress-stats:nth-child(3) {
    background-color: red !important;
}

.progress-stats .RSPBprogressBar .RSPBstep {
    top: -50px;
}

/* .progress-stats .RSPBprogressBar .RSPBstep:first-child {
    transform: none;
} */

/* .progress-stats .RSPBprogressBar .RSPBstep:nth-child(5) {
    left: 98% !important;
} */

@keyframes glowing {
    0% {
        filter: blur(0px);
    }

    50% {
        filter: blur(1.5px);

    }

    100% {
        filter: blur(0px);
    }
}

.reaction-skeleton {
    position: relative;
    filter: blur(1px);
    animation: glowing 1300ms infinite
}

/*  */
.badges .item {
    background: #1e1e24;
    padding: 20px;
    height: 100%;
    position: relative;
}

.badges .item.active::before {
    display: none;
}

.tags-spinner-border {
    width: 20px !important;
    height: 20px !important;
}

.tags-spinner-border.boost-spinner {
    width: 15px !important;
    height: 15px !important;
    color: #2CFAFE !important;
}
.custom-tag-class{
    color: #fff !important;
    background: #000; font-size: 15px; padding: 10px 8px; font-family: "Montserrat";
    display: block;
}
.tags-spinner-border.boost-spinner-submission {
    color: #012627 !important;
}

.tags-validation {
    color: #fff;
    font-weight: 400;
    font-size: 12px;
    padding-left: 3px;
    opacity: 0.5;
}

.tags-validation.text-danger {
    font-size: 15px;
}

.badges .item:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: #000000ec;
    z-index: 9;
    background-image: url(./assets/images/locked-badge.png);
    background-repeat: no-repeat;
    background-position: center;
}

.badges .item .image-bg {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 30px;
    border-radius: 10px;
    margin-bottom: 20px;
}

.badges .item.daily .image-bg {
    background-image: linear-gradient(128deg, #2bfcd0 0%, #253dbd 100%);
}

.badges .item.early .image-bg {
    background-image: linear-gradient(128deg, #ec666c 0%, #a21a20 100%);
}

.badges .item.glitter .image-bg {
    background-image: linear-gradient(128deg, #761ac0 0%, #eb90d3 100%);
}

.badges .item.golden .image-bg {
    background-image: linear-gradient(128deg, #f5d174 0%, #f1b029 100%);
}

.badges .item.light .image-bg {
    background-image: linear-gradient(128deg, #96c93d 0%, #77ac1a 100%);
}

.badges .item.ambassador .image-bg {
    background-image: linear-gradient(128deg, #eca627 0%, #bb6c19 100%);
}

.badges .item.creator .image-bg {
    background-image: linear-gradient(128deg, #b7bbbc 0%, #989fa0 100%);
}

.badges .item.earner .image-bg {
    background-image: linear-gradient(128deg, #ee34d9 0%, #8bfdfd 100%);
}

.badges .item.virtual .image-bg {
    background-image: linear-gradient(128deg, #ec7e53 0%, #bf5040 100%);
}

.badges .item.pent .image-bg {
    background-image: linear-gradient(128deg, #ca2b5e 0%, #763a87 100%);
}

.badges .item.weekly .image-bg {
    background-image: linear-gradient(128deg, #2bfcd0 0%, #253dbd 100%);
}

.badges .item.speed .image-bg {
    background-image: linear-gradient(128deg, #7346ff 0%, #3d17c4 100%);
}

.badges .item.welcome .image-bg {
    background-image: linear-gradient(128deg, #c6b199 0%, #816c59 100%);
}

.badges .item.influencer .image-bg {
    background-image: linear-gradient(128deg, #926adb 0%, #4f3383 100%);
}

.badges .item.hyper .image-bg {
    background-image: linear-gradient(128deg, #eecaa2 0%, #ef639f 100%);
}

.badges .item.magician .image-bg {
    background-image: linear-gradient(128deg, #d161ac 0%, #db37a4 100%);
}

.badges .item.monthly .image-bg {
    background-image: linear-gradient(128deg, #2bfcd0 0%, #253dbd 100%);
}

.badges .item .image {
    width: 150px;
}

.bb-reaction-box {
    background-color: #333134;
    border-radius: 14px;
    padding: 20px;
}

.badges .item .image img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.badges .item p {
    font-size: 15px;
    opacity: 0.4;
    line-height: 22px;
    margin-top: 10px;
}

.no-data {
    height: 150px;
    border: 1px dashed #84e9f4;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
    text-transform: capitalizes;
}

.personal-info .information form .btn-primary {
    /* background: #84e9f4;
    color: #111115;
    border: 1px solid #84e9f4;
    outline: none;
    box-shadow: none;
    font-weight: 500; */
    /* margin-top: 20px; */
}

.active {
    opacity: 1;
    background: transparent linear-gradient(90deg, #84e9f427 0%, #84e9f400 100%) 0% 0% no-repeat padding-box;
}

.personal-info .information form .btn-primary:hover {
    /* background: transparent;
    color: #84e9f4;
    border: 1px solid #84e9f4; */
}

.points-table .table {
    color: #fff;
}

/* .points-table .table th{background: #000;} */
.points-table .table th,
.points-table .table td {
    padding: 10px;
    text-align: left;
}

.points-table .table> :not(:first-child) {
    border: none;
}

.points-table .table td {
    background: #1e1e24;
    border-color: #4a4a50;
}

.coinmarket-rewards {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-right: 50px;
}

.coinmarket-rewards .content {
    flex: 1;
    padding-right: 20px;
}

.coinmarket-rewards .content p {
    opacity: 0.4;
    margin-top: 20px;
    margin-bottom: 35px;
}

.coinmarket-rewards .content .actions .btn-primary {
    background: #84e9f4;
    color: #000;
    height: 65px;
    font-weight: 500;
    border-color: #84e9f4;
    padding: 10px 20px;
    margin-right: 30px;
}

.coinmarket-rewards .content .actions .link {
    color: #84e9f4;
}

.coinmarket-rewards .image-box {
    max-width: 320px;
    margin-left: 200px;
    position: relative;
}

.coinmarket-rewards .image-box span {
    border-radius: 32px;
    font-size: 24px;
    position: absolute;
    bottom: -16px;
    left: 0;
    right: 0;
    margin: auto;
    display: block;
    max-width: 210px;
    text-align: center;
    background: #00000096;
    padding: 14px 20px;
    font-weight: 500;
}

.claim-rewards p {
    max-width: 850px;
    margin-top: 20px;
    margin-bottom: 40px;
    opacity: 0.4;
}

.next-rewards-list {
    border: 3px dashed #2e484d;
    border-radius: 10px;
    text-align: center;
    padding: 30px;
    margin-top: 80px;
}

.next-rewards-list .title {
    background: #2e484d;
    border-radius: 8px;
    max-width: 500px;
    margin: 0 auto;
    color: #6597a0;
    padding: 20px;
    margin-top: -63px;
    margin-bottom: 30px;
}

.next-rewards-list ul {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.next-rewards-list ul li {
    flex: 1;
    cursor: pointer;
}

.next-rewards-list ul li .image {
    height: 75px;
    margin-top: 15px;
    margin-bottom: 20px;
}

.next-rewards-list ul li .image img {
    height: 100%;
}

.next-rewards-list ul li .points {
    color: #84e9f4;
    font-weight: 600;
}

.next-rewards-list ul li span {
    font-weight: 500;
}

.next-rewards-list ul li:hover {
    background: #2e484d;
    border-radius: 10px;
}

.breadcrum.daily {
    margin-left: -24px;
}

.banner-title {
    background: #2e2a96;
    padding: 50px 30px 35px;
    border-radius: 10px;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    margin-bottom: 50px;
}

.banner-title p {
    font-size: 26px;
    max-width: 400px;
    margin-left: 90px;
}

.banner-title h1 {
    font-size: 120px;
    color: #84e9f4;
    text-align: center;
    line-height: 100px;
    font-weight: 500;
    margin: 0;
}

.banner-title h1 span {
    font-size: 50px;
    display: block;
    color: #fff;
    margin-bottom: -40px;
}

.avatar-badge {
    display: flex;
    width: 100%;
}

.buyers-rewards .item {
    display: flex;
    margin-top: 50px;
    padding-bottom: 100px;
    border-bottom: 2px solid #cccccc1c;
}

.avatar-badge .image {
    height: 160px;
    margin-bottom: 15px;
    width: 130px;
}

.avatar-badge .image img {
    height: 100%;
    width: 100%;
    object-fit: contain;
}

.avatar-badge span {
    font-weight: 500;
    color: #84e9f4;
}

.avatar-badge .avatar {
    margin-right: 50px;
}

.buyers-rewards .item .overview>p {
    max-width: 850px;
    opacity: 0.4;
    margin-top: 20px;
    margin-bottom: 30px;
    font-weight: 500;
}

.more-items>p {
    margin-bottom: 30px;
    font-weight: 500;
    cursor: pointer;
}

.more-items .others>p {
    color: #84e9f4;
    font-weight: 500;
    margin-bottom: 10px;
}

.more-items .others ul li {
    width: 80px;
    margin-right: 15px;
}

.more-items .others ul {
    display: flex;
}

.icon svg {
    fill: rgb(81, 132, 140);
    font-size: 20px;
}

.icon .bitcoin-icon {
    width: 20px
}

.more-items .others ul li .image {
    width: 100%;
    margin-bottom: 10px;
}

.more-items .others ul li span {
    font-size: 16px;
    opacity: 0.4;
    line-height: 22px;
    display: block;
}

.buyers-rewards .item .overview>.others>ul {
    margin-top: 15px;
}

.buyers-rewards .item .overview>.others>ul li {
    font-weight: 500;
    margin-bottom: 10px;
    /* background: url("./assets/images/logo.svg"); */
    padding-left: 20px;
    background-position: left 5px;
    background-size: 10px;
    background-repeat: no-repeat;
}

.buyers-rewards .load-more {
    text-align: center;
    margin-top: 30px;
}

.buyers-rewards .load-more span {
    cursor: pointer;
}

.buyers-rewards .item .others {
    margin-bottom: 30px;
    transition: ease all 1s;
    max-width: 835px;
}

.buyers-rewards .item .others.hide {
    height: 0;
    opacity: 0;
}

.buyers-rewards .item .others.show {
    height: auto;
    opacity: 1;
}

.buyers-rewards .item .overview .more {
    color: #fff;
    opacity: 1;
    cursor: pointer;
}

.buyers-rewards .item .overview .more .fa {
    color: #84e9f4;
    margin-left: 20px;
}

.filters {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.filters .btn-secondary {
    color: #1554F0;
    background-color: #ffffff;
    border-color: #1554F0;
    padding: 10px 20px;
    box-shadow: none;
    font-weight: 500;
}

.filters .btn-secondary.active,
.filters .btn-secondary:hover,
.filters .btn-secondary:focus {
    background: transparent linear-gradient(64deg, #8136fb 0%, #2be7fd 100%) 0% 0% no-repeat padding-box;
    border-color: #1554F0;
    color: #fff;
}

.purchase-item-list .purchase-item {
    margin-top: 20px;
    background: #323239;
    border-radius: 25px;
    overflow: hidden;
}

.purchase-item .item {
    display: flex;
    align-items: center;
    /* background: #333639; */
    padding: 20px 40px;
    padding-right: 80px;
    /* margin-bottom: 5px; */
    border-bottom: 1px solid #4c5258;
    position: relative;
}

.purchase-item .item::after {
    font-family: "Fontawesome";
    content: "\f105";
    position: absolute;
    width: 30px;
    height: 30px;
    top: 50%;
    transform: translateY(-50%);
    right: 30px;
    font-size: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    opacity: 0.7;
    font-weight: 600;
}

.purchase-item .item:hover {
    /* background: #393943; */
    background: #33363990;
}

.purchase-item .item .image {
    width: 40px;
    height: 40px;
    margin-right: 35px;
}

.purchase-item .item .image img {
    object-fit: contain;
}

.purchase-item .item .content {
    flex: 1;
}

.purchase-item .item .content h4 {
    font-size: 20px;
    margin-bottom: 10px;
    line-height: 26px;
}

.purchase-item .item .content p {
    font-size: 16px;
    color: #fff;
    opacity: 0.7;
}

.choose-format .btn-close {
    filter: invert(1);
    position: absolute;
    right: 20px;
    top: 20px;
}

.create-game-wrapper {
    /* background: #33363940; */
    /* padding: 20px 30px; */
    /* padding-right: 50px; */
}

.create-game-wrapper .form-modal {
    background-color: #323239;
    border-radius: 25px;
    margin-bottom: 50px;
}

.create-game-wrapper .form-modal .form-wrapper {
    padding: 0px 30px;
}

.create-game-wrapper .form-modal .form-wrapper .fixed-side,
.create-game-wrapper .form-modal .form-wrapper .scroll-side {
    padding: 20px 0;
}

.create-game-wrapper .form-modal .form-wrapper .scroll-side .form-section .basic-multi-select .custom-select__multi-value__remove {
    background-color: #9999997d;
    color: #ffffff;
}

.create-game-wrapper .form-modal .form-wrapper .scroll-side .form-section .basic-multi-select .custom-select__multi-value__remove:hover {
    color: #fff;
    background-color: #9999997d;
}

.create-game-wrapper .form-modal .form-wrapper .fixed-side {
    height: 100%;
    border-right: 1px solid #ffffff26;
}

.create-game-wrapper .form-modal .form-wrapper .scroll-side .form-section .react-tagsinput .react-tagsinput-remove {
    cursor: pointer;
    font-weight: bold;
    width: 22px;
    height: 24px;
    font-size: 15px;
    padding: 4px;
    background: #9999997d;
    display: flex;
    justify-content: center;
    margin-left: 4px;
    align-items: center;
    color: #ffffffbf;
}

.editor .public-DraftEditorPlaceholder-root {
    opacity: 0.6;
}

.create-game-wrapper .form-modal .form-wrapper .scroll-side .form-section .react-tagsinput .react-tagsinput-tag {
    padding: 0 0 0 8px;
    display: inline-flex;
    align-items: center;
    font-size: 14px;
    color: #ffffffbf;
}

/* .create-game-wrapper .form-modal .form-wrapper .img-wrap p{opacity: 0.7; margin-bottom: 8px; font-size: 16px; font-weight: 500;} */
.create-game-wrapper .form-modal .scroll-side {
    height: 560px;
    overflow: auto;
    padding-right: 8px;
}

.create-game-wrapper .form-section {
    margin-bottom: 20px;
    border-bottom: 1px solid #ffffff26;
    padding-bottom: 20px;
}

.create-game-wrapper .form-modal .scroll-side::-webkit-scrollbar {
    width: 5px;
}

.create-game-wrapper .form-modal .scroll-side::-webkit-scrollbar-thumb {
    background: #2cfafe;
}

.create-game-wrapper .form-modal .scroll-side::-webkit-scrollbar-track {
    background: #323239;
    border-radius: 5px;
    border: 1px solid #5c5c5c;
}

.create-game-wrapper .form-modal .section-title {
    padding: 25px 30px;
    border-bottom: 1px solid #ffffff26;
}

.create-game-wrapper .form-btn-wrap {
    display: flex;
    justify-content: end;
    /* padding-top: 25px; */
    /* padding-bottom: 20px; */
    position: relative;
    z-index: 0;
}

.create-game-wrapper .form-btn-wrap .main-btn-style {
    font-family: "Montserrat";
    font-size: 17px;
    text-transform: uppercase;
    padding: 11px 24px;
}

.save-btn:is(:active, :focus, :focus-visible, :hover),
.comment-form-comment .submit-post .btn:active, .open-list-button:is(:active, :focus, :focus-visible, :hover) {
    background: transparent !important;
    color: #2cfafe !important;
    border-color: #2cfafe !important;
}


/* .create-game-wrapper .form-btn-wrap .save-btn:active:hover{color: #2cfafe !important;} */
.create-game-wrapper .form-btn-wrap .back-btn {
    background: #323239;
    color: #fff;
    border-color: #323239;
}

.create-game-wrapper .form-btn-wrap .back-btn:hover {
    background: transparent;
    color: #2cfafe;
    border-color: #2cfafe;
}

.create-game-wrapper .form-btn-wrap .save-btn:hover {
    /* background: #1554F0;
    color: #fff;
    border-color: #1554F0; */
}

.create-game-wrapper .form-modal h3 {
    /* font-size: 22px; */
}

.create-game-wrapper .dynamic-form .sd-file__decorator {
    background: #44444e;
    border: 1px solid #44444e;
}

.create-game-wrapper .dynamic-form .sd-file__decorator .sd-file__drag-area-placeholder {
    padding: 0 !important;
}

.create-game-wrapper .form-modal form {
    margin-bottom: 30px;
}

.dynamic-form .sd-action,
.sd-file__choose-btn.sd-action {
    background-color: transparent;
    font-size: 12px;
    border: 1px solid #2CFAFE;
    color: #2cfafe;
    box-shadow: none;
    outline: none;
    font-weight: 600;
    text-decoration: none;
    border-radius: 8px;
    padding: 7px 16px;
    position: relative;
    z-index: 1;
    transition: all 0.3s;
    font-family: 'Neon';
    font-weight: 700;
    opacity: 1;
}

.dynamic-form .sd-action:hover,
.sd-file__choose-btn.sd-action:hover {
    color: #000;
    background-color: #2cfafe !important;
}

.sd-question__required-text {
    font-size: 15px !important;
}

.create-game-wrapper label {
    /* padding-left: 10px; */
}

.custom-picker .rs-picker-toggle {
    border-color: #1554F0 !important;
    color: #1554F0;
    box-shadow: none;
}

.custom-picker .rs-picker-toggle .rs-picker-toggle-textbox,
.custom-picker .rs-picker-toggle .rs-picker-toggle-value {
    color: #1554F0;
}

.rs-picker-daterange-header {
    color: #1554F0;
}

.rs-calendar-table-cell-selected .rs-calendar-table-cell-content {
    background-color: #1554F0;
}

.rs-calendar-table-cell-in-range:before,
.rs-calendar-table-cell-content:hover,
.rs-picker-menu .rs-calendar .rs-calendar-table-cell:hover .rs-calendar-table-cell-content {
    background-color: #1554f030;
    color: #333;
}

.rs-btn-link {
    color: #1554F0;
}

.rs-btn-primary {
    background-color: #1554F0;
}

.rs-calendar-table-cell-is-today .rs-calendar-table-cell-content {
    box-shadow: inset 0 0 0 1px #1554F0;
}

.form-control.editor {
    height: 150px;
    padding: 10px;
    color: #000;
}

.public-DraftStyleDefault-block {
    margin: 0 !important;
}

.form-control.editor .rdw-editor-toolbar {
    background: #7a7a7a;
    ;
    border-color: #4e4e4e;
}

.form-control.editor .editor-class.rdw-editor-main {
    /* padding: 0 10px; */
    color: #fff;
    /* height: 150px; */
    overflow: auto;
}

.form-control.editor a {
    color: #000;
}

.upload-wrapper {
    position: relative;
    height: 250px;
    background: #44444E;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 250px;
    max-width: 100%;
    font-size: 24px;
    border-radius: 25px;
    overflow: hidden;
}

.video-upload-wrapper {
    width: 280px;
}

.upload-wrapper .upload-camera {
    position: absolute;
    width: 60px;
    height: 60px;
    background: #21FAFF;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    bottom: 0;
    right: 0;
    color: #212329;
    font-size: 25px;
}

.upload-wrapper input {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
}

.upload-wrapper .upload-img {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.upload-wrapper .logo-upload-img {
    width: 120px;
    height: 120px;
}

.upload-wrapper .upload-img.dummy-img img {
    /* width: 100px; */
    /* height: 100px; */
    /* object-fit: contain; */
}

.upload-wrapper .upload-img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.video-wrap .upload-camera {

    display: flex;
    align-items: center;
    justify-content: center;
}


.video-wrap .upload-camera input {
    display: none;
}

.video-wrap .upload-camera label {
    color: #2cfafe;
    /* padding: 0.5rem; */
    cursor: pointer;
    width: fit-content;
}

.video-wrap .upload-camera .icon {
    padding-top: 3px;
}

.video-wrap .upload-camera .icon svg {
    font-size: 20px;
    color: #2cfafe;
}

.upload-wrapper .upload-img .video-wrap {
    width: 100%;
    height: 100%;
}

.custom-form-modal .form-modal label {
    width: 100%;
}

.kJrhuj option {
    color: #fff !important
}

.form-modal label {
    width: 100%;
}

.main-content-area select {
    background: #1B1A21 !important;
    border: 2px solid #333639 !important;
    color: #fff;
}

.main-content-area select:focus {
    box-shadow: none !important;
    border: none !important;
}

.giftcard-table .actions {
    /* min-width: 80px; */
    display: flex;
}

.main-dashboard {
    position: relative;
}

/* .main-dashboard::before {
    content: '';
    background: url('./assets/images/home-overlay.png') no-repeat;
    background-position: top center;
    background-size: cover;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0.5;
    z-index: -1;
} */
.heading h4 {
    font-size: 18px;
}

.rs-picker-menu {
    z-index: 9;
}

.buyers-rewards {
    /* background: #33363940 ;
    padding: 20px 20px; */
}

.form-bg-wrapper {
    background: #33363940 0% 0% no-repeat padding-box;
    border: 2px solid #333639;
    border-radius: 20px;
    padding: 25px;
}

.guide {
    margin-bottom: 30px;
}

.guide .section-dark-bg {
    background: linear-gradient(90deg, #E55F5F 0%, #E55F5F80 100%);
    color: rgba(255, 255, 255, 0.753);
    border-radius: 6px;
    font-size: 16px;
    padding: 14px 30px;
}

.guide .section-green-bg {
    background: linear-gradient(90deg, #4bfa06 0%, #33FF68 100%);
    color: rgba(255, 255, 255, 0.753);
    border-radius: 6px;
    font-size: 20px;
    padding: 14px 30px;
    font-weight: bolder;

}

.guide span {
    font-weight: 500;
    color: #fff;
}

#edit-avatr-modal .content p {
    margin-bottom: 20px;
}

#edit-avatr-modal .content ul {
    list-style: circle;
    padding-left: 20px;
}

#edit-avatr-modal .content ul li {
    margin-bottom: 10px;
}

#edit-avatr-modal .content ul li a {
    color: #11d6b9;
}

#edit-avatr-modal .content ul li a:hover {
    text-decoration: underline;
}

.form-modal.contest {
    margin-top: 0;
}

.contest-card {
    background: #333639;
    padding: 30px;
    max-width: 100%;
    text-align: left;
    margin: 0 auto;
    border-radius: 20px;
    position: relative;
    padding-bottom: 100px;
    height: 100%;
}

.contest-card h4 {
    font-size: 28px;
    line-height: 40px;
    color: #1A1A1A;
}

.contest-card p {
    font-size: 18px;
    font-weight: 500;
    color: #7F8890;
}

.contest-card .points {
    display: inline-flex;
    justify-content: flex-start;
    align-items: center;
    background: rgb(61 34 239 / 15%);
    padding: 15px;
    border-radius: 10px;
    color: #3D22EF;
    font-family: 'Neon';
    font-weight: 900;
    margin-top: 16px;
    margin-bottom: 30px;
}

.form-modal.contest .contest-card .points label {
    width: auto;
    color: #3D22EF;
    font-size: 18px;
    font-weight: 900;
}

.contest-card .card-top {
    font-size: 24px;
    color: #3D22EF;
    font-family: 'Neon';
    font-weight: 900;
    padding-bottom: 20px;
    margin-bottom: 20px;
    border-bottom: 1px solid #e3e3e3;
}

.contest-card .card-bottom {
    position: absolute;
    background: rgb(0 0 0 / 15%);
    left: 0;
    bottom: 0;
    width: 100%;
    color: #7F8890;
    font-size: 14px;
    font-weight: bold;
    text-align: center;
    padding: 16px;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
}

.address-wrapper {
    padding-bottom: 20px;
    border-bottom: 2px solid #333639;
    margin-bottom: 20px;
    display: flex;
    width: 100%;
    align-items: center;
}

.address-wrapper .copy-wrapper {
    background: #1B1A21;
    position: relative;
    padding: 15px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 500;
    border: 2px solid #333639;
    border-radius: 10px;
    width: 70%;
}

.address-wrapper .share-btns {
    width: 30%;
    padding-left: 20px;
}

.copy-wrapper .copy-icon,
.field-wrapper .copy-icon {
    position: absolute;
    right: 0;
    background: #84e9f4;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    color: #000;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    cursor: pointer;
}

.field-wrapper .copy-icon {
    background-color: #1554f0;
    color: #fff;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
    font-size: 22px;
}

.copy-wrapper .copy-icon:hover .fa {
    transform: scale(1.2);
}

.share-btns label {
    margin-bottom: 0;
    margin-right: 10px;
}

.share-btns button {
    margin-right: 10px;
}

.share-btns button svg {
    width: 40px;
    height: 40px;
}

.not-found a {
    color: #84e9f4;
}

.not-found.sec4-not-found {
    min-height: 450px;
}

.form-modal.contest label {
    color: #7F8890;
    font-size: 18px;
    font-weight: bold;
}

.loader {
    position: absolute;
    left: 0;
    top: 0;
    background: #00000063;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 999;
}

.simple-layout {
    /* padding-top: 50px;
    padding-bottom: 20px;
    min-height: 800px; */
    padding: 50px 0px 20px 0px;
}

.simple-layout .page-title {
    margin-bottom: 50px;
}

.infinite-scroll-component::-webkit-scrollbar {
    display: none;
}

.form-modal .react-tagsinput {
    background-color: #44444E;
    border: 2px solid #333639 !important;
    border-radius: 10px;
}

.css-1s2u09g-control {
    border-radius: 10px !important;
}

.form-modal .react-tagsinput-tag {
    /* background-color: #000;
    border-radius: 2px;
    border: 1px solid #333639 !important;
    color: #F2F2F2; */
}

.form-modal .react-tagsinput-input {
    color: #F2F2F2;
    font-size: 17px;
    width: 100%;
}

.form-modal .custom-group {
    display: flex;
    align-items: center;
    font-size: 16px;
}

/* .promotion-modal .modal-content {
    background-image: url('./assets/images//promotion-bg.png');
    border-radius: 30px;
    background-size: 100% 100%;
    padding: 100px 0;
} */

.promotion-modal .modal-dialog {
    max-width: 600px;
    height: 400;
}

.promotion-modal .modal-content {
    height: 400px;
}

.promotion-modal .modal-content .modal-header {
    border: none;
    position: absolute;
    right: 30px;
    top: 30px;
    background: #ffffff45;
    color: #fff;
    width: 60px;
    height: 60px;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    cursor: pointer;
}

.promotion-modal .modal-content .modal-header .btn-close {
    filter: invert(1);
    opacity: 1;
    padding: 0;
    margin: 0;
}

.promotion-modal .modal-content .image {
    width: 220px;
    height: 220px;
    margin: 0 auto;
}

.promotion-modal .modal-content .image .img-fluid {
    width: 100%;
    height: 100%;
    object-fit: cover;
    opacity: 0.5;
}

.promotion-modal .modal-content .content h2 {
    margin-bottom: 20px;
}

.promotion-modal .modal-content .content {
    max-height: 400px;
    overflow: auto;
}

::-webkit-scrollbar {
    width: 8px;
    height: 8px;
}

::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 5px;
}

::-webkit-scrollbar-thumb {
    background: transparent linear-gradient(270deg, #00FFD0 0%, #263ABD 100%) 0% 0% no-repeat padding-box;
    border-radius: 5px;
}

.promotion-modal .modal-content .content p,
.promotion-modal .modal-content .content ul {
    margin-bottom: 10px;
}

.promotion-modal .modal-content .content ul li {
    margin-bottom: 5px;
}

.promotion-modal .modal-content .content .action-btn {
    margin-top: 50px;
}

.promotion-modal .modal-content .content .btn-primary {
    background: #84E9F4;
    color: #000;
    font-family: 'Neon';
    font-weight: 900;
    padding: 15px 20px;
    border-radius: 8px;
    border-color: #84E9F4;
    box-shadow: none;
    outline: none;
}

.promotion-modal .modal-content .content .btn-primary:hover {
    background: transparent;
    color: #84E9F4;
}

.posts-myntist .container1 .featured-area-wrapper {
    height: 450px;
    display: flex;
    margin-bottom: 50px;
}

.posts-myntist .container1 .featured-area-wrapper .fp-col2 {
    display: flex;
    width: 60%;
    flex-wrap: wrap;
}

.posts-myntist .container1 .featured-area-wrapper .col1 {
    width: 40%;
    position: relative;
}

.posts-myntist .container1 .featured-area-wrapper .col2,
.posts-myntist .container1 .featured-area-wrapper .col3,
.posts-myntist .container1 .featured-area-wrapper .col4,
.posts-myntist .container1 .featured-area-wrapper .col5 {
    width: 50%;
    height: 50%;
    position: relative;
}

.posts-myntist .author-info .auth-url:hover {
    text-decoration: underline;
}

.fp-col1 .featured-header,
.fp-col2 .f-col .featured-header {
    position: absolute;
    width: 100%;
    padding: 5%;
    left: 0;
    bottom: 0;
    text-shadow: 1px 1px 2px rgb(0 0 0 / 30%);
    background: linear-gradient(to bottom, transparent, rgba(0, 0, 0, .7));
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}

.featured-header .auth-byline {
    color: #a3a3a3;
}

.featured-item:hover+.featured-header .author-info {
    margin-bottom: 20px !important;
    transition: all .3s ease-out;
}

.featured-header:hover .author-info {
    margin-bottom: 20px !important;
    transition: all .3s ease-out;
}

.masonry-item .content-tags {
    background-color: #333134;
    padding: 16px 32px;
    display: flex;
    flex-wrap: wrap;
}

.masonry-item .content-tags a {
    text-transform: uppercase;
    color: #a3a3a3;
    font-size: 12px;
}

.masonry-item .content-tags a:hover {
    text-decoration: underline;
}

.masonry-item .content-tags a:not(:last-child) {
    margin-right: 15px;
}

.featured-header {
    font-size: 14px;
}

.notification-dot {
    position: relative;
}

.table th,
.table td {
    color: #fff;
}

.table .ava-img {
    width: 50px;
}

.table tbody tr {
    vertical-align: middle;
}

.notification-dot .icon-dot {
    background: #F9896B 0% 0% no-repeat padding-box;
    opacity: 1;
    color: #ffffff;
    position: absolute;
    top: -7px;
    border-radius: 50%;
    right: -7px;
    width: 17px;
    height: 17px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.notification-dot .icon-dot .count {
    font-size: 8px
}

.masonry-item a {
    position: relative;
}

.masonry-item .bb-post-meta {
    font-size: 10px;
    position: absolute;
    bottom: 10px;
    right: 10px;
    background-color: rgba(40, 40, 40, .5);
    padding: 4px 8px;
    border-radius: 25px;
}

.slick-track {
    display: flex;
}

.promotion-wrapper .promotion-description {
    max-height: 105px;
    overflow-y: auto !important;
    overflow: hidden;
    margin-top: 20px;
}

.table-r-inactive {
    opacity: 0.3;
    cursor: not-allowed;
}

.notifications .list ul {
    background-color: #1e1e24;
}

.swal2-title {
    font-size: 18px !important;
}

.swal2-icon.swal2-success [class^=swal2-success-circular-line][class$=left],
.swal2-icon.swal2-success .swal2-success-fix,
.swal2-icon.swal2-success.swal2-icon-show .swal2-success-circular-line-right {
    display: none;
}

.swal2-container.swal2-center>.swal2-popup {
    background: linear-gradient(84deg, #11D6B9 0%, #1554F0 100%);
    color: #fff;
}

.swal2-styled.swal2-confirm {
    background-color: #11D6B9 !important;
    color: #000 !important;
}

.swal2-styled.swal2-confirm:focus,
.swal2-styled.swal2-confirm:focus-visible {
    box-shadow: none;
    outline: none;
}

.nft-slider .item .nft-image {
    position: relative;
}

.chat-close-component {
    position: relative;
}

.my-nfts .nft-list .item .nft-image .img {
    width: 200px;
    height: 200px;
}

.answer-wrapper .answer-wrapper-img {
    position: relative;
}

.answer-wrapper .correct-wrapper-icon {
    width: 65px;
    height: 65px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #ffffff;
    border: 1px solid silver;
    border-radius: 50%;
}

.answer-wrapper .icon-wrapper-center {
    position: absolute;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    top: 0;
    display: none !important;
}

.answer-wrapper .correct-wrapper-icon .fa-check-icon,
.answer-wrapper .correct-wrapper-icon .fa-times-icon {
    color: silver;
    font-size: 35px;
    font-weight: 900;
}

.answer-wrapper:hover .icon-wrapper-center {
    display: flex !important;
}

.answer-wrapper.correct-wrapper .icon-wrapper-center {
    display: flex !important;
}

.answer-wrapper.correct-wrapper .correct-wrapper-icon .fa-check-icon {
    color: rgba(0, 255, 146, 0.8) !important;
}

.answer-wrapper.wrong-wrapper .icon-wrapper-center {
    display: flex !important;
}

.answer-wrapper.wrong-wrapper .correct-wrapper-icon .fa-times-icon {
    color: #eb2739 !important;
}

.answer-wrapper.wrong-wrapper .answer-wrapper-img {
    opacity: 0.5;
}

.answer-wrapper.wrong-wrapper {
    border: 2px solid #eb2739 !important;
}

.answer-wrapper.correct-wrapper {
    border: 2px solid rgba(0, 255, 146, 0.8) !important;
}

.check-wrapper {
    width: 33px;
    height: 33px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    border: 1px solid #e2e2e2;
    border-radius: 50%;
}

.check-wrapper .fa-times-icon {
    color: #e2e2e2;
    font-size: 16px;
    font-weight: 900;
    /* display: none; */
}

.answer2-wrapper:hover .check-wrapper .fa-times-icon {
    display: unset !important;
}

.answer2-wrapper.correct-wrapper .check-wrapper .fa-times-icon {
    display: unset !important;
}

.answer2-wrapper.correct-wrapper .answer2-container .check-wrapper .fa-check-icon {
    color: rgba(0, 255, 146, 0.8) !important;
}

.answer2-wrapper.wrong-wrapper .check-wrapper .fa-times-icon {
    display: unset !important;
}

.check-wrapper .fa-times-icon {
    color: #eb2739 !important;
}

.points-table .ava-img {
    width: 40px;
}

.treasurebox-header {
    /* padding: 0 48px; */
    position: fixed;
    width: 100%;
    z-index: 999;
    top: 0;
    left: 0;
}

.treasurebox-header::after {
    content: "";
    width: 100%;
    height: 100%;
    background: #131313;
    opacity: 0.8;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
}

.treasurebox-sec {
    max-width: 1668px;
    margin: 0 auto;
    padding-top: 90px;
    position: relative;
    overflow: hidden;
}

.simple-layout.layout3 .treasurebox-sec {
    padding-top: 70px;
}

.treasurebox-sec .container-mini {
    max-width: 1400px;
    margin-left: auto;
    margin-right: auto;
}

.treasurebox-sec::after {
    /* content: '';
    position: absolute;
    width: 400px;
    height: 400px;
    background-image: url('../src/assets/images/home-bg-bottom.png');
    right: 0;
    bottom: -40px;
    background-size: contain;
    z-index: -1; */
}

.treasurebox-header .navbar-brand .image {
    width: 50px;
    margin-right: 7px;
}

.treasurebox-header nav.navbar {
    padding-top: 20px;
    max-width: 1648px;
    margin: 0 auto;
}

.treasurebox-header nav.navbar .main-btn-style {
    background: transparent;
    border-color: #1554F0;
    color: #fff;
    min-height: 44px;
    line-height: 22px;
    font-family: "Neon";
}

.treasurebox-header nav.navbar .main-btn-style:hover,
.treasurebox-header nav.navbar .main-btn-style.is-active,
.treasurebox-header nav.navbar .main-btn-style:hover,
.treasurebox-header nav.navbar .main-btn-style.active {
    background: #1554F0;
}

.treasurebox-header .navbar-brand {
    color: #F2F2F2;
    font-family: 'Neon';
}

.treasurebox-header .nav-link {
    color: #fff;
    text-transform: uppercase;
    opacity: .4;
    font-family: "Neon";
    font-weight: 900;
    line-height: 20px;
    overflow-x: hidden;
    overflow-y: visible;
    padding: 8px 20px;
    text-decoration: none;
}

.treasurebox-header .nav-link.is-active {
    opacity: 1;
}

.treasurebox-header .nav-link {
    position: relative;
    z-index: 2;
}

.treasurebox-header .nav-link:not(:last-child) {
    margin-right: 54px;
}

.parent-tag .dropdown-nav {
    display: none;
    transition: transform .5s cubic-bezier(0.88, -0.72, 0, 1), opacity .5s ease-in-out;
    transform: translateX(-50%);
    list-style: none;
    margin: 0;
    padding: 0;
    position: absolute;
    z-index: 22;
    top: 36px;
    left: 50%;
    padding-top: 20px;
    width: 700px;
}

.tag-click .dropdown-nav {
    display: block;
    /* transform: translateY(0); */
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
}

.parent-tag .dropdown-nav section ul {
    /* display: block;
    column-count: 4; */
}

.parent-tag .dropdown-nav section ul.social-links {
    display: none !important;
}

.parent-tag .dropdown-nav section ul:first-child {
    /* margin-bottom: 20px; */
    /* border-bottom: 1px solid #8a99a1; */
    /* padding-bottom: 20px; */
    margin-bottom: 0;
}

.parent-tag .dropdown-nav section {
    /* padding: 30px 20px; */
    border-radius: 8px;
    /* background: linear-gradient(84deg, #11D6B9 0%, #1554F0 100%); */
    background: #fff;
    position: relative;
}

.parent-tag .dropdown-nav section::before {
    position: absolute;
    top: -13px;
    right: 50%;
    transform: translateX(-50%);
    margin-right: -6px;
    display: block;
    content: "";
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 13px 15px 13px;
    /* border-color: transparent transparent #1554F0 transparent; */
    border-color: transparent transparent #fff transparent;
}

.parent-tag .dropdown-nav section ul li {
    /* width: 29%; */
    margin: 0 7px 10px;
    font-weight: 600;
}

.parent-tag .dropdown-nav section ul.social-links li {
    width: fit-content;
}

.treasurebox-header .nav-link::before {
    content: "-";
    position: absolute;
    top: -7px;
    left: -23px;
    font-size: 38px;
    color: #2cfafe;
    transition: all .4s ease-in-out;
}

.treasurebox-header .profile-notifications .nav-link::before {
    display: none;
}

.treasurebox-header .nav-link:hover::before {
    left: 100%;
    transition: all .4s ease-in-out;
}

.treasurebox-header .nav-link:hover {
    opacity: 1;
    transition: 0.3s ease-in-out;
}

.search-login-btn .search-icon,
.profile-notifications button {
    margin-left: 12px;
    padding: 8px;
    width: 44px;
    height: 44px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 7px;
    cursor: pointer;
    border: 2px solid #1554F0;
    color: #FFF;
    background: transparent;
    transition: all 0.3s;
}

.profile-notifications button {
    overflow: hidden;
}

.profile-notifications button img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    background: #2cfafe;
}

.treasurebox-tabs .tabs-wrap .select-tabs-box .tags-select {
    height: 60px;
    padding: 0;
    border: 0;
}

.treasurebox-tabs .tabs-wrap .select-tabs-box .tags-select:focus-within {
    box-shadow: none;
}

.treasurebox-tabs .tabs-wrap .select-tabs-box .tags-select .react-dropdown-select-clear {
    display: none;
}

.treasurebox-tabs .tabs-wrap .select-tabs-box .tags-select .react-dropdown-select-content {
    height: 100%;
    position: relative;
    padding: 10px 70px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 16px;
    font-family: "Neon";
    text-transform: uppercase;
    color: #fff;
    opacity: 1;
    font-weight: 900;
}

.treasurebox-tabs .tabs-wrap .select-tabs-box .tags-select .react-dropdown-select-content input {
    font-size: 16px;
    font-family: "Neon";
    text-transform: uppercase;
    color: #fff;
    opacity: 1;
    font-weight: 900;
    width: 100%;
    margin: 0;
}

.treasurebox-tabs .tabs-wrap .select-tabs-box .tags-select .react-dropdown-select-content::before {
    font-family: "Fontawesome";
    content: "\f002";
    position: absolute;
    font-size: 20px;
    color: #fff;
    top: 50%;
    transform: translateY(-50%);
    left: 20px;
}

.treasurebox-tabs .tabs-wrap .select-tabs-box .tags-select .react-dropdown-select-dropdown {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    background: #323239;
    padding: 12px;
    width: 100%;
    border-color: #323239;
    top: calc(100% + 12px);
}

.treasurebox-tabs .tabs-wrap .select-tabs-box .tags-select .react-dropdown-select-dropdown .react-dropdown-select-item {
    border-radius: 7px;
    background-color: #44444e;
    color: #fff;
    opacity: .7;
    margin: 7px;
    padding: 11px 20px;
    font-weight: 600;
    font-size: 17px;
    border: 1px solid #1554f0;
    text-transform: capitalize;
    transition: all 0.3s;
}

.treasurebox-tabs .tabs-wrap .select-tabs-box .tags-select .react-dropdown-select-dropdown .react-dropdown-select-item:hover {
    background-color: #1554F0;
    opacity: 1;
}

.treasurebox-tabs .tabs-wrap .select-tabs-box .tags-select .react-dropdown-select-dropdown .react-dropdown-select-no-data {
    font-size: 17px;
    font-weight: 600;
    color: #fff;
    width: 100%;
}

.treasurebox-tabs .tabs-wrap .select-tabs-box .tags-select .react-dropdown-select-dropdown::-webkit-scrollbar {
    width: 5px;
}

.search-login-btn .search-icon:hover {
    background: #2CFAFE;
    color: #000;
}

.transition {
    transition: all 0.3s ease-in-out;
}

.search-login-btn {
    position: relative;
    /* z-index: 2; */
}

.search-login-btn .refresh-search {
    position: absolute;
    right: 50px;
    background: transparent;
    border: none;
    color: #979494;
    top: 2px;
}

.search-login-btn .input-width {
    width: 450px;
    padding: 10px 90px 10px 10px;
    opacity: 1;
}

.search-login-btn input {
    height: 44px;
    transition: all .3s ease-in-out;
    position: absolute;
    right: 0;
    top: 0;
    border-radius: 7px;
    background: transparent;
    border: 2px solid #1554F0;
    opacity: 0;
    /* border-right-width: 1px; */
}

.search-login-btn input:focus-visible {
    outline: none;
}

/* .search-login-btn .input-width{width: 400px;} */
.treasurebox-tabs ul li {
    margin: 7px;
}

.treasurebox-tabs ul li:first-child {
    /* margin-left: 0; */
}

.treasurebox-tabs ul li:last-child {
    /* margin-right: 0; */
}

.search-login-btn .search-icon svg {
    /* border: 2px solid #1994F9;
    border-radius: 6px;
    padding: 14px; */
    /* color: #fff; */
}

.search-login-btn .login-btn {
    border: 2px solid #2CFAFE;
    border-radius: 6px;
    padding: 10px;
    color: #2CFAFE;
    opacity: 1;
    padding: 9px 14px !important;
}

.search-login-btn .login-btn:hover {
    background-color: #2CFAFE;
    color: #030203;
}

.treasurebox-sec .heading h3 {
    font-size: 30px;
}

.treasurebox-sec .heading h1 {
    font-size: 102px;
    font-family: 'Montserrat';
    font-weight: 700;
}

.heading-img-sec {
    margin-bottom: 50px;
}

.treasurebox-tabs .tabs-wrap {
    background-color: #323239;
    padding: 8px;
    border-radius: 12px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 50px;
    border: 1px solid #323239;
    align-items: flex-start;
}

.treasurebox-tabs .tabs-wrap.active {
    border-color: #2cfafe;
    background: #323239;
}

.treasurebox-tabs ul.nav-tabs {
    border: 0;
    width: calc(100% + -220px);
    /* background-color: #fff;
    padding: 17px;
    border-radius: 12px;
    margin-bottom: 50px;
    padding-bottom: 10px;
    border-color: #ffffff3d; */
}

.treasurebox-tabs.wall-tabs ul.nav-tabs {
    width: 100%;
    padding: 17px;
    padding-bottom: 10px;
}

.tabs-wrap.active ul.nav-tabs {
    height: 0;
    width: 0;
    overflow: hidden;
    transition: all 0.3s;
}

#tabs-select ul {
    background-color: #000;
}

#tabs-select ul li {
    /* margin-right: 0; */
}

#tabs-select .search-wrapper {
    background: #21232926;
    border-radius: 8px;
    padding: 8px 10px;
    border: 2px solid #2cfafe;
    word-break: break-all;
    overflow: hidden;
    height: 60px;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: relative;
}

.treasurebox-tabs .tabs-wrap.active #tabs-select .search-wrapper {
    border: none;
    background: transparent;
    padding: 0;
    padding-left: 55px;
}

#tabs-select .search-wrapper input {
    margin-top: 0;
    font-family: "Neon";
    color: #fff;
    opacity: 0.7;
    font-size: 17px;
    font-weight: 700;
    width: 100%;
    height: 100%;
    padding-left: 20px;
    padding-right: 80px;
    position: relative;
}

/* #tabs-select .search-wrapper input::before{font-family: "Fontawesome";content: "\f00d"; position: absolute; font-size: 20px; color: #fff; top: 50%; transform: translateY(-50%); left: 20px;} */
.treasurebox-tabs .tabs-wrap.active #tabs-select .search-wrapper::before {
    font-family: "Fontawesome";
    content: "\f002";
    position: absolute;
    font-size: 20px;
    color: #fff;
    top: 50%;
    transform: translateY(-50%);
    left: 20px;
}

#tabs-select .search-wrapper input::-webkit-input-placeholder {
    color: #fff;
}

#tabs-select .search-wrapper .chip {
    color: #fff;
    opacity: 0.8;
    position: relative;
    margin-bottom: 0;
    font-size: 16px;
    font-weight: 700;

}

#tabs-select .search-wrapper .chip img {
    /* display: none; */
    filter: brightness(0.5);
}

#tabs-select .search-wrapper .icon_cancel.icon_down_dir {
    display: none;
}

#tabs-select .search-wrapper .chip::after {
    /* font-family: "Fontawesome";
    content: "\f00d";
    height: 17px;
    width: 17px;
    position: absolute;
    right: -12px;
    top: 6px;
    color: #757C80;
    font-size: 10px;
    font-weight: 400 !important;
    border: 1px solid #757C80;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center; */
}

.tabs-wrap.active .select-tabs-box {
    /* width: 300px; */
    position: relative;
    width: 100%;
}

.select-tabs-box ul {
    padding: 8px !important;
    border-radius: 8px !important;
    border-color: #323239 !important;
    background-color: #323239 !important;
    max-height: 280px !important;
    display: flex !important;
    flex-wrap: wrap;
}

.select-tabs-box ul::-webkit-scrollbar,
.submission__menu-list::-webkit-scrollbar {
    width: 5px !important;
}

.select-tabs-box .optionListContainer {
    background: none;
    margin-top: 15px;
}

.select-tabs-box .optionListContainer ul span.notFound {
    color: #000 !important;
    opacity: 1 !important;
}

.select-tabs-box ul li {
    border-radius: 7px;
    background-color: #44444E;
    color: #fff;
    opacity: 0.7;
    margin: 7px;
    padding: 11px 20px;
    font-weight: 600;
    font-size: 17px;
    border: 1px solid #1554F0;
    text-transform: capitalize;
    width: fit-content;
    height: fit-content;
    transition: all 0.3s;
}

.select-tabs-box ul li:hover {
    background-color: #1554F0;
    color: #fff;
    opacity: 1;
}

.select-tabs-box button {
    padding: 10px;
    font-size: 18px;
    font-weight: 600;
    border-radius: 8px;
    background: #fff;
    color: #1554F0;
    width: 55px;
    height: 55px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 7px;
}

.tabs-wrap .select-tabs-box {
    position: relative;
}

.tabs-wrap .select-tabs-box button.close-select {
    opacity: 0;
    transition: all 0.1s;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: -10px;
    cursor: default;
    padding: 5px;
    width: 23px;
    height: 23px;
    font-size: 14px;
    z-index: 1;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #ffffff99;
    color: #000;
    margin: 0 !important;
}

.tabs-wrap.active .select-tabs-box button.close-select {
    opacity: 1;
    right: 20px;
    cursor: pointer;
}

.tabs-wrap .select-tabs-box button:hover {
    color: #000;
}

.treasurebox-tabs ul.nav-tabs .nav-item .nav-link {
    background-color: #44444E;
    border-radius: 10px;
    color: #B4B8C3;
    font-size: 13px;
    font-weight: 600;
    /* margin-bottom: 8px; */
    padding: 16px 30px;
    transition: all 0.3s ease-in-out;
    border: none;
    font-family: inherit;
    opacity: 1;
}

.treasurebox-tabs ul.nav-tabs .nav-item .nav-link:last-child {
    margin-right: 0;
}

.treasurebox-tabs ul.nav-tabs .nav-item .nav-link.active {
    background-color: #1554F0;
    color: #fff;
}

.treasurebox-tabs ul.nav-tabs .nav-item .nav-link:hover {
    background-color: #1554F0;
    color: #fff;
    border: none;
}

.footer-sec {
    background-color: #333639;
}

.footer-sec-padding {
    padding: 80px 96px 0 96px;
    margin-top: 30px;
}

.footer-sec .footer-logo {
    width: 50px;
}

.footer-sec .navbar-brand {
    color: #F2F2F2;
    font-family: 'Neon';
    margin-bottom: 25px;
}

.footer-sec .footer-wrapper p {
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 60px;
    text-align: justify;
}

.footer-sec .footer-wrapper h5 {
    margin-bottom: 20px;
    margin-top: 10px;
}

.footer-sec .social-links li,
.parent-tag .dropdown-nav section ul.social-links a {
    width: 45px;
    height: 45px;
    background-color: #fff;
    border-radius: 50px;
    font-size: 20px;
}

.footer-sec .social-links li {
    margin-bottom: 8px;
}

.footer-sec .social-links li.fa,
.parent-tag .dropdown-nav section ul.social-links li.fa a {
    color: #1877f2;
}

.footer-sec .social-links li.fa:hover,
.parent-tag .dropdown-nav section ul.social-links li.fa a:hover {
    background-color: #1877f2;
    color: #fff;
    transition: .3s ease-in-out;
}

.footer-sec .social-links li.me,
.parent-tag .dropdown-nav section ul.social-links li.me a {
    color: #02b875;
}

.footer-sec .social-links li.me:hover,
.parent-tag .dropdown-nav section ul.social-links li.me a:hover {
    background-color: #02b875;
    color: #fff;
    transition: .3s ease-in-out;
}

.footer-sec .social-links li.in,
.parent-tag .dropdown-nav section ul.social-links li.in a {
    color: #c32aa3;
}

.footer-sec .social-links li.in:hover,
.parent-tag .dropdown-nav section ul.social-links li.in a:hover {
    background-color: #c32aa3;
    color: #fff;
    transition: .3s ease-in-out;
}

.footer-sec .social-links li.li,
.parent-tag .dropdown-nav section ul.social-links li.li a {
    color: #0077B5;
}

.footer-sec .social-links li.yt {
    color: #ff0505;
}

.footer-sec .social-links li.li:hover,
.parent-tag .dropdown-nav section ul.social-links li.li a:hover {
    background-color: #0077B5;
    color: #fff;
    transition: .3s ease-in-out;
}

.footer-sec .social-links li.yt:hover,
.parent-tag .dropdown-nav section ul.social-links li.yt a:hover {
    background-color: #df0404;
    color: #fff;
    transition: .3s ease-in-out;
}

.footer-sec .social-links li.re,
.parent-tag .dropdown-nav section ul.social-links li.re a {
    color: #ff4500;
}

.footer-sec .social-links li.re:hover,
.parent-tag .dropdown-nav section ul.social-links li.re a:hover {
    background-color: #ff4500;
    color: #fff;
    transition: .3s ease-in-out;
}

.footer-sec .social-links li.gi,
.parent-tag .dropdown-nav section ul.social-links li.gi a {
    color: #171515;
}

.nft-meta .author-name .main-btn-style {
    padding: 6px 12px;
    font-size: 13px;
    font-family: "Montserrat";
}

.nft-meta .author-name .main-btn-style:hover,
.custom-modal .main-btn-style:hover {
    color: #2cfafe !important;
}

.nft-meta .author-name .main-btn-style:active{color: #000 !important}

.footer-sec .social-links li.gi:hover,
.parent-tag .dropdown-nav section ul.social-links li.gi a:hover {
    background-color: #171515;
    color: #fff;
    transition: .3s ease-in-out;
}

.footer-sec .social-links li.te,
.parent-tag .dropdown-nav section ul.social-links li.te a {
    color: #08c;
}

.footer-sec .social-links li.te:hover,
.parent-tag .dropdown-nav section ul.social-links li.gi a:hover {
    background-color: #08c;
    color: #fff;
    transition: .3s ease-in-out;
}

.footer-sec .social-links li.yo,
.parent-tag .dropdown-nav section ul.social-links li.yo a {
    color: #FF2D2D;
}

.footer-sec .social-links li.yo:hover,
.parent-tag .dropdown-nav section ul.social-links li.yo a:hover {
    background-color: #FF2D2D;
    color: #fff;
    transition: .3s ease-in-out;
}

.footer-sec .social-links li.tw,
.parent-tag .dropdown-nav section ul.social-links li.tw a {
    color: #0995DE;
}

.footer-sec .social-links li.tw:hover,
.parent-tag .dropdown-nav section ul.social-links li.tw a:hover {
    background-color: #0995DE;
    color: #fff;
    transition: .3s ease-in-out;
}

.footer-sec .gamification-logo span:first-child {
    width: 45px;
    height: 45px;
    border-radius: 50%;
    display: inline-block;
    overflow: hidden;
}

.footer-sec .gamification-logo span:last-child {
    font-family: "Neon";
    text-transform: uppercase;
    font-size: 17px;
}

.footer-links-wrapper h5 {
    margin-bottom: 50px;
}

.footer-links-ul li {
    margin-bottom: 15px;
}

.footer-links-ul a {
    font-weight: 500;
    font-size: 18px;
}

.footer-links-ul a:hover {
    color: #97b5ff;
    padding-bottom: 5px;
    transition: .3s ease-in-out;
}

.footer-links-col {
    margin-bottom: 40px;
}

.footer-sec hr {
    border-top: 2px solid #404040;
    opacity: 1;
}

.all-right-services {
    padding: 30px 0;
}

.treasurebox-tabs .tab-content .active {
    background: transparent;
}

.treasurebox-sec .box-lottie {
    width: 290px;
    height: 290px;
    margin-right: 180px;
}

.nft-sec1 .nft-meta .views {
    width: 20px;
}

.nft-sec1 h2 {
    font-size: 26px;
    line-height: 28px;
    padding-left: 12px;
}

.post-skeleton>div {
    display: flex;
    flex-direction: row !important;
    justify-content: space-between;
}

.nft-sec .inner-wrapper h3 {
    font-family: 'Montserrat';
    font-size: 17px;
    font-weight: 500;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 24px;
    text-transform: capitalize;
}

.wrapper2 .inner-wrapper h3,
.wrapper3 .inner-wrapper h3 {
    margin-bottom: 8px;
    word-break: break-all;
}

.wrapper2 .inner-wrapper h3 {
    /* position: absolute; left: 11px; top: calc(100% + -65px);  */
    line-height: 22px;
    margin: 0;
    font-size: 17px;
    padding: 0 10px;
    margin-bottom: 5px;
}

.wrapper2.right .inner-wrapper h3 {
    padding: 0;
    width: 95%;
    margin: 0 auto 6px;
}

.wrapper4 .tags,
.wrapper1 .tags {
    /* border-top: 1px solid #434343; */
    padding-top: 5px;
    margin-bottom: 8px;
    max-height: 30px;
    overflow: hidden;
}

.nft-sec .nft-meta {
    /* margin-bottom: 10px; */
    /* background-color: #323639;
    border-radius: 50px;
    padding: 13px 18px;
    margin: 0 25px;
    position: absolute;
    margin-top: -25px;
    left: 0;
    right: 0;
    bottom: -30px; */
}

.nft-sec2 .nft-meta,
.nft-sec3 .nft-meta {
    background-color: transparent;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
    width: 95%;
}

.nft-sec3 .nft-meta {
    top: calc(100% + -43px);
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
}

.nft-sec4 .nft-meta {
    /* background-color: transparent; bottom: 0px; margin: 0 10px; padding: 13px 10px; */
    /* position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 8px 12px; */
}

.nft-sec1 .views-votes {
    background-color: transparent;
    border-radius: 20px;
}

.boosted-detail {
    display: flex;
    justify-content: space-between;


}

.boosted-detail .boosted-icon {
    position: absolute;
    /* width: 100%; */
    background: #212329B2;
    padding: 2px 10px;
    top: 10px;
    z-index: 1;
}

.boosted-detail .boosted-icon span {
    font-size: 13px;
    font-weight: 500;
}

.boosted-detail .boosted-icon.boosted-time {
    left: 0;
    border-radius: 0 20px 20px 0;
    padding-left: 5px;
}

.boosted-detail .boosted-icon.boosted-rocket {
    right: 0;
    border-radius: 20px 0 0 20px;
    padding-right: 9px;
}

.boosted-detail .boosted-icon .icon {
    width: 27px;
    height: 27px;
    display: flex;
    justify-content: center;
    align-items: center;
    /* padding-bottom: 6px; */
}

.boosted-detail .boosted-icon .timer img {
    width: 10px;
}

.boosted-detail .boosted-icon .rocket img {
    width: 14px;
}

/* .boosted-detail .boosted-icon .icon img{width: 100%; height: 100%; object-fit: contain;} */
.nft-sec1 .votes {
    font-size: 11px;
}

.nft-sec1 .author-name p {
    font-size: 14px;
    font-weight: 500;
}

.nft-sec1 .author-name p span {
    color: #FFFFFF80;
}

.nft-sec1 {
    margin-bottom: 30px;
    padding-bottom: 30px;
    border-bottom: 1px solid #727272;
}

.not-found {
    height: 92%;
    background-color: #323239;
    border-radius: 10px;
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.not-found .post-img {
    width: 100px;
    height: 100px;
    margin-bottom: 20px;
    opacity: 0.8;
}

.not-found p {
    font-size: 18px;
    color: #fff;
    opacity: 0.7;
    font-weight: 600;
}

.nft-sec1 .slick-track .slick-slide {
    padding: 7px;
}

.nft-sec2,
.nft-sec3 {
    margin-bottom: 15px;
}

.nft-trending {
    margin-bottom: 25px;
    border-bottom: 1px solid #727272;
    /* margin-bottom: 25px;
    background-color: #1e2a2c;
    padding: 30px 20px;
    border: 1px solid #2cfafe;
    border-radius: 16px; */
}

.nft-trending h2,
.treasurebox-tabs h2 {
    color: #fff;
}

.nft-sec2 .wrapper2 .inner-wrapper a {
    display: block;
    height: 100%;
}

.nft-meta .views-votes,
.nft-meta .author-name {
    /* background-color: #00000067; */
    /* border-radius: 20px; */
    /* min-width: 85px; */
    padding: 3px 0;
}

.wrapper2 .nft-meta .views-votes,
.wrapper3 .nft-meta .views-votes {
    background-color: #00000067;
    border-radius: 20px;
    min-width: 100px;
    padding: 3px 8px;
}

.nft-meta .views {
    /* width: 20px; */
    /* padding-bottom: 4px; */
}

.post-details-content .trending-posts .new-trending .nft-meta .views {
    width: fit-content;
}

.post-details-content .trending-posts .new-trending .nft-meta .views-votes,
.nft-meta-trending {
    padding: 5px 0px;
    border-radius: 18px;
}

.post-details-content .trending-posts .new-trending .nft-meta .views-votes {
    background-color: #21232996;
    padding: 4px 8px;
}

.nft-meta-trending .views-votes {
    min-width: 90px;
    justify-content: space-between;
    background-color: #21232996;
    padding: 8px 10px;
    width: fit-content;
    border-radius: 30px;
}

.nft-meta-trending .views-votes .views span {
    /* font-size: 12px; */
}

.nft-meta-trending .views-votes .views {
    padding-bottom: 0;
}

.nft-meta .views-votes .views svg {
    width: 20px !important;
}

.nft-meta span {
    font-size: 15px;
    font-weight: 500;
}

.nft-meta .author-name p span:nth-child(2),
.nft-meta .author-name p {
    text-transform: capitalize;
    color: #fff;
    font-weight: 600;
}

.nft-meta .votes {
    font-size: 11px;
}

.nft-meta .author-name p {
    font-size: 12px;
    font-weight: 500;
}

.nft-meta .author-name p span {
    color: #FFFFFF80;
}

.nft-sec2 .wrapper2 {
    position: relative;
    /* height: 100%; */
    min-height: 360px;
    padding: 6px;
    overflow: hidden;
}

.nft-sec2 .wrapper2.left .image {
    height: 350px;
    width: 100%;
    border-radius: 12px;
    overflow: hidden;
    margin-bottom: 10px;
    transition: all 0.3s;
    background: #baedff;
}

.nft-sec2 .wrapper2.left:hover .inner-wrapper .post-bottom {
    opacity: 1;
    top: calc(100% + -86px);
}

.nft-sec2 .wrapper2.left:hover .inner-wrapper .image {
    height: 300px;
    transition: all 0.3s;
}

.nft-sec3 .wrapper3 .image {
    height: 300px;
    width: 100%;
    border-radius: 12px;
    overflow: hidden;
    margin-bottom: 10px;
}

.wrapper3 .image,
.nft-sec2 .wrapper2 .image {
    /* background-color: #00FFD0; */
}

.nft-sec2 .wrapper2 .image img,
.nft-sec3 .wrapper3 .image img,
.nft-sec2 .wrapper2 .image video,
.nft-sec3 .wrapper3 .image video {
    height: 100%;
    width: 100%;
    margin-bottom: 10px;
    object-fit: cover;
    border-radius: 8px;
}

.new-trending a {
    position: relative;
}

.new-trending .nft-meta {
    position: absolute;
    bottom: 10px;
    left: 8px;
    right: 8px;
}

.post-details-content .trending-posts .new-trending .nft-meta {
    width: fit-content;
    height: fit-content;
    top: 10px;
    left: 10px;
}

.nft-meta {
    /* position: absolute; */
    /* bottom: 10px; */
    /* left: 8px; */
    /* right: 8px; */
}

@keyframes append-animate {
    from {
        transform: translateY(200px) scale(0);
        opacity: 0;
    }

    to {
        transform: translateY(0) scale(1);
        opacity: 1;

    }
}

.nft-sec4 .wrapper4 {
    width: 19%;
    margin-bottom: 18px;
    padding: 7px 8px;
    /* border: 1px solid #1994f9; */
    border-radius: 12px;
    height: 390px;
    background-color: #323239;
}

.nft-sec4 h2 {
    /* font-family: 'Montserrat'; */
}

.nft-sec4 h2 span {
    display: block;
}

.nft-sec4 h2 span:first-child {
    margin-right: 8px;
}

.nft-sec4 h2.category-h {
    /* font-size: 26px; */
}

.nft-sec4 h2 span:last-child {
    text-transform: capitalize;
    color: #2CFAFE;
    font-family: "Montserrat";
    font-size: 22px;
}

.wrapper4 .inner-wrapper {
    position: relative;
}

.inner-wrapper {
    /* padding: 8px; */
    /* border: 1px solid #1994f9; */
    /* background-color: #53535f; */
    border-radius: 12px;
    transition: all 0.4s;
    /* position: relative; */
}

/* .inner-wrapper a{position: relative;} */
.nft-sec1 .inner-wrapper {
    /* min-height: 415px; */
    /* overflow: hidden; */
    height: 100%;
    /* margin-bottom: 12px; */
    position: relative;
}

.wrapper1 .inner-wrapper a {
    position: relative;
}

.wrapper4,
.wrapper2,
.wrapper3 {
    /* margin: 0; */
    /* border-radius: 6px !important; */
    /* width: 100%; */
    /* height: auto; */
    /* padding: 0px !important; */
    /* overflow: hidden; */
    /* display: inline-block; */
    /* transition: all ease-in-out 0.3s; */
    /* background-color: #22232A; */
}

.wrapper4 {
    margin: 10px 0.5% 20px 0.5%;
}

.wrapper1 {
    min-height: 415px;
    /* position: relative; */
    padding: 5px;
    border-radius: 12px;
    overflow: hidden;
}

/* .nft-trending{border-bottom: 1px solid #036370; padding-bottom: 10px;} */
.wrapper1 .image video,
.wrapper1 .image img,
.wrapper4 .image video,
.wrapper4 .image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    max-width: 100%;
    max-height: 100%;
    border-radius: 0;
}

.wrapper1:hover,
.wrapper4:hover {
    box-shadow: 0 0px 10px #2cfafe;
}

.wrapper2,
.wrapper3 {
    margin-bottom: 10px;
}

.nft-sec2 .right .inner-wrapper {
    /* min-height: 834px; */
    /* height: 100%; */
    /* margin-bottom: 12px; */

}

.nft-sec3 .wrapper3 {
    /* min-height: 410px; */
    height: 100%;
}

.wrapper2 .post-bottom {
    background: #323239;
    padding: 12px 15px;
    border-radius: 17px;
    color: #fff;
    position: absolute;
    top: calc(100% + -35px);
    left: 50%;
    transform: translateX(-50%);
    width: 93%;
    opacity: 0;
    transition: all 0.4s;
}

/* .wrapper2 .right-post-bottom {
    position: absolute;
    width: 100%;
    left: 0;
    top: calc(100% + -70px);
} */

.nft-sec2 .wrapper2.right:hover .inner-wrapper .post-bottom {
    opacity: 1;
    top: calc(100% + -86px);
}

.nft-sec2 .wrapper2.right:hover .image {
    height: 490px;
}

.nft-sec3 .inner-wrapper {
    /* min-height: 420px; */
    height: 100%;
}

.nft-sec4 .inner-wrapper,
.nft-sec2 .left .inner-wrapper {
    min-height: 100%;
}

.infinite-scroll-component {
    padding: 10px;
    overflow: hidden !important;
}

.nft-sec4 .wrapper4 .nft-meta {
    /* border-top: 1px solid #434343;
    padding-top: 5px; */
    flex-direction: row-reverse;
}

.nft-sec4 .wrapper4 .image img {
    width: 100%;
}

/* .nft-sec4 .wrapper4 .tags span {
    font-size: 14px;
    color: #FFFFFF;
    margin: 2px;
    background: #1994f9;
    padding: 1px 8px;
    border-radius: 7px;
} */

.post-tags {
    font-size: 14px;
    color: #2cfafe;
    margin: 2px;
    /* background: #1994f9; */
    padding: 1px 5px;
    text-transform: uppercase;
    border-radius: 7px;
    font-weight: 500;
}
.trending-posts .post .tags .post-tags:first-child{padding-left: 0;}

.post-tags a {
    word-break: break-all;
    color: #2cfafe;
}

.nft-sec3 .wrapper3 {
    position: relative;
}

.join-discord {
    background-image: url("./assets/images/nft9.png");
    padding: 100px 0;
    margin-top: 30px;
}

.join-discord h1 {
    color: #1454F0;
    font-family: 'Neon';
    margin-bottom: 15px;
    font-weight: 900;
}

.join-discord h5 {
    font-size: 50px;
    font-family: 'Montserrat';
    color: #000000;
    font-weight: 700;
    margin-bottom: 30px;
    line-height: 60px;
    text-align: center;
}

.discord-wrapper button {
    background-color: #2CFAFE;
    font-weight: 700;
}

.discord-wrapper button img {
    width: 50px;
}

.discord-wrapper button:hover {
    background-color: #1554F0 !important;
    color: #fff;
}

.discord-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    max-width: 1400px;
    margin: 0 auto;
}

/* .nft-sec2 .col-sm-6 {
    margin-bottom: 70px;
} */

/* .wrapper1 .tags, .wrapper2 .tags {
    padding: 10px 0;
} */
.wrapper1 .tags a,
.wrapper2 .tags a,
.wrapper3 .tags a,
.wrapper4 .tags a {
    /* margin-left: 10px; */
}

.search-click {
    border: 2px solid #1994F9;
    outline: none;
    background-size: 22px;
    background-position: 13px;
    border-radius: 10px;
    width: 600px;
    max-width: 100%;
    height: 50px;
    /* padding: 25px; */
    padding: 5px 15px;
    transition: all 0.5s;
    margin-right: 12px;
    /* margin-top: -3px;; */
    background: transparent;
}

/* .search-click:focus {
    width: 180px;
    padding-left: 50px;
  } */
.search-click {
    position: relative;
    overflow: hidden;
    height: 50px;
}

.search-click input {
    background: transparent;
    border: 1px solid #1994F9;
    outline: none;
    position: absolute;
    width: 300px;
    height: 50px;
    left: 0%;
    padding: 10px;
}

.wrapper1 .image {
    width: 100%;
    height: 350px;
    border-radius: 12px;
    overflow: hidden;
    margin-bottom: 20px;
    transition: all 0.4s;
    background: #baedff;
}

.wrapper1 .post-bottom {
    background: #323239;
    padding: 12px 15px;
    border-radius: 17px;
    color: #fff;
    position: absolute;
    /* top: calc(100% + -130px); */
    bottom: -100px;
    left: 50%;
    transform: translateX(-50%);
    width: 93%;
    opacity: 0;
    transition: all 0.4s;
}

.wrapper1:hover .inner-wrapper .post-bottom {
    opacity: 1;
    bottom: -77px;
}

.wrapper1:hover .inner-wrapper .image {
    height: 320px;
    transition: all 0.4s;
}

.wrapper4 .image {
    width: 100%;
    height: 250px;
    border-radius: 12px;
    overflow: hidden;
    /* height: 100%; */
    margin-bottom: 15px;
    background: #baedff;
}

.wrapper4 .image img {
    width: 100%;
    height: 100%;
}

.wrapper3 a {
    position: relative;
}

.wrapper3 .image img {
    height: 100%;
}

.wrapper3 .image video {
    height: 100%;
    object-fit: cover;
}

.wrapper3.left .nft-meta {
    /* position: unset; */
    /* padding-top: 10px; */
}

.wrapper2 a {
    position: relative;
}

.nft-sec2 .wrapper2.right .image {
    height: 520px;
    background: #baedff;
    border-radius: 14px;
    transition: all 0.4s;
}

.nft-sec2 .wrapper2.right {
    min-height: 550px;
}

.nft-sec2 .col-sm-6 {
    /* padding-bottom: 10px; */
}

.infinite-scroll-component__outerdiv {
    width: 100%;
}

.single-main-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.page-title h2 {
    /* padding-top: 20px; */
    margin-bottom: 20px;
}

.form-message {
    color: #ff0000;
}

.delete:hover {
    color: red;
}

.edit:hover {
    color: #fec041;
}

.notice .list-none {
    list-style-type: none;
}

.message-container {
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
}

.message-container::-webkit-scrollbar {
    display: none;
}

.center-col {
    flex: 1;
    padding-top: 20px;
    background: #f8ef96 0% 0% no-repeat padding-box;
    overflow-y: auto;
}

.custom-select__control {
    height: 45px;
    background: #44444E !important;
    color: #fff;
    border: 2px solid #333639 !important;
    outline: none;
    box-shadow: none !important;
}

.custom-select__option {
    background: #000 !important;
}

.custom-select__menu {
    /* position: unset !important; */
    z-index: 9999999;
}

.custom-select__menu-list {
    padding: 0 !important;
}

.custom-select__single-value {
    color: #fff !important;
}

.date-picker {
    width: 20%;
    display: inline;
    background: rgba(30, 30, 36, 1) !important;
    color: #fff;
}

.calendarWrap {
    display: inline-block;
    position: relative;
    width: 100%;
}

.calendarElement {
    position: absolute;
    left: 6%;
    transform: translateX(-10%);
    top: 40px;
    border: 1px solid #ccc;
    z-index: 999;
}

.rs-calendar-table {
    color: #000 !important;
}

.rs-picker-daterange-menu {
    left: 52% !important;
}

.text-color {
    color: #000;
}

/* level achievement accordion start */

/* .accordion-header{background-color: transparent;}
.accordion-body{background: transparent;} */
.level-achievement-accordion .accordion-item:last-of-type .accordion-button.collapsed {
    background: #111115;
    color: #fff;
    border: 1px solid rgba(255, 255, 255, 0.1);
}

.level-achievement-accordion .accordion-button:not(.collapsed) {
    color: #0c63e4;
    background-color: #111115;
    border-top: 1px solid rgba(255, 255, 255, 0.1);
    border-left: 1px solid rgba(255, 255, 255, 0.1);
    border-right: 1px solid rgba(255, 255, 255, 0.1);
    border-bottom: none;
}

.level-achievement-accordion {
    margin-bottom: 20px;
}

.level-achievement-accordion .level-achievement-accordion .accordion-item {
    background-color: transparent;
}

.level-achievement-accordion .accordion-item {
    border: none;
}

.level-achievement-accordion .accordion-body {
    background-color: #111115;
    border: 1px solid rgba(255, 255, 255, 0.1);
}

.accordion-button:focus {
    border: none;
    box-shadow: none;
}

.avatar-imagess img {
    width: 70px;
    margin: 0px 20px;
    max-height: 80px;
}

.achievement-content {
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.level-achievement-accordion .accordion-body img {
    width: 100px;
    margin-bottom: 10px;
}

.level-achievement-accordion .accordion-body ul {
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.accordion-button::after {
    background-image: url('./assets/images/chiveron.svg');
}

.achievement-content .others ul li span {
    background: none !important;
    text-align: start !important;
}

.achievement-content .others ul li {
    line-height: 30px;
}

/* level acheivement accoridon end */
/* .area-of-achievements{background-color: #000000;} */
.level-of-achievements {
    margin-bottom: 30px;
}

/* .modal-content ul li div img{width: 225px;} */
.level-of-achievements img {
    width: 80px;
}

.level-of-achievements span {
    padding-left: 20px;
    font-size: 18px;
    display: flex;
    align-items: center;
    font-weight: 500;
}

.others ul li span {
    background: none !important;
    font-size: 14px;
    padding: 0;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.level-images ul {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.level-images ul img {
    width: 120px;
    margin-bottom: 10px;
}

.area-of-achievements {
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.level-of-achievements h3 {
    margin-bottom: 10px;
    font-size: 22px;
}

.level-of-achievements .you-will-get {
    padding-top: 60px;
}

.others p {
    margin-bottom: 40px;
    word-wrap: break-word;
}

.spinner-border {
    width: 2rem;
    height: 2rem;
}

.spinner-border-xl {
    width: 6rem;
    height: 6rem;
}

.nft-sec .section-dark-bg {
    /* padding: 60px 0px; */
}


.video-react-video {
    width: 100% !important;
}

.ant-upload-list-item-info {
    margin-bottom: 20px;
}

.ant-tooltip-inner {
    text-align: center;
}

.video-react-button {
    background: transparent linear-gradient(72deg, #fd24d8 0%, #fed130 100%) 0% 0% no-repeat padding-box;
    margin-bottom: 20px;
    border-radius: 5px;
}

.form-control.editor {
    margin-top: 30px;
}

.create-game-wrapper .form-control.editor {
    margin-top: 0;
    overflow: auto;
    cursor: text;
}

.wrapper-class.rdw-editor-wrapper {
    height: 125px;
    overflow: hidden;
}

.create-game-wrapper .form-control.editor::-webkit-scrollbar,
.form-control.editor .editor-class.rdw-editor-main::-webkit-scrollbar {
    width: 4px;
}

.video-react-video {
    position: relative;
    left: 50%;
    transform: translateX(-50%);
}

/* .video-react-control-bar{display: flex;align-items: center;justify-content: center;} */
.video-react-big-play-button {
    display: none;
}

.video-react-current-time-display {
    margin-left: 30px;
}

/* .section-dark-bg .heading h4 {
    text-align: center;
} */

iframe {
    display: none !important;
}

.main-content-area .react-select-dd select {
    width: 100%;
    height: 45px;
    padding: 2px 8px;
}

#edit-avatr-modal img {
    max-width: -webkit-fill-available;
}

.edit-avatar-style {
    cursor: pointer;
}

.post-content-wrapper {
    display: flex;
    width: 74%;
    float: left;
    padding: 0 12px;
    justify-content: space-between;
}

.post-sidebar-secondray ul li {
    /* margin: 7px 0;
    padding: 10px 0;
    text-transform: uppercase;
    font-family: 'Neon';
    color: #000;
    font-weight: 600; */
}

.post-sidebar-secondray .active {
    color: #2967f1;
    /* background: none !important; */
}

.post-main-content .post-details,
.comments-container {
    background-color: #333134;
    /* padding: 25px 0; */
    /* padding: 25px 20px; */
    margin-bottom: 30px;
    border-radius: 14px;
}

.post-details .title h2 {
    border-bottom: 2px solid #53535f;
    padding-bottom: 7px;
    margin-bottom: 15px;
}

.post-details .preface {
    font-size: 22px !important;
    font-weight: 800;
    border-bottom: 2px solid #53535f;
    display: inline-block;
}

.comment-form-comment textarea {
    resize: none;
    height: 200px;
}

.padding25 {
    /* padding: 0 25px; */
}

.post-content-wrapper .post-sidebar-secondray {
    width: 28%;
    word-break: break-all;
}

.post-content-wrapper .post-main-content {
    width: 70%;
}

.auther-views {
    /* margin-bottom: 15px; */
}

.post-details .auther-views {
    border-bottom: 1px solid #4c4c4c;
    padding: 20px;
}

.post-details .auther-views .share-menu {
    background-color: transparent;
    padding: 5px 10px;
    border-radius: 14px;
    position: relative;
    z-index: 3;
    outline: none;
}

.post-details .auther-views .share-menu .icon {
    color: #2cfafe;
    font-size: 15px;
}

.post-details .auther-views .share-menu .icon svg {
    font-size: 20px;
    color: #fff;
}

.post-details .points-detail-desc {
    font-size: 15px;
    margin-bottom: 15px;
    margin-top: 20px;
    border-bottom: 1px solid #4c4c4c;
    padding-bottom: 15px;
}

.auther-views .auther .image-detail .views svg {
    width: 20px !important;
    height: 20px !important;
    margin-right: 7px;
    cursor: auto;
}




.auther-views .auther .image {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    overflow: hidden;
    margin-right: 18px;
    background-color: #2cfafe;
}

.auther-views .auther .image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.auther-views .auther .name a:hover {
    text-decoration: underline;
}

.auther-views .views {
    font-size: 14px;
}

.auther-views .eye-lottie {
    cursor: default;
}

.auther-views .views button {
    width: auto;
    margin: 0;
}

.auther-views .name {
    font-weight: 700;
    font-size: 18px;
    text-transform: capitalize;
}

.auther-views .name a {
    /* color: #2CFAFE; */
}

.post-details .post-img {
    /* height: 490px; */
    border-radius: 14px;
    overflow: hidden;
    /* margin-bottom: 75px; */
    margin-bottom: 15px;
    background: #baedff;
}

.post-details .post-img img,
.post-details .post-img video {
    object-fit: cover;
    height: 100%;
    width: 100%;
}

.submissions-post {
    padding: 0 !important;
    margin-bottom: 18px;
    overflow: hidden;
    border-radius: 14px;
    position: relative;
}

.submissions-post .boosted-detail .boosted-icon {
    top: 47%;
    transform: translateY(-50%);
}

.submissions-post .auther-views .mini-menu {
    background-color: transparent;
    padding: 4px;
    border: none;
    /* position: absolute;
    right: 15px;
    top: 10px; */
    font-size: 20px;
}

.btn-wrapper-share-hover {
    position: absolute;
    right: 12px;
    top: 10px;
}

.btn-wrapper-share-hover .dots-hover {
    margin: 0;
    display: none;
    background-color: #5c575e;
}

.btn-wrapper-share:hover .dots-hover {
    display: flex;
    justify-content: center;
    align-items: center;
    /* border-bottom: 1px solid #cecece; */
    border-radius: 0px;
    padding: 6px 6px;
    color: #4c4c4c;
    background-color: transparent;
}

.btn-wrapper-share:hover .dots-hover:first-child {
    border-bottom: 1px solid #4444445c;
}

.dots-hover:hover {
    color: #4c4c4c !important;
    background-color: #00c6cb !important;
}

.btn-wrapper-share:hover .btn-wrapper-share-hover {
    /* border: 1px solid #cecece; */
    border-radius: 4px;
    overflow: hidden;
    background: #4cfafe;
    color: #000;
}

.submissions-post .post-img {
    height: 320px;
    border-radius: 14px;
    overflow: hidden;
}

.submissions-post .post-img img,
.submissions-post .post-img video {
    object-fit: cover;
}

.submissions-post .auther-views .image {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    overflow: hidden;
    margin-right: 18px;
}

.submissions-post .auther-views .name a {
    font-size: 18px;
    color: #fff;
}

.submissions-post .auther-views .date {
    font-size: 14px;
    color: #fff;
    opacity: 0.6;
}

.submissions-post .auther-views {
    border-bottom: 1px solid #4c4c4c;
    padding: 15px 15px;
    margin-bottom: 0;
}

.submissions-post .title {
    padding: 12px 20px;
    margin-bottom: 0 !important;
}

.submissions-post .title h3 {
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 5px;
}

.btn-wrapper-share {
    position: relative;
    background: initial;
}

.btn-wrapper-share .edit-boost {
    position: absolute;
    top: 0;
    right: -100%;
    display: flex;
    align-items: center;
    display: none;
    transition: all 0.2s;
    z-index: 2;
}

.active.btn-wrapper-share .edit-boost {
    right: 30px;
    opacity: 1;
    display: flex;
}

.btn-wrapper-share .edit-boost button {
    background-color: transparent;
    color: #2cfafe;
    font-size: 13px;
    border: none;
    font-weight: 700;
}

.btn-wrapper-share .share-icons .likes-box {
    margin-right: 30px;
}

/* .post-details .post-img video{width: 100%; object-fit: cover; height: 100%;} */
.sub-post-img {
    height: 450px;
    border-radius: 12px;
    overflow: hidden;
}

.sub-post-img img,
.post-details .post-item .post-item-img img {
    height: 100%;
    object-fit: cover;
}

.post-details .post-item {
    /* padding: 0px 20px; */
}

.post-details .post-item .post-item-img {
    width: 100%;
    height: 500px;
}

.post-details .post-item-title {
    font-size: 17px;
    font-weight: 600;
}

.post-details .post-item h1 {
    font-size: 25px;
    font-weight: 700;
    line-height: 30px;
}

.post-details .post-item-img img {
    border-radius: 12px;
}

.post-details .questions-container {
    margin-bottom: 15px;
    word-break: break-all;

}

.post-details .questions-container h4 {
    font-size: 16px;
    font-family: 'Montserrat';
    line-height: 25px;
    font-weight: 500;
    text-transform: capitalize;
    margin-bottom: 8px;
}

.post-details .questions-container h4 strong {
    font-size: 19px;
}

.post-details .questions-container h6 {
    font-size: 16px;
    font-family: 'Montserrat';
    line-height: 25px;
    font-weight: 500;
}

.post-details .questions-container .answer-wrapper {
    padding: 10px;
    border: 1px solid #747474;
    border-radius: 6px;
    position: relative;
}

.post-details .questions-container .poll-wrapper-img::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    height: var(--poll-progress);
    width: 100%;
    background-color: #49c793ab;
    border-radius: 13px;
}

.post-details .questions-container .poll-wrapper-text::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    height: 100%;
    width: var(--poll-progress);
    background-color: #49c793ab;
}

/* .post-details .questions-container .answer-img-wrapper::after{content: ""; position: absolute; bottom: 0; left: 0; height: var(--poll-progress); width: 100%; background-color: #1554f07d;} */
.post-details .questions-container .poll-wrapper {
    box-shadow: 0px 0px 6px #f1f1f1;
}

.post-details .questions-container .answer-wrapper:hover {
    box-shadow: 0px 0px 6px #ebebeb;
}

.correct-container .correct-content {
    box-shadow: 0px 0px 6px #ebebeb;
    padding-bottom: 20px;
    margin-bottom: 15px;
}

.correct-container .correct-content h4 {
    background-color: rgba(0, 255, 146, .15);
    padding: 16px 30px;
}

.correct-container .correct-content span,
.correct-container .correct-content p {
    padding: 16px 30px;
}

.wrong-container .wrong-content {
    box-shadow: 0px 0px 6px #ebebeb;
    padding-bottom: 20px;
    margin-bottom: 15px;
}

.wrong-container .wrong-content h4 {
    background-color: rgba(255, 0, 25, .15);
    padding: 14px 20px;
}

.wrong-container .wrong-content span,
.wrong-container .wrong-content p {
    padding: 8px 20px;
}

.score-container {
    margin-bottom: 30px;
}

.score-container .score-content {
    box-shadow: 1px 1px 9px #ebebeb;
    padding: 20px 20px;
}

.score-container .score-content p {
    font-size: 14px;
}

/* .score-container .score-number-container {
    background-image: url("../src//assets/images/score-bg.jpg");
    text-align: center;
    padding: 15% 0;
    border-radius: 10px;
    margin-top: 20px;
} */

.post-sidebar {
    width: 25%;
    float: right;
    padding: 0 12px;
}

.trending-posts {
    margin-bottom: 30px;
    padding: 20px 25px;
    background-color: #333134;
    border-radius: 14px;
}

.post-details-content .trending-posts {
    background-color: #333134;
    border-radius: 14px;
    padding: 20px;
}

.post-details-content .trending-posts .new-trending {
    background-color: transparent;
    padding: 0;
}

.trending-posts .post {
    position: relative;
    margin-bottom: 20px;
}

.trending-posts .post p {
    font-size: 14px;
    line-height: 17px;
    margin-bottom: 10px;
}

.trending-posts .post:not(:last-child) {
    /* margin-bottom: 30px; */
}

.trending-posts .post-desc p {
    margin-bottom: 8px;
    font-size: 18px;
}

.trending-posts .post-creater span:first-child {
    font-size: 13px;
    opacity: 0.6;
}

.trending-posts .post-creater span:last-child {
    font-size: 14px;
}

.trending-posts .post .post-number {
    width: 30px;
    height: 30px;
    background-color: #dc27da;
    position: absolute;
    border-radius: 50%;
    top: 10px;
    left: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.post-details .tags {
    /* padding-top: 10px; */
    margin-bottom: 20px;
}

.submissions-post .tags {
    margin-bottom: 0px;
    padding-top: 0;
}

.post-details .tags a,
.form-modal .react-tagsinput-tag {
    margin-bottom: 0;
    /* border: 3px solid #53535f; */
    padding: 4px 8px;
    font-size: 13px;
    font-weight: 700;
    text-transform: uppercase;
    color: #2CFAFE;
}

.submissions-post .tags a {
    padding: 4px;
    border: none;
    margin: 6px 6px 0 0;
    font-weight: 500;
    color: #2cfafe;
    font-size: 14px;
}

.form-modal .react-tagsinput-tag {
    background: transparent;
    color: #fff;
    border: 3px solid #53535f;
}

.post-details .social-btns {
    font-size: 14px;
    font-weight: 700;
}

.post-details .social-btns button,
.point-btn,
.like-count,
.social-btns .instagram,
.social-btns .facebook,
.social-btns .youtube,
.social-btns .tiktok {
    height: 43px;
    color: #fff;
}

.point-btn .clicked {
    background-color: #2cfafe;
    color: #000;
}

.social-btns .facebook,
.social-btns .twitter,
.social-btns .tiktok,
.social-btns .instagram,
.social-btns .youtube {
    padding: 5px 18px !important;
    margin-right: 10px;
    margin-bottom: 10px;
    font-size: 14px !important;
    font-weight: 600 !important;
    color: #fff
}

a.social-btns .tiktok {
    /* padding: 12px 18px !important; */

}

.social-btns .facebook svg,
.social-btns .twitter svg .tiktok svg {
    width: 28px;
    height: 28px;
}

.social-btns .instagram svg,
.social-btns .facebook svg {
    width: 15px;
    height: 15px;
}

.social-btns .facebook {
    background-color: #3a579a !important;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff
        /* padding: 5px !important; */
}

.social-btns .instagram .social-btns .facebook {
    margin-right: 10px;
}

.social-btns .instagram {
    background-color: #fa31a9 !important;
    display: flex;
    align-items: center;
    justify-content: center;
    /* padding: 5px !important; */
    color: #fff;
}

.social-btns .instagram .social-btns .facebook {
    width: 100%;
}


/* .rsme-embed.rsme-instagram-embed{display: none !important;} */
.social-btns .twitter {
    background-color: #00abf0 !important;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff
}

.social-btns .youtube {
    background-color: #F70000 !important;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
}

.social-btns .tiktok {
    background-color: #f7007c !important;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
}

.social-btns .tiktok:hover {
    background: #c40062 !important;
}

.social-btns .instagram:hover {
    background: #d60081 !important;
}

.social-btns .facebook:hover {
    background-color: #3c49fd !important;
}

.social-btns .twitter:hover {
    background-color: #0179a8 !important;
}

.social-btns .youtube {
    background-color: #F70000 !important;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
}

.social-btns .youtube:hover {
    background: #a70000 !important;
}

.social-btns .facebook:hover {
    background-color: #3c49fd !important;
}

.social-btns .twitter:hover {
    background-color: #0179a8 !important;
}

.social-btns div {
    /* width: 33.3%; */
}

.custom-post-container {
    width: 1300px;
    max-width: 100%;
    margin: 0 auto;
    padding: 0 12px;
}

.point-btn,
.like-count {
    /* padding: 0.3rem; */
    background-color: transparent;
    /* border: 3px solid; */
    border-color: #53535f;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    color: #fff;
    font-weight: 700;
    font-size: 18px;
}

.point-btn {
    border-right-width: 0;
    width: 43px;
    margin-right: 10px;
    margin-bottom: 10px;
}

.point-btn.youtube-loader {
    border: none;
    width: auto;

}

.rate-btn,
.like-count {
    color: #fff;
    min-width: 43px;
    height: 43px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    border: 2px solid #2cfafe;
    border-radius: 5px;
    background-color: transparent;
}
.voting-wrapper{font-size: 20px; border: 2px solid #2cfafe; border-radius: 6px; height: 43px; overflow: hidden; min-width: 80px;}
.voting-wrapper .liked{cursor: pointer; padding: 6px 5px 6px 15px; transition: all 0.3s;} 
.voting-wrapper .liked:hover, .voting-wrapper .clicked{color: #2cfafe;}
.voting-wrapper .counted{padding: 6px 15px 6px 5px;}
.custom-select__control .css-1rhbuit-multiValue {
    background-color: transparent !important;
    border: 3px solid #53535f !important;
}

.custom-select__control .css-1rhbuit-multiValue .css-12jo7m5 {
    color: #ffffffbf !important;
    text-transform: uppercase !important;
    font-weight: 600;
}

.redirectMetamask a {
    color: blue;
}

.comments-container h5 {
    text-transform: capitalize;
    margin-bottom: 20px;
}

.comment-form {
    padding: 20px;
    background-color: #53535f;
    margin-bottom: 15px;
}

.post-details-content .comments-container .comment-form {
    background-color: transparent;
    padding: 0;
}

.comment-form-comment textarea {
    padding: 7px;
    background-color: #333134;
    border-color: #333134;
}

.post-details-content .comments-container .comment-form .comment-form-comment textarea {
    background-color: #48464A;
    border-radius: 12px;
    padding: 10px;
    margin-bottom: 12px;
}

/* .post-details-content .comments-container .comment-form  .info-field{margin-bottom: 12px;} */
.comment-form-comment .submit-post .btn {
    /* color: #fff;
    background-color: #353432;
    padding: 6px;
    font-weight: 600;
    text-transform: uppercase;
    border-radius: 3px;
    border: none;
    width: 120px; */
    /* padding: 6px 20px;
    font-size: 15px; */
}

.comment-form-comment .submit-post .btn:hover {
    /* box-shadow: -3px 3px 8px 0 rgb(21 21 24 / 34%);
    color: #fff; */
}

.comment-list .comment.comment-author {
    padding: 10px 0;
    border-bottom: 1px solid #535353;
}

.comment-list .comment.comment-author:last-child {
    border-bottom: none;
}

.comment-list {
    position: relative;
}

.spinner-border.comment-loader {
    position: absolute;
    top: 48%;
    right: 48%;
}

.comment-list {
    position: relative;
}

.spinner-border.comment-loader {
    position: absolute;
    top: 48%;
    right: 48%;
}

.comment-body .comment-user-img {
    border-radius: 50%;
    width: 40px;
    height: 40px;
    background-color: #2cfafe;
    overflow: hidden;
}
.comment-body .comment-user-img img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.comment-body .comment-details {
    margin-left: 25px;
    width: 100%;
    flex: 1;
}

.comment-body .date-edit {
    font-size: 12px;
    color: #a3a3a3 !important;
}

.comment-body .date-edit a:hover {
    text-decoration: underline;
}

.comment-body .date-edit a:hover,
.comment-body .date-edit .edit-comment-btn:hover {
    text-decoration: underline;
}

.comment-body .date-edit .edit-comment-btn {
    font-size: 12px;
    color: #a3a3a3 !important;
    background-color: #53535f;
}


.comment-body .comment-reply-btn {
    background-color: #53535f;
    color: #fff;
    font-size: 10px;
    padding: 1px 10px;
}

.comment-body .comment-reply-btn:hover {
    text-decoration: underline;
}

.comment-body .user-comment-name:hover {
    text-decoration: underline;
}

.comment-list .children.comment.comment-author {
    border-bottom: 0 !important;
    padding-left: 60px;
}

.comment-list .children.comment.comment-author .comment-body {
    border-top: 1px solid #f4f3f3;
    padding-top: 10px;
}

.children .comment-body .comment-user-img img {
    width: 35px;
}

.comment-list .replies {
    border-bottom: 0 !important;
    padding-left: 60px;
}

.comment-list .replies .comment-body {
    border-top: 1px solid #535353;
    padding-top: 10px;
}

.comment-list:last-child .comment {
    border-bottom: none;
}

.replies .comment-body .comment-user-img {
    width: 35px;
    height: 35px;
    background-color: #2cfafe;
    overflow: hidden;
    border-radius: 50%;
}
.replies .comment-body .comment-user-img img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.load-more-btn {
    font-size: 13px;
    font-weight: 600;
    color: #a3a3a3 !important;
    background-color: #53535f;
    padding: 8px 14px;
}

/* 404 page design */
.notfound-container {
    height: 75vh;
    margin-top: -35px;
}

.main-title {
    color: white;
    font-weight: 600;
    font-size: 80px;
    line-height: 70px;
    text-align: center;
}

.subtitle {
    color: white;
    text-align: center;
}

.logo {
    display: flex;
    align-items: center;
}

.astronaut {
    position: absolute;
    top: 30%;
    right: 10%;
    height: 30vmin;
    animation: floating 3s infinite ease-in-out;
}

@keyframes floating {
    from {
        transform: translateY(0px);
    }

    65% {
        transform: translateY(15px);
    }

    to {
        transform: translateY(-0px);
    }
}

.button__badge {
    background-color: #fa3e3e;
    border-radius: 2px;
    color: white;

    padding: 2px 5px;
    font-size: 10px;

    position: absolute;
    /* Position the badge within the relatively positioned button */
    top: -5px;
    right: -5px;
}

.messageButton {
    position: relative;
}

.button__badge_empty {
    display: none;
}

.goback-btn {
    width: fit-content;
    margin: 30px auto 0px;
}

.goback-btn:hover {
    transform: scale(1.1);
    transition: all ease-in-out 0.75s;
}

.goback-btn:focus {
    box-shadow: unset !important;
}






.sd-root-modern {
    background: transparent !important;
}

.sd-body.sd-body--static {
    max-width: 100% !important;
}

.sd-body .sd-body__page {
    min-width: 100% !important;
}

.sd-input {
    background-color: transparent !important;
    color: #fff !important;
    outline: none !important;
    box-shadow: none !important;
    height: 50px !important;
    /* margin-top: 10px !important; */
    background: #44444E !important;
    border: 2px solid #333639 !important;
    border-radius: 10px !important;
}

.sd-element--with-frame:not(.sd-element--collapsed) {
    background: transparent !important;
}

.sd-comment {
    overflow: auto !important;
    resize: none !important;
}

textarea.sd-comment {
    height: 150px !important;
    border-radius: 6px;
}

.sd-element--complex.sd-element--with-frame>.sd-element__header--location-top:after {
    display: none !important;
}

.sd-element__title .sv-string-viewer {
    margin-bottom: 8px;
    display: inline-block;
}

.sd-element--complex:not(.sd-element--collapsed)>.sd-element__header--location-top {
    padding-top: 0 !important;
}

.post-submittioins .sd-element--complex:not(.sd-element--collapsed)>.sd-element__header--location-top {
    display: none !important;
}

.post-submittioins .sd-paneldynamic__progress-text,
.post-submittioins .sd-paneldynamic__remove-btn,
.post-submittioins .sd-paneldynamic__add-btn {
    display: none !important;
}

.post-submittioins .sd-element--complex:not(.sd-element--collapsed)>.sd-element__header--location-top {
    display: none !important;
}

.post-submittioins .sd-paneldynamic__progress-text,
.post-submittioins .sd-paneldynamic__remove-btn,
.post-submittioins .sd-paneldynamic__add-btn {
    display: none !important;
}

/* .sd-element__header--location-top{ padding-left: 20px !important;} */
/* #sq_149_ariaTitle, #sq_156_ariaTitle, #sq_233_ariaTitle, #sq_268_ariaTitle, #sq_1710_ariaTitle, #sq_436_ariaTitle, #sq_618_ariaTitle, #sq_632_ariaTitle, #sq_744_ariaTitle, #sq_793_ariaTitle, #sq_800_ariaTitle, #sq_877_ariaTitle, #sq_912_ariaTitle, #sq_954_ariaTitle, #sq_996_ariaTitle, #sq_1010_ariaTitle{padding-left: 22px !important;} */
.sd-element--with-frame:not(.sd-element--collapsed).sd-question--paneldynamic,
.sd-page.sd-body__page.sd-page__empty-header,
.sd-body--responsive .sd-page.sd-page__empty-header {
    padding: 0 !important;
}

.sd-paneldynamic__separator {
    display: none !important;
}

.sd-element--complex:not(.sd-element--collapsed)>.sd-element__header--location-top h5 .sd-element__num {
    font-size: 20px !important;
    margin-left: 1px !important;
}

.sd-body.sd-body--static .sd-body__timer,
.sd-body.sd-body--static .sd-body__navigation,
.sd-body.sd-body--static .sd-body__page {
    padding: 0 !important;
}

.svc-logic-question-value,
.sd-element:not(.sd-element--collapsed) {
    padding-top: 15px !important;
}

.sd-element.sd-element--complex {
    padding-top: 0 !important;
}

.sd-panel__content {
    margin-top: 0 !important;
}

.sd-question__header--location-top,
.sd-paneldynamic__panel-wrapper,
.sd-paneldynamic__buttons-container {
    padding-bottom: 0 !important;
}

.create-game-wrapper label.sd-context-btn.sd-file__choose-btn--icon {
    /*padding: 8px 24px !important;*/
    display: flex;
    justify-content: center;
    align-items: center;
    height: 48px;
}

.create-game-wrapper label.sd-context-btn.sd-file__choose-btn--icon::after {
    content: 'Add';
    position: absolute;
    bottom: -30px;
    right: 4px;
    color: #fff;
    background-color: #2b2a2a;
    padding: 1px 6px;
    display: none;
    transition: all 0.3s ease-in-out;
    font-size: 13px;
    border: 1px solid #fff;
}

.create-game-wrapper label.sd-context-btn.sd-file__choose-btn--icon:hover::after {
    display: block;
}

.sd-table.sd-matrixdynamic {
    overflow: hidden !important;
}

.dynamic-form .sd-question--table>.sd-question__content::before,
.dynamic-form .sd-question--table>.sd-question__content::after {
    display: none;
}

.dynamic-form .sd-question--table>.sd-question__content {
    padding-left: 12px;
    padding-right: 12px;
}

/* .create-game-wrapper .sd-context-btn--negative{top:40px} */
.create-game-wrapper .sd-context-btn--negative::after {
    content: 'Remove';
    position: absolute;
    bottom: -30px;
    right: 2px;
    color: #fff;
    background-color: #2b2a2a;
    padding: 1px 6px;
    display: none;
    transition: all 0.3s ease-in-out;
    font-size: 13px;
    border: 1px solid #fff;
}

.create-game-wrapper .sd-context-btn--negative:hover::after {
    display: block;
}

.sd-file__decorator {
    padding: 10px !important;
    border-radius: 6px;
}

#sq_177_ariaTitle {
    padding-top: 10px !important;
    margin-bottom: 7px !important;
}

.sd-question__content {
    padding-top: 0;
}

.sd-element--nested.sd-element--complex>.sd-element__header--location-top:after {
    bottom: -8px !important;
    background: #ffffff26 !important;
}

.sd-matrixdynamic__footer {
    padding: 8px 0 !important;
}

.sd-element .sd-panel__header.sd-element__header.sd-element__header--location-top {
    padding-top: 8px !important;
}

/* .sd-element--nested.sd-panel > .sd-element__header--location-top, .sd-element--nested.sd-question--paneldynamic > .sd-element__header--location-top{background: #1B1A21 !important;} */
.sd-table__cell--row-text:last-of-type {
    background-color: transparent !important;
}

.sd-table__cell--row-text {
    background: transparent !important;
}

.sd-question--table>.sd-question__content:before,
.sd-question--table>.sd-question__content:after {
    background: transparent !important;
}

/* .sd-question__header.sd-element__header.sd-question__header--location-top.sd-element__header--location-top{padding-left: 25px !important;} */

/* #sv-pd-add-btn, */
#sv-pd-next-btn {
    margin-left: 8px !important;
}

.sd-action.sd-paneldynamic__btn.sd-action--negative.sd-paneldynamic__remove-btn {
    margin-right: 5px !important;
}

.sd-action.sd-paneldynamic__btn.sd-action--negative.sd-paneldynamic__remove-btn,
.sd-action.sd-matrixdynamic__remove-btn {

    color: #ff6b6b !important;
    background: transparent;
    border-color: transparent;
}

.sd-table__cell--actions.sd-table__cell--vertical .sd-action-bar {
    justify-content: flex-start !important;
}

.sd-action.sd-matrixdynamic__remove-btn {
    padding: 10px;
    opacity: 1 !important;
}

.sd-action.sd-paneldynamic__btn.sd-action--negative.sd-paneldynamic__remove-btn:hover,
.sd-action.sd-matrixdynamic__remove-btn:hover {
    color: #fff !important;
    background-color: #ff6b6b !important;
}

.sd-action.sd-matrixdynamic__remove-btn .sd-action__icon {
    width: 22px !important;
    height: 22px !important;
}

.sd-action.sd-matrixdynamic__remove-btn .sd-action__icon use {
    fill: #ff6b6b;
}

.sd-action.sd-matrixdynamic__remove-btn:hover .sd-action__icon use {
    fill: #fff !important;
}

.sd-table__cell.sd-table__cell.sd-table__cell--row-text {
    padding: 7px 8px !important;
    width: 150px;
}

.sd-item__decorator {
    border: 1px solid #7a7a7a !important;
}

.sd-element--nested.sd-panel {
    overflow: hidden !important;
}

.sd-element--nested.sd-panel,
.sd-element--nested.sd-question--paneldynamic {
    border: 1px solid #ffffff26 !important;
    border-radius: 10px !important;
}

/* .dynamic-form .sd-element--nested.sd-panel{padding: 18px;} */
.sd-table {
    background: transparent !important;
}

.sd-matrixdynamic__footer .sd-action {
    /* margin-left: 30px !important; */
}

.css-1s2u09g-control {
    /* min-height: 50px !important; */
}

.submission__value-container {
    padding: 0 8px !important;
}

.submission__input-container {
    height: 43px;
}

.post-sidebar .post-img {
    margin-bottom: 12px;
    width: 100%;
    /*height: 400px;*/
    height: 250px;
    border-radius: 10px;
    overflow: hidden;
    background: #baedff;
    /* background-color: #22232a; */
}

.post-sidebar .post-img img,
.post-sidebar .post-img video {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.custom-modal .modal-dialog {
    max-width: 600px;
}

.settings-wrap {
    padding-top: 60px;
}

.new-trending {
    background: #53535f;
    padding: 10px;
    margin-bottom: 20px;
}

.new-trending .boosted-detail .boosted-icon {
    top: 50px;
}

.setting-wrap {
    padding: 50px 50px 20px 50px;
}

.profile-component .setting-loader {
    background: none !important;
}

.profile-component {
    position: relative !important;
}

.settings-sidenav {
    margin-top: 60px;
    background: #33363940;
    border: 2px solid #333639;
    border-radius: 20px;
    height: 655px;
    position: relative;
}

.settings-sidenav ul li {
    border-bottom: 1px solid #70707066;
    padding: 10px;
}

.settings-sidenav ul li:last-child {
    border-bottom: none;
    margin-top: 5px;
    margin-bottom: 5px;
    position: absolute;
    right: 0;
    bottom: 0;
}

.settings-sidenav ul li:last-child a {
    width: fit-content;
    margin-right: auto;
    padding: 8px 22px;
}

/* .settings-sidenav ul li:last-child a:hover{color: #000;} */
.settings-sidenav ul li a {
    font-family: 'Neon';
    font-size: 19px;
    font-weight: 700;
    /* opacity: 0.4; */
    color: #fff;
    transition: all 0.3s;
    padding: 10px;
    display: flex;
}

.settings-sidenav ul li a:hover {
    color: #2CFAFE
}

.settings-sidenav ul li a.active {
    background: none;
    color: #2CFAFE;
}

.settings-sidenav ul li a .icons {
    width: 30px;
    height: 30px;
}

.leaderboard-table table thead tr th {
    padding: 16px 12px;
}

.leaderboard-table table thead tr th:first-child {
    width: 15%;
}

.custom-group .checkbox {
    appearance: none;
    background-color: #000;
    margin: 0;
    font: inherit;
    color: currentColor;
    width: 20px;
    height: 20px;
    border: 3px solid #53535f;
    border-radius: 0.15em;
    /* transform: translateY(-0.075em); */
}

.custom-group {
    display: block;
    position: relative;
    padding-left: 33px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

/* Hide the browser's default checkbox */
.custom-group input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
    left: 0;
    top: 0;
    z-index: 1;
}

/* Create a custom checkbox */
.checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 23px;
    width: 23px;
    background-color: #44444E;
    border: 1px solid #44444E;
    border-radius: 4px;
}

/* On mouse-over, add a grey background color */
.custom-group:hover input~.checkmark {
    /* background-color: #1c1821; */
}

/* When the checkbox is checked, add a blue background */
.custom-group input:checked~.checkmark {
    background-color: #1554F0;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the checkmark when checked */
.custom-group input:checked~.checkmark:after {
    display: block;
}

/* Style the checkmark/indicator */
.custom-group .checkmark:after {
    left: 8px;
    top: 3px;
    width: 6px;
    height: 11px;
    border: solid white;
    border-width: 0px 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

.custom-file-picker {
    position: relative;
    overflow: hidden;
    display: inline-block;
}

.custom-file-picker button {
    padding: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
}

.custom-file-picker button:active {
    color: transparent !important;
    background-color: transparent !important;
    background: transparent;
}

.custom-file-picker button .video-wrap {
    width: 100%;
    height: 100%;
}

.custom-file-picker input[type=file] {
    font-size: 30px;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    cursor: pointer;
}

.join-date {
    display: flex;
    justify-content: space-between;
}

.join-date strong {
    color: #fff;
}

/* .user-profile-info{display: flex; flex-wrap: wrap; justify-content: center;} */
.user-profile-info .info-wrap {
    padding: 8px 10px;
    border: 1px solid #2CFAFE;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 8px;
    width: 100%;
}

.user-profile-info .info-wrap:first-child {
    margin-left: 0;
}

.user-profile-info .info-wrap:last-child {
    margin-right: 0;
}

.user-profile-info .info-wrap span {
    font-size: 14px;
}

.user-profile-info .info-wrap span {
    font-size: 15px;
    text-transform: capitalize;
}

.new-trending .post-img {
    height: 300px;
}

.post-details-content .trending-posts .new-trending .post-img {
    height: 320px;
    margin-bottom: 12px;
    border-radius: 14px;
    overflow: hidden;
    background: #baedff;
}

.custom-group .checkbox {
    width: 23px;
    height: 23px;
}

.new-trending .post-img img,
.new-trending .post-img video {
    height: 100%;
    object-fit: cover;
    width: 100%;
}

.activity-main .activity-wrap {
    display: flex;
    align-items: center;
    padding: 20px 0;
    border-bottom: 1px solid #53535f;
}

.activity-main .activity-wrap .activity-avatar a {
    display: block;
    margin-right: 20px;
}

.activity-main .activity-wrap .activity-avatar a img {
    border-radius: 5px;
}

.activity-main .activity-wrap .activity-content .activity-header strong :nth-child(2) {
    opacity: 0.4;
}

.activity-main .activity-wrap .activity-content .activity-bubble a {
    background-color: #53535f;
    padding: 8px 10px;
    border-radius: 5px;
}

.submission__control {
    background-color: #fff !important;
    color: #000;
    width: 500px;
    max-width: 100%;
    /* border-color: #2CFAFE !important; */
}
.submission__menu{z-index: 9 !important;}
.submission__menu,
.submission__option--is-focused {
    background-color: #000 !important;
}

.submission__placeholder {
    color: #fff !important;
}

.main-logo:focus-visible {
    outline: none;
}

.reaction-sections {
    /* padding-top: 30px; */
}

.reaction-item {
    background-color: #53535f;
    /* height: 120px; */
    position: relative;
    width: fit-content;
    padding: 8px 10px;
    border-radius: 30px;
    margin: 8px 10px 0 0;
    cursor: pointer;
}

.reaction-item.active {
    background-color: #1554F0;
}

.reaction-item::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 0;
    /* background-color: #ffe400; */
    bottom: 0;
    left: 0;
    right: 0;
    transition: all 0.4s;
}

.reaction-item.react-added::after {
    /* height: 30px; */
    /* height: var(--reaction-fill); */
    height: 100%;
    transition: all 0.4s;
}

.reaction-item.selected {
    background-color: #ffe400;
}

.reaction-item .bb-badge .circle {
    width: 40px;
    height: 40px;
    overflow: hidden;
    border-radius: 50%;
    margin-right: 15px;
}

.reaction-item .bb-badge .circle img {
    width: 100%;
    height: 100%;
    max-width: 100%;
    object-fit: cover;
    background-color: #ffe400;
}

.reaction-stat-count {
    width: 100%;
    height: calc(100% - 35px);
    /* display: flex;
    align-items: center;
    justify-content: center;
    
    font-weight: 600; */
    position: relative;
    font-size: 14px;
    z-index: 1;
}

.reaction-wrap {
    width: fit-content;
}

.reaction-stat-count span {
    /* background: #fff; */
    /* width: 30px; */
    /* height: 30px; */
    /* display: flex;
    justify-content: center;
    align-items: center; */
    /* border-radius: 50%; */
    color: #fff;
}

.reaction-vote-btn {
    background-color: #53535f;
    text-align: center;
    transition: all 0.2s ease-in-out;
    text-transform: uppercase;
    font-size: 13px;
    font-weight: 700;
    /* padding: 10px 0; */
}

.reaction-vote-btn:hover {
    background-color: #ffe400;
    color: #000;
    cursor: pointer;
}

.reaction-btn-disabled {
    cursor: not-allowed;
    pointer-events: none;
}

.reaction-vote-btn-disabled {
    /* background-color: #53535f; */
    opacity: 0.5;
    text-align: center;
    transition: all 0.2s ease-in-out;
    text-transform: uppercase;
    font-size: 13px;
    font-weight: 700;
    /* padding: 10px 0; */
    cursor: not-allowed;
}

.spinner-border.reaction-loader {
    position: absolute;
    left: 43%;
}

.reaction-sections .col-sm-2,
.reaction-sections .col-sm-4 {
    padding: 0 6px;
}

/* .profile-notifications{position: relative;} */
.profile-notifications .nav-link {
    padding: 0 !important;
    opacity: 1;
}

.profile-notifications button:hover,
.profile-notifications button.is-active {
    background-color: #1554F0;
    color: #fff;
    border-color: #1554F0;
}

.profile-notifications button.p-0:hover {
    background-color: transparent;
}

.profile-notifications .notifi-digit {
    color: #fff;
    background-color: red;
    width: 19px;
    height: 19px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 9px;
    position: absolute;
    top: -6px;
    right: -6px;
    font-weight: 700;
}

.notification-detail {
    background-color: #323639;
    width: 400px;
    border-radius: 8px;
    position: absolute;
    right: 0;
    top: 18px;
    z-index: 999999;
    box-shadow: 0 0 10px #2cfafe;
}

.notification-detail::before {
    content: "";
    position: absolute;
    top: -15px;
    width: 0;
    right: 0;
    transform: translateX(-50%);
    height: 0;
    border-style: solid;
    border-width: 0 13px 15px 13px;
    /* border-color: transparent transparent #2CFAFE transparent;*/
    border-color: transparent transparent #323639 transparent;

    z-index: 2;
}

.notification-detail ul li {
    /* border-bottom: 1px solid #cecece; */
    padding: 18px 30px;
    padding-bottom: 0;
    position: relative;
}

.notification-detail ul li.unread-notification a::before {
    content: "";
    position: absolute;
    width: 8px;
    height: 8px;
    background-color: red;
    border-radius: 50%;
    top: 50%;
    transform: translateY(-50%);
    left: 10px;
}

.notification-detail ul li a {
    color: #fff;
    font-size: 14px;
    background-color: transparent;
    /* border-radius: 4px; */
    display: block;
    /* padding: 10px 12px; */
    border-bottom: 1px solid #585C60;
    padding-bottom: 16px;
}

.notification-detail ul li:last-child a {
    border-bottom: none;
}

.notification-detail ul li:last-child {
    border-bottom: 1px solid #585C60;
}

.notification-detail ul li:first-child {
    border-top: 1px solid #585C60;
}

.unread-notification ul li a {
    background-color: #414448;
}

.notification-detail ul li a p {
    /* padding-bottom: 10px; */
    /* border-bottom: 1px solid #ffffff1c; */
}

.notification-detail ul li a:hover {
    /* background-color: rgba(225, 255, 225, 0.3); */
}


.main-leaderboard {
    padding: 0 15px;
}

.main-leaderboard .table-wrapper,
.activity-main .table-wrapper,
.notification-section .table-wrapper {
    /* max-width: 1560px; */
    padding: 30px 40px;
    background: #323239;
    border-radius: 20px;
    /* margin: 50px auto; */
    /* background: url("./assets/images/table-middle.png"); */
    /* background-size: 100%; */
    /* padding: 100px 50px 60px; */
    /* position: relative; */
    /* background-repeat: repeat; */
    /* background-position: top; */
    z-index: 9;
}

.main-leaderboard .table-wrapper::before {
    /* content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: -1;
    background: url("./assets/images/table-top.png");
    height: 75px;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: top;
    background-color: #1a1a1a; */
}

.main-leaderboard .table-wrapper::after {
    /* content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: -1;
    background: url("./assets/images/table-bottom.png");
    height: 40px;
    background-repeat: no-repeat;
    background-position: bottom;
    background-size: 100% 100%;
    background-color: #1a1a1a; */
}

.main-leaderboard .table-wrapper .table,
.activity-main .table,
.nofifications-table .table,
.notification-section .table-wrapper .table {
    color: #fff;
    border-spacing: 0 18px;
    border-collapse: separate;
    padding: 0 3px;
}

.nofifications-table .table,
.notification-section .table-wrapper .table {
    min-width: 500px;
    overflow: auto;
}

.main-leaderboard .table-wrapper .table {
    padding: 0 7px;
}

.main-leaderboard .table-wrapper .table tbody tr,
.activity-main .table thead tr,
.activity-main .table tbody tr,
.notification-section .table-wrapper .table thead tr,
.nofifications-table .table tbody tr {
    border-radius: 11px;
}

.main-leaderboard .table-wrapper .table tbody tr td:first-child,
.main-leaderboard .table-wrapper .table tbody tr th:first-child,
.activity-main .table tbody tr td:first-child,
.activity-main .table thead tr th:first-child,
.notification-section .table-wrapper .table tbody tr td:first-child,
.notification-section .table-wrapper .table thead tr th:first-child {
    border-radius: 11px 0 0 11px;
}

.main-leaderboard .table-wrapper .table tbody tr td:last-child,
.main-leaderboard .table-wrapper .table tbody tr th:last-child,
.activity-main .table thead tr th:last-child,
.activity-main .table tbody tr td:last-child,
.notification-section .table-wrapper .table tbody tr td:last-child,
.notification-section .table-wrapper .table thead tr th:last-child {
    border-radius: 0 11px 11px 0;
    /* background-color: #525252; */
}

.main-leaderboard .table-wrapper .loader {
    min-height: 60vh;
    align-items: center;
}

.main-leaderboard .table-wrapper .table td,
.main-leaderboard .table-wrapper .table th,
.activity-main .table td,
.activity-main .table th,
.notification-section .table-wrapper .table td,
.notification-section .table-wrapper .table th {
    border: none;
    box-shadow: none;
    text-align: left;
    font-weight: 500;
    padding: 14px 10px;
}

.main-leaderboard .table-wrapper .table .user-details,
.main-leaderboard .table-wrapper .table .badges ul {
    justify-content: flex-start;
    /* min-width: 180px;     */
    align-items: center;
}

.main-leaderboard .table-wrapper h2 {
    position: absolute;
    left: 0;
    right: 0;
    top: 15px;
    text-align: center;
    color: #fff;
}

.main-leaderboard .table-responsive::-webkit-scrollbar {
    width: 6px;
}

.main-leaderboard .table-wrapper .table tbody {
    border: none;
}

.main-leaderboard .table-wrapper .table td {
    background: transparent;
    vertical-align: middle;
    font-weight: 500;
    /* padding: 10px 17px; */
}

.main-leaderboard .table-wrapper .table tbody tr {
    /* clip-path: polygon(2% 0%, 98% 0%, 100% 100%, 0% 100%); */
    background: linear-gradient(to right, #1f1f27, #1f1f27);
}

.main-leaderboard .table-wrapper .table tr.first {
    /* clip-path: polygon(2% 0%, 98% 0%, 100% 100%, 0% 100%); */
    background: linear-gradient(to right, #fd3448, #fd25e6);
}

.main-leaderboard .table-wrapper .table tr.second {
    /* clip-path: polygon(2% 0%, 98% 0%, 100% 100%, 0% 100%); */
    background: linear-gradient(to right, #fd2cd0, #feca36);
}

.main-leaderboard .table-wrapper .table tr.third {
    /* clip-path: polygon(2% 0%, 98% 0%, 100% 100%, 0% 100%); */
    background: linear-gradient(to right, #7f3afb, #2ce3fd);
}

.main-leaderboard .table-wrapper .table tr.user-record {
    outline: 3px solid #336bff;
}

.main-leaderboard .table-wrapper .table tr.forth,
.activity-main .table thead,
.nofifications-table .table thead {
    /* clip-path:  polygon(0% 0%, 100% 0%, 99.5% 100%, 0.5% 100%); */
    background: linear-gradient(to right, #01b3caf5, #024750fb);
    border-radius: 10px;
}

.main-leaderboard .table-wrapper .table tr.forth {
    vertical-align: middle;
}

.main-leaderboard .table-wrapper .table thead {
    position: sticky;
    z-index: 1;
    margin: 0;
    left: 0;
    top: 0;
}

.main-leaderboard .table-wrapper .table tr.forth th:first-child {
    border-radius: 10px 0 0 10px;
}

.main-leaderboard .table-wrapper .table tr.forth th:last-child {
    border-radius: 0 10px 10px 0;
}

.main-leaderboard .table-responsive {
    overflow: auto;
    height: 540px;
}

.activity-main .table-responsive {
    height: 590px;
}

.main-leaderboard .table-responsive tbody {
    width: 100%;
    position: relative;
}

.main-leaderboard .table-responsive .sc-gsnTZi {
    position: absolute;
    left: 0;
    right: 0;
}

.user-details {
    display: flex;
}

.user-details .image {
    width: 46px;
    height: 46px;
    border-radius: 5px;
    overflow: hidden;
    background-color: #2cfafe;
}

.user-details .image img {
    height: 100%;
    object-fit: cover;
    width: 100%;
}

img[alt] {
    font-size: 12px;
}

.user-details .user-info {
    position: relative;
    text-align: left;
    margin-left: 10px;
    margin-bottom: 0;
    flex: 1;
}

.user-details .user-info h5 {
    font-family: 'Montserrat';
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
}

.user-details .user-info span {
    font-size: 12px;
    opacity: 0.8;
}

.badges ul {
    display: flex;
}

.badges ul li {
    background: #ffffff14;
    width: 35px;
    height: 35px;
    margin-right: 5px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.badges ul li img {
    width: 22px;
}

.notification-section li a {
    display: inline-block;
}

/* .nofifications-table tbody tr:nth-child(2n+1) {
    background-color: #41414b;
} */
.activity-table .table .thead-dark,
.nofifications-table .table .thead-dark {
    background-color: #000;
    border-bottom: 1px solid #fff;
}

.activity-table .main-btn-style {
    padding: 6px 12px;
    font-size: 14px;
    border-radius: 5px;
    background-color: #2cfafe;
    color: #000;

}

.activity-table .main-btn-style:hover {
    background-color: transparent;
    text-decoration: none;
    color: #fff;
}

.nofifications-table a {
    /* color: #a4a4a4; */
}

.nofifications-table a:hover {
    text-decoration: underline;
}

.nofifications-table .actions {
    color: #a4a4a4;
}

.nofifications-table .actions span:hover {
    text-decoration: underline;
    cursor: pointer;
}

.nofifications-table .table>:not(caption)>*>* {
    border-bottom-width: 0;
}

.see-all-activities {
    color: #2CFAFE;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 18px 10px;
}

.see-all-activities:hover {
    opacity: 0.9;
    color: #2CFAFE;
}

.notification-detail h5 {
    /* background: #2CFAFE;
    color: #000;
    padding: 6px 0; */
    color: #2CFAFE;
    padding: 18px 10px;

}

.notification-detail ul {
    /* padding: 12px 0; */
    /* padding-bottom: 13px; */
}



.post-sidebar-secondray .categories-box.fixed,
.post-sidebar .trending-posts.fixed {
    position: fixed;
    top: 20px;
}

.post-sidebar-secondray .categories-box::after {
    display: none;
}

/* .post-sidebar .trending-posts.fixed{padding-right: 75px;} */

.text-capitalize {
    text-transform: capitalize;
}

.post-sidebar #trend {
    padding-top: 20px;
    /* padding-right: 50px; */
}

.wrapper1 .views-votes {
    background-color: #00000067;
    border-radius: 20px;
    min-width: 100px;
    padding: 3px 8px;
    justify-content: space-between;
}

.wrapper4 .views-votes {
    background-color: #00000067;
    border-radius: 20px;
    min-width: 90px;
    padding: 3px 8px;
    justify-content: space-between;
}

.static-content {
    background-color: #333639;
    padding: 40px 70px;
}

.static-content h2 {
    font-family: "Neon";
    margin-bottom: 40px;
}

.static-content ul li {
    margin-bottom: 35px;
}

.mini-ul {
    list-style-type: disc;
    margin-bottom: 20px;
    padding-left: 17px;
}

.mini-ul li {
    margin-bottom: 10px !important;
}

.static-content ul li h4 {
    margin-bottom: 15px;
    border-bottom: 1px solid #fff;
    display: inline-block;
}

.submissions-content .post-details .title {
    margin-bottom: 15px;
}

.submissions-content .post-details .title span {
    font-family: inherit;
}

.main-wall {
    padding: 0px 12px 50px !important;
}

.h-border {
    /* border-bottom: 1px solid;
    display: inline-block; */
}


.wall-cards ul li .single-card {
    min-height: 140px;
    width: 100%;
    border-radius: 8px;
    color: #000;
    padding: 15px 20px;
    transition: all 0.3s;
    position: relative;
    cursor: pointer;
    background-color: #fff;
}

.wall-cards ul li .card-1 {
    /* background-color: #84E9F4; */
}

.wall-cards ul li .single-card.active {
    box-shadow: 0 0 10px #fff;
    transform: scale(1.01);
}

.wall-cards ul li .card-2 {
    /* background-color: #FDBC64; */
}

.wall-cards ul li .card-3 {
    /* background-color: #625BAC; */
}

.wall-cards ul li .card-4 {
    /* background-color: #F9896B; */
}

/* .wall-cards ul li .single-card:hover{background-color: #2CFAFE;color: #000;} */
/* .wall-cards ul li .single-card:hover span{color: #000;} */
.wall-cards ul li {
    width: 24%;
    margin: 0 8px 13px;
}

/* .wall-cards ul li:first-child{margin-left: 0;}
.wall-cards ul li:last-child{margin-right: 0;} */

.wall-cards ul li .single-card p {
    font-size: 30px;
    font-weight: 600;
    color: #000;
    font-family: "Neon";
}

.wall-cards ul li .card-1 .card-icon span,
.wall-cards ul li .card-1 strong {
    color: #1554f0;
}

.wall-cards ul li .card-2 .card-icon span,
.wall-cards ul li .card-2 strong {
    color: #1554f0;
}

.wall-cards ul li .card-3 .card-icon span,
.wall-cards ul li .card-3 strong {
    color: #1554f0;
}

.wall-cards ul li .card-4 .card-icon span,
.wall-cards ul li .card-4 strong {
    color: #1554f0;
}

.wall-cards ul li .single-card strong {
    font-size: 18px;
    margin-bottom: 6px;
    font-weight: 500;
    color: #000;
    opacity: 0.7;
    /* font-family: "Neon"; */
}

.wall-cards ul li .single-card .card-icon span img {
    width: 45px;
}

.wall-cards ul li .single-card .card-icon {
    /* width: 60px;
    height: 60px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff; */
}

.wall-cards ul li .single-card .card-icon span {
    font-size: 30px;
}

.filter-dropdown {
    width: 300px;
}

.filter-dropdown .submission__control {
    cursor: pointer;
}

.filter-dropdown .submission__control .submission__value-container {
    min-height: 48px;
}

.filter-dropdown .css-1dimb5e-singleValue {
    color: #fff;
}

ul.leaderboard-userdetails {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    background-color: #000;
    padding: 12px 15px;
    border-radius: 8px;
}

ul.leaderboard-userdetails li {
    /* width: 31%;  */
}

ul.leaderboard-userdetails li strong {
    color: #2CFAFE;
    font-size: 18px;
    text-transform: capitalize;
}

.leader-board-header td {
    padding-left: 20px;
    padding-right: 20px;
}


.nofifications-table .actions {
    /* display: flex; */
}

.referrals {
    background-color: #000;
    padding: 50px 40px 40px 40px;
    border-radius: 10px;
}

.referrals .referrals-head {
    max-width: 780px;
    margin: 0 auto 50px;
}

.referrals .referrals-head h2 span {
    color: #2CFAFE;
}

.referrals-copy {
    background: transparent;
    border: 2px solid #1554F0;
    color: #fff;
    border-radius: 8px;
    padding: 9px 20px;
    font-family: 'Neon';
    font-weight: 700;
    min-height: 58px;
    padding-right: 60px;
    overflow: hidden;
}

.referrals-copy span {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    background-color: #1554F0;
    color: #fff;
    height: 100%;
    width: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 22px;
    cursor: pointer;
}

.referrals .referrals-body {
    border-radius: 10px;
    background-color: #fff;
    color: #000;
    padding: 40px;
    max-width: 95%;
    margin: 0 auto;
}

.referrals .referrals-body .content h2 {
    font-family: inherit;
}

.referrals .referrals-body .content {
    text-align: center;
}

.referrals .referrals-body .content .referrals-box {
    padding: 30px;
    /* text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column; */
    position: relative;
    border: 1px solid #cecece;
    border-radius: 20px;
    height: 100%;
}

.referrals .referrals-body .content .referrals-box p {
    text-align: start;
    opacity: 0.7;
    font-size: 15px;
}

.referrals .referrals-body .content .referrals-box .box-num {
    font-size: 25px;
    font-weight: 600;
    width: 72px;
    height: 72px;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    margin-bottom: 20px;
}

.referrals .referrals-body .content .referrals-box strong {
    font-size: 16px;
    font-weight: 700;
    text-transform: uppercase;
    color: #323239;
    font-family: 'Montserrat';
}

.referrals .referrals-body .content .referrals-box .box-icon {
    /* position: absolute;
    top: -20px;
    right: 60px;
    color: #000; */
    font-size: 30px;
}

.referrals .referrals-body .content .referrals-box-1 .box-num {
    background-color: green;
}

.referrals .referrals-body .content .referrals-box-1 .box-icon {
    color: #fff;
}

.referrals .referrals-body .content .referrals-box-2 .box-num {
    background-color: #00abf0;
}

.referrals .referrals-body .content .referrals-box-2 .box-icon {
    color: #fff;
}

.referrals .referrals-body .content .referrals-box-3 .box-num {
    background-color: #1554F0;
}

.referrals .referrals-body .content .referrals-box-3 .box-icon {
    color: #fff;
}

.referrals-copy .copy-link {
    font-family: 'Montserrat';
}

.leader-board-header tbody .cinYy {
    position: absolute;
}

.referrals .referrals-body .content .referrals-box .box-number {
    position: absolute;
    left: 20px;
    top: 0;
    font-size: 18px;
    color: #BDC3C5;
    font-weight: 500;
}

.categories-box,
.categories-box-top {
    max-height: 400px;
    background-color: #00d5d9;
    border-radius: 10px;
    position: relative;
    /* overflow-x: hidden; */
    color: #000;
    z-index: 1;
    /* overflow-y: auto; */
}

/* style */

.categories-list,
.categories-box ul {
    height: 300px;
    overflow-y: auto;
}

.categories-list::-webkit-scrollbar,
.categories-box ul::-webkit-scrollbar {
    width: 5px;
}

.categories-box {
    padding: 20px;
}

.category-hiding-btn-top {
    cursor: pointer;
    padding-bottom: 6px;
}

.categories-box-top,
.category-hiding-btn-top {
    display: none;
}

.category-hiding-btn-top h3 {
    padding: 8px 35px 8px 8px;
    font-size: 20px;
    line-height: 25px;
    position: relative;
    background: #0000007a;
    color: #fff;
    border-radius: 8px;
    width: 210px;
    transition: width 0.4s ease;
}

.category-hiding-btn-top:hover h3{width: 320px;}

.fb-model .modal-content,
.insta-model .modal-content {
    min-height: 320px;
}

.fb-model .select-content,
.insta-model .select-content {
    padding-top: 25px;
}

.fb-model .select-content .fb-insta-select,
.insta-model .select-content .fb-insta-select {
    margin-bottom: 80px;
}

.fb-model .select-content .fb-insta-select .custom-select__control,
.insta-model .select-content .fb-insta-select .custom-select__control {
    justify-content: flex-start;
    font-size: 15px;
    font-weight: 700;
    opacity: 0.6;
}

.fb-model .select-content .fb-insta-select .custom-select__control .custom-select__value-container,
.insta-model .select-content .fb-insta-select .custom-select__control .custom-select__value-container {
    justify-content: flex-start !important;
}

.fb-model .custom-select__menu,
.insta-model .custom-select__menu {
    z-index: 33;
}

.fb-model .custom-select__menu .custom-select__menu-list,
.insta-model .custom-select__menu .custom-select__menu-list {
    z-index: 33;
    max-height: 150px;
    background: #323239;
}

.fb-model .custom-select__menu .custom-select__menu-list::-webkit-scrollbar,
.insta-model .custom-select__menu .custom-select__menu-list::-webkit-scrollbar {
    width: 4px;
}

.fb-model .custom-select__menu .custom-select__menu-list .custom-select__option,
.insta-model .custom-select__menu .custom-select__menu-list .custom-select__option {
    text-align: start;
    background: #000 !important;
    font-size: 15px;
    font-weight: 600;
    opacity: 0.7;
    border-bottom: 1px solid #ffffff26;
    padding: 12px;
    color: #fff;
}
.fb-model .custom-select__menu .custom-select__menu-list .custom-select__option:hover,
.insta-model .custom-select__menu .custom-select__menu-list .custom-select__option:hover {
    background: #2cfafe !important;
    color: #000;
}
.curr-wallet .curr-chain {
    width: 45px;
    height: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    border: 1px solid #2cfafe;
    transition: all 0.4s;
}

.curr-wallet .curr-chain svg {
    width: 25px;
    height: 25px;
    fill: #2cfafe
}

.curr-wallet .curr-chain:hover svg {
    fill: #000;
}

.curr-wallet .curr-chain.active {
    background-color: #2cfafe;
    box-shadow: 0px 0px 6px 0px #2cfafe;
}

.curr-wallet .curr-chain.active svg,
.curr-wallet .curr-chain:hover {
    fill: #000
}

.curr-wallet .curr-chain:hover {
    background-color: #2cfafe;
}

.curr-wallet .connect-btn {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 0;
}

.curr-wallet .modal-title {
    padding-right: 50px;
    font-size: 24px;
}

.curr-wallet .modal-content .modal-body .modal-main-body::after {
    width: 100%;
    background-color: #494a4c;
    height: 2px;
    bottom: -8px;
}

.curr-wallet .curr-heading {
    font-size: 16px;
    margin-bottom: 8px;
}

.curr-wallet .modal-content .connect-btn {
    background-color: #2cfafe;
    border: 1px solid #2cfafe;
    transition: all 0.3s;
    color: #000;
}

.curr-wallet .modal-content .connect-btn:hover,
.curr-wallet .modal-content .connect-btn:focus {
    background-color: transparent;
    color: #2cfafe;
}

.curr-wallet input {
    margin-bottom: 20px;
    background-color: transparent;
    color: #fff;
    border: 2px solid #494a4c;
    padding: 11px 15px;
}

.curr-wallet .num-copies-input::-webkit-outer-spin-button,
.curr-wallet .num-copies-input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* .curr-wallet .modal-content .modal-body button  */
.curr-wallet input:focus {
    background-color: transparent;
    outline: none;
    border-color: #494a4c;
    color: #fff;
    box-shadow: 0 0 0 0.25rem rgb(23 26 30 / 0%) !important;
}

.curr-wallet .modal-content {
    box-shadow: 0px 0px 10px #2cfafe;
    border-radius: 12px;
}


.success-popup .modal-dialog {
    max-width: 800px;
}

.success-popup .modal-dialog .modal-content {
    background: #1554F0;
    background-image: url("./assets/images/star.png");
    background-size: 100% 100%;
    background-position: center;
    text-align: center;
    min-height: 475px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    background-repeat: no-repeat;
}

.success-popup .modal-dialog .modal-content .modal-body {
    flex: inherit;
}

.success-popup .modal-dialog .modal-content .connect-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: #ffffff30;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;
    transition: all 0.3s;
}

.success-popup .modal-dialog .modal-content .connect-btn:hover {
    border-color: #ffffff40;
    background: transparent;
}

.success-popup .modal-dialog .modal-content .connect-btn svg {
    font-size: 22px;
}

.success-popup .modal-dialog .modal-content .modal-body .modal-title h2 {
    font-family: "Montserrat";
    margin-bottom: 17px;
}

.success-popup .modal-dialog .modal-content .modal-body p {
    color: #fff;
    opacity: 0.8;
    font-weight: 500;
    font-size: 18px;
    margin-top: 0;
    margin-bottom: 10px;
}

.success-popup .modal-dialog .modal-content .modal-body .main-btn-style {
    font-size: 14px;
    padding: 12px 20px;
    display: inline-block;
}

.category-hiding-btn-top h3::after {
    font-family: "Fontawesome";
    content: "\f107";
    position: absolute;
    right: 8px;
    top: 8px;
    color: #fff;
    font-size: 23px;
    line-height: 25px;
}

.categories-box h3,
.categories-box-top h3 {
    font-size: 22px;
}

.categories-box::-webkit-scrollbar,
.categories-box-top::-webkit-scrollbar {
    width: 6px;
}

.categories-box::after,
.categories-box-top::after {
    content: "";
    background-image: url("../src/assets/images/two-bg.svg");
    height: 100%;
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;
    background-repeat: no-repeat;
    background-size: cover;
    transform: rotate(180deg);
}

.categories-box ul li,
.categories-box-top ul li {
    margin-right: 0;
    margin-bottom: 8px;
    position: relative;
    border-bottom: 1px solid #50b0d7
}

.categories-box ul li:hover::after,
.categories-box-top ul li:hover::after {
    font-family: "fontawesome";
    content: "\f105";
    position: absolute;
    top: 1px;
    right: 12px;
    font-size: 23px;
}

.categories-box ul li:last-child,
.categories-box-top ul li:last-child {
    border-bottom: none;
}

.categories-box ul li a,
.categories-box-top ul li a {
    color: #000;
    display: block;
    padding: 6px;
    font-size: 17px;
    font-family: "Montserrat";
    text-transform: capitalize;
}

.category-hiding-btn {
    top: 110px;
    left: 60px;
    z-index: 990;
    display: none;
}

.category-hiding-btn .categories-box {
    display: none;
}

.category-hiding-btn-top:hover .categories-box-top {
    display: block;
    position: absolute;
    width: 320px;
    top: 45px;
    left: 0;
}

.rank-fixed {
    position: fixed;
    top: 100px;
    right: 133px;

    /* top: 110px; */
    /* right: 60px; */
    border: 1px solid #868686;
    border-radius: 5px;
    font-family: Neon;
    color: #fff;
    display: flex;
    align-items: center;
    font-weight: 600;
    height: 40px;
    overflow: hidden;
    z-index: 990;
    margin-left: auto;
}

.rank-fixed .user-rank,
.rank-fixed .user-points {
    font-size: 16px;
    padding: 10px;
    background-color: #131313;
    ;
}

.rank-fixed .user-rank-no,
.rank-fixed .user-points-no {
    min-width: 50px;
    display: flex;
    height: 40px;
    font-size: 15px;
    align-items: center;
    text-align: center;
    justify-content: center;
    padding: 5px;
}

.rank-fixed .user-rank-no {
    background-color: #DBA857;
}

.rank-fixed .user-points-no {
    background-color: #179295;
}

.fixed-section {
    /* position: fixed; */
    width: 100%;
    top: 88px;
    left: 0;
    height: 65px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 132px;
    z-index: 990;

    opacity: 1;
}

.fixed-section-bg-home.show {
    /* background-color: #131313cf; */
    position: fixed;
}

.categories-box-top-content {
    padding: 15px;
}

.categories-box-top-content::before {
    content: "";
    position: absolute;
    border-color: transparent transparent #00d5d9 transparent;
}

.information form .name-row,
.information form .email-row {
    width: 49%;
}

.information form label {
    font-size: 15px;
    margin-bottom: 8px;
}

/* ================== media quriesssssss =================== */
.information form .name-row,
.information form .email-row {
    width: 49%;
}

.information form label {
    font-size: 15px;
    margin-bottom: 8px;
}

.information form label span {
    color: red;
    font-size: 17px;
}

.login-wrap {
    padding: 10px 15px 30px;
}

.login-wrap .intro {
    margin-bottom: 50px;
}

.login-wrap .card {
    cursor: pointer;
    padding: 25px 65px;
    background: #22232A;
    margin-bottom: 5px;
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    transition: all 0.3s;
}

.login-wrap .card:hover {
    box-shadow: 0px 0px 5px #2cfafe;
    transform: scale(1.008);
}

.login-wrap .card:hover .meta-tag h4 {
    color: #2cfafe;
}

.login-wrap .card .meta-tag {
    position: relative;
    color: #fff;
    display: flex;
    /* justify-content: space-between; */
    align-items: center;
    text-align: start;
}

.login-wrap .card .meta-tag h4 {
    font-weight: bold;
    -webkit-transition: 0.3s;
    transition: 0.3s;
    margin-top: 20px;
    margin-bottom: 20px;
    color: rgb(127, 136, 144);
    max-width: 300px;
}

.login-wrap .card .meta-tag .wallet-img {
    width: 80px;
    height: 80px;
    max-width: 100%;
    margin-right: 90px;
}

.login-wrap .card .meta-tag .wallet-img img {
    object-fit: contain;
    width: 100%;
    height: 100%;
}

.wall-wrap .inner-wrapper {
    position: relative;
}

.wall-wrap .inner-wrapper .recover-delete-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 13px;
    font-family: 'Montserrat';
    padding: 5px 8px;
    border-radius: 4px;
    font-weight: 500;
    border-width: 1px;
    color: #000;
    background: #2cfafe;
    z-index: 2;
}

.connect-wallet-wrap button {
    display: none;
}




ul.pagination-container li:first-child,
ul.pagination-container li:last-child {
    font-size: 25px;
}

span.required {
    font-size: 18px;
    color: red;
    /* padding-top: 5px; */
}

.field-label p,
.dynamic-form .sd-element__title .sv-string-viewer {
    opacity: 0.7;
    color: #fff;
    margin-bottom: 5px;
    font-size: 17px;
    font-weight: 500;
}

.video-wrap video {
    object-fit: cover;
    border-radius: 15px;
}

.success-box {
    width: 900px;
    max-width: 100%;
    margin: 0 auto;
    padding: 50px 40px;
    border-radius: 16px;
    background: #323239;
    text-align: center;
}

.success-box .main-btn-style {
    font-family: 'Montserrat';
}

.submission__option:hover {
    background-color: #2cfafe !important;
    color: #000 !important;
    cursor: pointer;
}

.submission__option {
    color: #fff !important;
}

.submission__option--is-selected {
    background-color: #2cfafe !important;
    color: #000 !important;
}

.main-leaderboard .table-wrapper table tbody tr:hover,
.activity-main .table tbody tr:hover,
.nofifications-table .table tbody tr:hover {
    box-shadow: 0 0 6px #2cfafe;
}

.post-details .title h3 {
    /* white-space: nowrap; */
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    max-height: 68px;
}

.para-ellipse {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    max-height: 130px;
}

.flex-1 {
    flex: 1;
}

.treasurebox-header .navbar-toggler img {
    width: 27px;
    height: 27px;
}

.treasurebox-header .navbar-toggler {
    background: #1554F0;
    width: 40px;
    height: 40px;
    border-color: #1554F0 !important;
}

.treasurebox-header .navbar-toggler:focus {
    box-shadow: none;
}

.DraftEditor-editorContainer {
    z-index: auto !important;
}

.submission__control {
    border: 1px solid #464646;
    background-color: #323239 !important;
}

.submission__control:hover {
    border-color: #000000;
}

.submission__single-value {
    color: #fff !important;
}

.custom-select__input-container input {
    color: #fff !important;
}

.custom-select__menu-list .custom-select__option {
    cursor: pointer;
    text-transform: uppercase;
}

.custom-select__menu-list .custom-select__option:hover {
    background: #2cfafe !important;
    color: #000 !important;
}

.modal.profile-model .modal-dialog {
    position: fixed;
    width: 100%;
    height: 100%;
    transform: translate3d(0%, 0, 0);
}

.modal.profile-model.fade.show .modal-dialog {
    right: 0;
}

.modal.profile-model.fade .modal-dialog {
    right: -320px;
    transition: all 0.3s;
}

.profile-model .modal-dialog .modal-content {
    background-color: #323239;
    position: relative;
    padding-top: 60px;
}

.profile-model .modal-dialog .modal-content .modal-header {
    border-bottom: none;
}

.profile-model .modal-dialog .modal-content .close-btn {
    background: transparent;
    color: #fff;
    width: 45px;
    height: 45px;
    font-size: 22px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 15px;
    right: 20px;
}

/* .select-submission .submission__option:hover{
    background-color: #1554F0 !important;
} */
.vote-count strong {
    font-size: 22px;
    color: #fff;
}

.dynamic-form .sd-table-wrapper::before,
.dynamic-form .sd-table-wrapper::after {
    display: none !important;
}

.create-game-wrapper .dynamic-form .sd-file__decorator .sd-file__drag-area-placeholder {
    padding: 0 !important;
}

.post-details-content .comments-container .comment-form .comment-form-comment textarea:focus-visible {
    outline: none;
}

.time-since {
    font-size: 14px;
}

.boosted-view-all:active {
    color: #000 !important;
    background-color: #2cfafe !important;
    border-color: #2cfafe !important;
}

#notification-wrapper .toast-notification span {
    background-color: #439b43 !important;
}

.word-break {
    word-break: break-all;
}

.go-to-top-button.visible {
    font-size: 22px;
    color: #fff;
    position: fixed;
    bottom: 35px;
    right: 40px;
    background-color: #1554f0;
    padding: 5px;
    width: 48px;
    height: 48px;
    border-radius: 50%;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999999;
}

.confirmation-modal .modal-dialog {
    padding: 0 12px;
}

.confirmation-modal .modal-content {
    padding: 15px 10px;
}

.confirmation-modal .modal-body {
    padding: 20px;
    min-height: 100px;
}

.confirmation-modal .close-button {
    background-color: #323239;
    color: #fff;
    border-color: #323239;
}

.confirmation-modal .close-button:hover {
    background-color: transparent;
    color: #2cfafe;
    border-color: #2cfafe;
}

.confirmation-modal p {
    font-size: 20px;
    color: #fff;
    font-weight: 600;
}

.video-button {
    position: relative;
}

.video-button::before {
    content: "\f04b";
    font-family: "FontAwesome";
    position: absolute;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: #0077B5;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 32%;
    left: 50%;
    transform: translate(-50%, 50%);
    font-size: 25px;
    padding-left: 5px;
    color: #eaeaea;
}

.wrapper2.right .video-button::before {
    top: 40%;
}

.main-loader img{width: 120px; height: 120px; max-width: 100%; animation: myAnimation 4s linear infinite; }
@keyframes myAnimation {
0% { transform: rotate(0deg); }
100% { transform: rotate(360deg); }
}
.main-wrapper, .main-loader{overflow-x: hidden; width: 100vw; height: 100vh; display: flex; justify-content: center; align-items: center; flex-direction: column; background-color: black;}
.post-submittioins .sd-container-modern, .dynamic-form .sd-container-modern{margin-bottom: 30px !important;}
.boosted-ranks{background-color: transparent !important;}
.align-items-baseline{align-items: baseline;}


.user-profile{border-bottom: 1px solid #4B4B55;padding-bottom: 30px;}
.user-profile .profile-banner{width: 100%;height: 300px;border-radius: 16px;overflow: hidden; position: relative;z-index: 1; background-image: url("../src/assets/images/user-banner.png"); background-repeat: no-repeat; background-size: cover;}
/* .user-profile .profile-banner img{width: 100%; height: 100%; object-fit: cover;} */
.user-profile .profile-banner strong{font-size: 30px; font-family: "Neon"; letter-spacing: 67px;}
.user-profile .treasurebox-sec{position: relative;z-index: 1;margin-top: -40px; }
.user-profile .user-dp{width: 140px; height: 140px; border-radius: 10px; overflow: hidden; background: #323239;}
.user-profile .user-dp img {width: 100%; height: 100%; object-fit: cover;}
.user-profile .user-info{height: 100%;}
.user-profile .user-info li {font-size: 20px; margin-right: 45px;}
.user-profile .user-info li strong{color: #2cfafe;}
.user-profile .user-info .field-wrapper{width: 530px; max-width: 100%; background: transparent; border: 1px solid #fff;}
.user-profile .user-info .field-wrapper .copy-icon{background-color: transparent; color: #2cfafe;}
.user-profile .user-name strong{font-size: 30px; font-family: "Neon";}
.user-profile .user-name .status{color: #fff; opacity: 0.6; font-size: 20px;}
.user-profile .user-name .unique-user{font-size: 22px; opacity: 0.6; font-weight: 500;}
.user-profile .user-name .user-bio{max-width: 50%; font-size: 17px;}
.posts-header{padding: 22px 15px;}

.post-copy{background-color: #ffffff24; border: none; cursor: pointer; transition: all 0.3s;}
.post-copy:hover{background-color: #2cfafe; color: #000;}

.field-wrapper .user-profile-address{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.water-mark-wrap{position: relative;}
.water-mark{position: absolute; bottom: 10px; right: 10px; width: 55px !important;height: 55px !important; margin-bottom: 0 !important;object-fit: contain !important;}
.field-wrapper p{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.boosted-view-all:focus{color: #2cfafe; background-color: transparent;}
.points-tooltip{width: 250px; font-size: 15px !important; background: #44444E !important;}
.points-tooltip::before, .points-tooltip::after{background: #44444E !important;}
.cursor-pointer{cursor: pointer;}
.footer-content {
    max-height: 140px;
    overflow: hidden;
    transition: max-height 0.3s ease;
}
  
.show-more {
    max-height: none;
}
.show-btn{cursor: pointer; color: #fff; font-size: 18px; font-weight: 600; border-bottom: 1px solid #aeaeae;}




/* ================== media quriesssssss =================== */
@media (max-width: 1880px) {
    .rank-fixed {
        right: 120px;
    }
}
@media (max-width: 1850px) {

    .fixed-section {
        padding: 11px 105px;
    }

    .rank-fixed {
        right: 100px;
    }
}

@media (max-width: 1800px) {
    .fixed-section {
        padding: 11px 80px;
    }

    .rank-fixed {
        right: 78px;
    }
}

@media (max-width: 1750px) {
    .fixed-section {
        padding: 11px 48px;
    }

    .rank-fixed {
        right: 50px;
    }
}

@media (max-width: 1720px) {
    .rank-fixed {
        right: 35px;
        top: 95px;
    }

    .success-popup .modal-dialog .modal-content {
        border-radius: 18px;
    }

    .wall-cards ul li .single-card .card-icon span img {
        width: 40px;
    }

    .purchase-item .item {
        padding: 17px 70px 17px 25px;
    }

    .purchase-item .item .content h4 {
        font-size: 18px;
        margin-bottom: 8px;
        line-height: 24px;
    }

    .purchase-item .item .content p {
        font-size: 15px;
    }

    .purchase-item .item .image {
        width: 35px;
        height: 35px;
        margin-right: 30px;
    }

    .purchase-item .item::after {
        right: 22px;
        font-size: 26px;
    }

    .nft-sec4 h2.category-h {
        /* font-size: 20px; */
    }

    .category-hiding-btn-top:hover .categories-box-top {top: 45px;}

    .fixed-section {
        padding: 11px 32px;
    }

    .parent-tag .dropdown-nav section::before {
        /* right: 60px; */
    }

    .nft-sec .nft-meta {
        /* padding: 10px 10px; */
        /* margin: 0 20px; */
        /* margin-top: -25px; */
    }

    .sidebar .logo .name span {
        font-size: 12px;
    }

    body {
        font-size: 16px;
    }

    .setting-wrap {
        padding: 50px 50px 20px 50px;
    }

    .profile-component .setting-loader {
        background: none !important;
    }

    .profile-component {
        position: relative !important;
    }

    /* h2 {
        font-size: 32px;
        line-height: 43px;
    } */

    h3 {
        font-size: 22px;
        line-height: 32px;
    }

    h4 {
        font-size: 20px;
        line-height: 28px;
    }

    h5 {
        font-size: 17px;
        line-height: 26px;
    }

    .my-nfts .nft-list ul {
        justify-content: start;
    }

    .my-nfts .nft-list ul li {
        width: 33%;
    }

    .game-list ul li {
        width: 32%;
    }

    .game-list ul li.treasury-box {
        margin-top: 180px;
    }

    .section-dark-bg {
        padding: 20px;
    }

    .main-dashboard .sidebar {
        max-width: 280px;
    }

    .sidebar .logo {
        margin-bottom: 50px;
        padding: 0 20px;
    }

    .sidebar .logo .image {
        width: 50px;
        height: 50px;
        margin-right: 10px;
    }

    .sidebar-links li a .icon {
        margin-right: 15px;
    }

    .sidebar-links li a {
        font-size: 14px;
        padding: 12px 20px;
    }

    .sidebar-links li a .icon svg {
        width: 20px;
    }

    .sidebar-links li.static {
        margin-top: 5px;
    }

    .collectibles-list ul li {
        width: 48%;
    }

    .notifications {
        padding-right: 30px;
    }

    .notifications .list {
        padding-right: 20px;
        /* background: #33363940 ; */
    }

    .notifications li {
        padding: 12px 20px;
        font-size: 15px;
    }

    .notice {
        padding: 25px;
    }

    .user-stats ul li:nth-child(2),
    .user-stats ul li:last-child {
        padding-left: 20px;
    }

    .section-dark-bg .heading,
    .section.heading {
        margin-bottom: 20px;
    }

    .badges-list ul li {
        width: 33%;
    }

    .settings-sidenav ul li a:hover {
        opacity: 1;
    }

    .activities ul li {
        padding: 20px;
    }

    .nft-list ul li .image {
        height: 150px;
    }

    .image .sale {
        font-size: 12px;
        padding: 4px 10px;
    }

    .nft-list ul li .name {
        margin-bottom: 10px;
    }

    .image .timer {
        padding: 5px 10px;
        font-size: 12px;
    }

    .badges .item .image {
        width: 120px;
    }

    .badges .item .image-bg {
        padding: 20px;
    }

    .avatar-badge .avatar {
        margin-right: 30px;
    }

    .avatar-badge .image {
        height: 140px;
        width: 120px;
    }

    .main-content-area {
        width: calc(100% - 280px);
    }

    .main-content-area .page-title h2 {
        font-size: 32px;
        line-height: 42px;
    }

    .treasurebox-header .nav-link:not(:last-child) {
        margin-right: 33px;
    }

    .treasurebox-sec {
        /* padding: 120px 0 0; */
    }

    .main-leaderboard .table-wrapper h2 {
        top: 22px;
    }

    .settings-sidenav ul li a.active {
        opacity: 1;
        background: none;
        padding-bottom: 6px;
    }

    .leaderboard-table table thead tr th {
        padding: 16px 12px;
    }

    .leaderboard-table table thead tr th:first-child {
        width: 15%;
    }

    .custom-group .checkbox {
        appearance: none;
        /* background-color: #000; */
        margin: 0;
        font: inherit;
        color: currentColor;
        width: 20px;
        height: 20px;
        border: 3px solid #53535f;
        border-radius: 0.15em;
        /* transform: translateY(-0.075em); */
    }

    .custom-group {
        display: block;
        position: relative;
        padding-left: 30px;
        margin-bottom: 12px;
        cursor: pointer;
        font-size: 22px;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }

    /* Hide the browser's default checkbox */
    .custom-group input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
        left: 0;
        top: 0;
        z-index: 1;
    }

    /* Create a custom checkbox */
    .checkmark {
        position: absolute;
        top: 0;
        left: 0;
        height: 20px;
        width: 20px;
        /* background-color: #000; */
        border: 2px solid #53535f;
    }

    /* On mouse-over, add a grey background color */
    .custom-group:hover input~.checkmark {
        /* background-color: #1c1821; */
    }

    /* When the checkbox is checked, add a blue background */
    .custom-group input:checked~.checkmark {
        background-color: #1554F0;
    }

    /* Create the checkmark/indicator (hidden when not checked) */
    .checkmark:after {
        content: "";
        position: absolute;
        display: none;
    }

    /* Show the checkmark when checked */
    .custom-group input:checked~.checkmark:after {
        display: block;
    }

    /* Style the checkmark/indicator */
    .custom-group .checkmark:after {
        left: 5px;
        top: 1px;
        width: 6px;
        height: 11px;
        border: solid white;
        border-width: 0px 2px 2px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
    }

    .custom-file-picker {
        position: relative;
        overflow: hidden;
        display: inline-block;
    }

    /* .custom-file-picker button {
            border: 2px solid #53535f;
            color: #fff;
            background-color: #1c1821;
            padding: 8px 20px;
            border-radius: 8px;
            font-size: 18px;
            font-weight: bold;
            cursor: pointer;
        } */

    .custom-file-picker input[type=file] {
        font-size: 30px;
        position: absolute;
        left: 0;
        top: 0;
        opacity: 0;
    }

    .join-date {
        display: flex;
        justify-content: space-between;
    }

    .join-date strong {
        /* color: #2CFAFE; */
    }

    /* .user-profile-info{display: flex; flex-wrap: wrap; justify-content: center;} */
    .user-profile-info .info-wrap {
        padding: 8px 10px;
        border: 1px solid #2CFAFE;
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 8px;
        width: 100%;
    }

    .user-profile-info .info-wrap:first-child {
        margin-left: 0;
    }

    .user-profile-info .info-wrap:last-child {
        margin-right: 0;
    }

    .user-profile-info .info-wrap span {
        font-size: 14px;
        text-transform: capitalize;
    }

    .new-trending .post-img {
        height: 300px;
    }

    .custom-group .checkbox {
        width: 18px;
        height: 18px;
    }

    .new-trending .post-img img,
    .new-trending .post-img video {
        height: 100%;
        object-fit: cover;
        width: 100%;
    }

    .activity-main .activity-wrap {
        display: flex;
        align-items: center;
        padding: 20px 0;
        border-bottom: 1px solid #53535f;
    }

    .activity-main .activity-wrap .activity-avatar a {
        display: block;
        margin-right: 20px;
    }

    .activity-main .activity-wrap .activity-avatar a img {
        border-radius: 5px;
    }

    .activity-main .activity-wrap .activity-content .activity-header strong :nth-child(2) {
        opacity: 0.4;
    }

    .activity-main .activity-wrap .activity-content .activity-bubble a {
        background-color: #53535f;
        padding: 8px 10px;
        border-radius: 5px;
    }


    .submission__placeholder {
        color: #fff !important;
    }

    .main-logo:focus-visible {
        outline: none;
    }

    .reaction-sections {
        /* padding-top: 30px; */
    }

    .reaction-item {
        background-color: #53535f;
        /* height: 120px; */
        position: relative;
    }

    .reaction-item::after {
        content: "";
        position: absolute;
        width: 100%;
        height: 0;
        /* background-color: #ffe400; */
        bottom: 0;
        left: 0;
        right: 0;
        transition: all 0.4s;
    }

    .reaction-item.react-added::after {
        /* height: 30px; */
        /* height: var(--reaction-fill); */
        transition: all 0.4s;
    }

    .reaction-item.selected {
        background-color: #ffe400;
    }

    .reaction-item .bb-badge .circle img {
        /* width: 40px;
            height: 40px;
            background-color: #ffe400;
            border-radius: 50%; */
        /* margin-top: -20px; */
    }

    .reaction-stat-count {
        /* width: 100%;
            height: calc(100% - 35px);
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 12px;
            font-weight: 600;
            position: relative;
            z-index: 1; */
    }

    .reaction-stat-count span {
        font-size: 13px;
        /* background: #fff;
            width: 30px;
            height: 30px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 50%;
            color: #000; */
    }

    .reaction-vote-btn {
        background-color: #53535f;
        text-align: center;
        transition: all 0.2s ease-in-out;
        text-transform: uppercase;
        font-size: 13px;
        font-weight: 700;
        /* padding: 10px 0; */
    }

    .reaction-vote-btn:hover {
        background-color: #ffe400;
        color: #000;
        cursor: pointer;
    }

    .reaction-btn-disabled {
        cursor: not-allowed;
        pointer-events: none;
    }

    .reaction-vote-btn-disabled {
        /* background-color: #53535f; */
        opacity: 0.5;
        text-align: center;
        transition: all 0.2s ease-in-out;
        text-transform: uppercase;
        font-size: 13px;
        font-weight: 700;
        /* padding: 10px 0; */
        cursor: not-allowed;
    }

    .spinner-border.reaction-loader {
        position: absolute;
        left: 43%;
    }

    .reaction-sections .col-sm-2,
    .reaction-sections .col-sm-4 {
        padding: 0 6px;
    }

    /* .profile-notifications{position: relative;} */
    .notification-detail {
        background-color: #323639;
        /* width: 400px; */
        border-radius: 8px;
        position: absolute;
        right: 0;
        top: 18px;
        /* z-index: 4; */
        /* box-shadow: 0 0 10px #2cfafe; */
    }

    .notification-detail::before {
        content: "";
        position: absolute;
        top: -15px;
        width: 0;
        right: 0;
        transform: translateX(-50%);
        height: 0;
        border-style: solid;
        border-width: 0 13px 15px 13px;
        /* border-color: transparent transparent #2CFAFE transparent;*/
        border-color: transparent transparent #323639 transparent;

        z-index: 2;
    }

    .notification-detail ul li {
        /* border-bottom: 1px solid #cecece; */
        padding: 18px 30px;
        padding-bottom: 0;
        position: relative;
    }

    .notification-detail ul li.unread-notification a::before {
        content: "";
        position: absolute;
        width: 8px;
        height: 8px;
        background-color: red;
        border-radius: 50%;
        top: 50%;
        transform: translateY(-50%);
        left: 10px;
    }

    .notification-detail ul li a {
        color: #fff;
        font-size: 14px;
        background-color: transparent;
        /* border-radius: 4px; */
        display: block;
        /* padding: 10px 12px; */
        border-bottom: 1px solid #585C60;
        padding-bottom: 16px;
    }

    .notification-detail ul li:last-child a {
        border-bottom: none;
    }

    .notification-detail ul li:last-child {
        border-bottom: 1px solid #585C60;
    }

    .notification-detail ul li:first-child {
        border-top: 1px solid #585C60;
    }

    .unread-notification ul li a {
        background-color: #414448;
    }

    .notification-detail ul li a p {
        /* padding-bottom: 10px; */
        /* border-bottom: 1px solid #ffffff1c; */
    }

    .notification-detail ul li a:hover {
        /* background-color: rgba(225, 255, 225, 0.3); */
    }


    .main-leaderboard {
        padding: 0 15px;
    }

    .main-leaderboard .table-wrapper {
        /* max-width: 1560px;
            margin: 50px auto;
            background: url("./assets/images/table-middle.png");
            background-size: 100%;
            padding: 100px 50px 60px;
            position: relative;
            background-repeat: repeat;
            background-position: top;
            z-index: 9; */
    }

    .main-leaderboard .table-wrapper::before {
        /* content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            z-index: -1;
            background: url("./assets/images/table-top.png");
            height: 100px;
            background-repeat: no-repeat;
            background-size: 100% 100%;
            background-position: top;
            background-color: #1a1a1a; */
    }

    .main-leaderboard .table-wrapper::after {
        /* content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            z-index: -1;
            background: url("./assets/images/table-bottom.png");
            height: 40px;
            background-repeat: no-repeat;
            background-position: bottom;
            background-size: 100% 100%;
            background-color: #1a1a1a; */
    }

    .main-leaderboard .table-wrapper .table {
        color: #fff;
        border-spacing: 0 14px;
        border-collapse: separate;
    }

    .main-leaderboard .table-wrapper .loader {
        min-height: 60vh;
        align-items: center;
    }

    .main-leaderboard .table-wrapper .table td,
    .main-leaderboard .table-wrapper .table th {
        border: none;
        box-shadow: none;
        text-align: left;
        font-weight: 500;
    }

    .main-leaderboard .table-wrapper .table .user-details,
    .main-leaderboard .table-wrapper .table .badges ul {
        justify-content: flex-start;
        /* min-width: 180px;     */
        align-items: center;
    }

    .main-leaderboard .table-wrapper h2 {
        position: absolute;
        left: 0;
        right: 0;
        top: 15px;
        text-align: center;
        color: #fff;
    }

    .main-leaderboard .table-wrapper .table tbody {
        border: none;
    }

    .main-leaderboard .table-wrapper .table td {
        background: transparent;
        vertical-align: middle;
        font-weight: 500;
    }

    .main-leaderboard .table-wrapper .table tbody tr {
        /* clip-path: polygon(2% 0%, 98% 0%, 100% 100%, 0% 100%); */
        background: linear-gradient(to right, #1f1f27, #1f1f27);
    }

    .main-leaderboard .table-wrapper .table tr.first {
        /* clip-path: polygon(2% 0%, 98% 0%, 100% 100%, 0% 100%); */
        background: linear-gradient(to right, #fd3448, #fd25e6);
    }

    .main-leaderboard .table-wrapper .table tr.second {
        /* clip-path: polygon(2% 0%, 98% 0%, 100% 100%, 0% 100%); */
        background: linear-gradient(to right, #fd2cd0, #feca36);
    }

    .main-leaderboard .table-wrapper .table tr.third {
        /* clip-path: polygon(2% 0%, 98% 0%, 100% 100%, 0% 100%); */
        background: linear-gradient(to right, #7f3afb, #2ce3fd);
    }

    .main-leaderboard .table-wrapper .table tr.forth {
        /* clip-path:  polygon(0% 0%, 100% 0%, 99.5% 100%, 0.5% 100%); */
        background: linear-gradient(to right, #01b3caf5, #024750fb);
    }

    .main-leaderboard .table-responsive tbody {
        width: 100%;
        position: relative;
    }

    .main-leaderboard .table-responsive .sc-gsnTZi {
        position: absolute;
        left: 0;
        right: 0;
    }

    .user-details {
        display: flex;
    }

    .user-details .image img {
        height: 100%;
        object-fit: cover;
        width: 100%;
    }

    img[alt] {
        font-size: 12px;
    }

    .user-details .user-info {
        position: relative;
        text-align: left;
        margin-left: 10px;
        margin-bottom: 0;
    }

    .user-details .user-info h5 {
        font-family: 'Montserrat';
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
    }

    .user-details .user-info span {
        font-size: 12px;
    }

    .badges ul {
        display: flex;
    }

    .badges ul li {
        background: #ffffff14;
        width: 35px;
        height: 35px;
        margin-right: 5px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .badges ul li img {
        width: 22px;
    }

    .notification-section li a {
        display: inline-block;
    }

    /* .nofifications-table tbody tr:nth-child(2n+1) {
        background-color: #41414b;
    } */

    .nofifications-table a {
        color: #a4a4a4;
    }

    .nofifications-table a:hover {
        text-decoration: underline;
    }

    .nofifications-table .actions {
        color: #a4a4a4;
    }

    .nofifications-table .actions span:hover {
        text-decoration: underline;
        cursor: pointer;
    }

    .nofifications-table .table>:not(caption)>*>* {
        border-bottom-width: 0;
    }

    .see-all-activities {
        color: #2CFAFE;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 18px 10px;
    }

    .see-all-activities:hover {
        opacity: 0.9;
        color: #2CFAFE;
    }

    .notification-detail h5 {
        /* background: #2CFAFE;
        color: #000;
        padding: 6px 0; */
        color: #2CFAFE;
        padding: 18px 10px;

    }

    .notification-detail ul {
        /* padding: 12px 0; */
        /* padding-bottom: 13px; */
    }



    .post-sidebar-secondray .categories-box.fixed,
    .post-sidebar .trending-posts.fixed {
        position: fixed;
        top: 20px;
    }

    /* .post-sidebar .trending-posts.fixed{padding-right: 75px;} */
    .text-capitalize {
        text-transform: capitalize;
    }

    .post-sidebar #trend {
        padding-top: 20px;
        /* padding-right: 50px; */
    }

    .wrapper1 .views-votes {
        background-color: #00000067;
        border-radius: 20px;
        min-width: 100px;
        padding: 3px 8px;
        justify-content: space-between;
    }

    .wrapper4 .views-votes {
        background-color: #00000067;
        border-radius: 20px;
        min-width: 90px;
        padding: 3px 8px;
        justify-content: space-between;
    }

    .static-content {
        background-color: #333639;
        padding: 40px 70px;
    }

    .static-content h2 {
        font-family: "Neon";
        margin-bottom: 40px;
    }

    .static-content ul li {
        margin-bottom: 35px;
    }

    .mini-ul {
        list-style-type: disc;
        margin-bottom: 20px;
    }

    .mini-ul li {
        margin-bottom: 10px !important;
    }

    .static-content ul li h4 {
        margin-bottom: 15px;
        border-bottom: 1px solid #fff;
        display: inline-block;
    }

}
@media (max-width: 1700px) {
    .rank-fixed{right: 22px;}
    .fixed-section {padding: 11px 22px;}
}
@media (max-width: 1599px) {
    .show-btn{font-size: 17px;}
    .footer-content{max-height: 130px;}
    .show-more{max-height: none;}
    .category-hiding-btn-top h3{font-size: 18px;}
    .category-hiding-btn-top:hover .categories-box-top {top: 44px;}
    .user-profile .profile-banner strong {font-size: 26px;letter-spacing: 50px;}
    .posts-header{padding: 20px 15px 15px;}
    .user-profile .user-dp {
        width: 130px;
        height: 130px;
    }
    .user-profile .user-info li {
        font-size: 18px;
        margin-right: 30px;
    }
    .user-profile .user-info .field-wrapper {
        width: 500px;
        border: 1px solid #fff;
        height: 52px;
    }
    .user-profile .user-name .status{font-size: 17px;}
    .user-profile .user-name strong{font-size: 26px;}
    .user-profile .user-name .unique-user{font-size: 20px;}
    .confirmation-modal p {
        font-size: 17px;
    }

    .nft-sec2 .wrapper2.right {
        min-height: 520px;
    }

    .nft-sec2 .wrapper2.right:hover .inner-wrapper .post-bottom {
        top: calc(100% + -91px);
    }

    .wrapper2.right .post-bottom {
        top: calc(100% + -60px);
    }

    .nft-sec1 h2 {
        font-size: 24px;
        line-height: 30px;
    }
    h2 {
        font-size: 24px;
        line-height: 30px;
    }

    .upload-wrapper {
        border-radius: 18px;
        width: 250px;
    }

    .form-modal .react-tagsinput-input {
        font-size: 16px;
    }

    .treasurebox-sec .box-lottie {
        margin-right: 120px;
        width: 260px;
        height: 260px;
    }

    .not-found .post-img {
        width: 100px;
        height: 100px;
    }

    .not-found p {
        font-size: 16px;
        font-weight: 500;
    }

    .wall-cards ul li .single-card .card-icon span img {
        width: 36px;
    }

    .treasurebox-sec .container-mini {
        max-width: 1240px;
    }

    .footer-sec .social-links li,
    .parent-tag .dropdown-nav section ul.social-links a {
        width: 40px;
        height: 40px;
    }

    .settings-sidenav {
        height: 620px;
    }

    .main-leaderboard .table-responsive {
        height: 520px;
    }

    .activity-main .table-responsive {
        height: 560px;
    }

    .categories-list h3 {
        font-size: 19px;
    }

    .post-sidebar-secondray ul li {
        font-size: 15px;
        padding: 8px 0;
    }

    .post-sidebar .post-img {
        height: 220px;
    }

    .trending-posts .post-desc p {
        font-size: 17px;
    }

    .post-details .auther-views {
        padding: 17px;
    }

    .personal-info .information .image-data .image {
        width: 190px;
        height: 190px;
    }

    .settings-sidenav ul li a {
        font-size: 17px;
    }

    .settings-sidenav ul li {
        padding: 7px;
    }

    .nft-sec2 .wrapper2.right .image {
        height: 480px;
    }

    .nft-sec2 .wrapper2.right:hover .image {
        height: 450px;
    }

    .main-leaderboard .table-wrapper,
    .activity-main .table-wrapper,
    .notification-section .table-wrapper {
        /* padding: 90px 30px 35px; */
        padding: 25px 30px;
    }

    .referrals .referrals-head {
        max-width: 700px;
    }

    .referrals .referrals-body .content .referrals-box {
        padding: 20px;
    }

    .referrals-copy .copy-link {
        font-size: 15px;
    }

    .wall-cards ul li .single-card .card-icon {
        /* width: 45px;
            height: 45px; */
    }

    .wall-cards ul li .single-card .card-icon span {
        font-size: 28px;
    }

    .wall-cards ul li .single-card strong {
        font-size: 15px;
    }

    .wall-cards ul li .single-card p {
        font-size: 25px;
    }

    .wall-cards ul li .single-card {
        min-height: 135px;
    }

    .footer-links-ul a {
        font-size: 16px;
    }

    .widget_create_post .text,
    .footer-sec .footer-wrapper p {
        font-size: 17px;
    }

    .nft-sec4 .wrapper4 {
        width: 24%;
    }

    .treasurebox-header .main-btn-style {
        font-size: 14px;
        /* padding: 9px; */
    }

    .main-btn-style,
    .personal-info .information form .btn-primary,
    .main-btn-style:disabled {
        padding: 9px 20px;
    }

    .search-login-btn input,
    .search-login-btn .search-icon {
        height: 43px;
    }

    /* .widget_create_post:before{width: 75px; height: 75px;} */
    .widget_create_post {
        /* padding: 70px 10px 10px; */
    }

    .widget-title {
        margin-bottom: 20px;
    }

    .widget_create_post .main-btn-style {
        font-size: 15px;
    }

    .widget_create_post:after {
        min-height: 170px;
    }

    .notification-detail {
        width: 350px;
    }

    .footer-links-ul a {
        font-size: 16px;
    }

    .widget_create_post .text,
    .footer-sec .footer-wrapper p {
        font-size: 17px;
    }

    .nft-sec4 .wrapper4 {
        width: 24%;
    }

    .search-login-btn input,
    .search-login-btn .search-icon,
    .profile-notifications button {
        height: 43px;
        width: 43px;
    }

    .treasurebox-header nav.navbar .main-btn-style {
        min-height: 43px;
    }

    /* .widget_create_post:before{width: 75px; height: 75px;} */

    .widget-title {
        margin-bottom: 20px;
        font-size: 19px;
    }

    .widget_create_post:after {
        min-height: 170px;
    }

    .notification-detail {
        /* width: 330px; */
    }
}

@media (max-width: 1499px) {

    .search-login-btn input,
    .search-login-btn .search-icon {
        height: 38px;
    }

    .search-login-btn .search-icon,
    .search-login-btn .input-no-width {
        width: 38px;
    }

    .treasurebox-header .main-btn-style {
        font-size: 14px;
        /* border-width: 1px; */
    }

    .search-login-btn input,
    .search-login-btn .search-icon,
    .profile-notifications button {
        font-size: 15px;
    }

    .treasurebox-header .nav-link {
        font-size: 15px;
    }

    .treasurebox-header .nav-link {
        font-size: 15px;
    }

    .notification-detail {
        /* width: 290px; */
    }
}

@media (max-width: 1399px) {
    .footer-content{max-height: 123px;}
    .show-more{max-height: none;}
    .categories-box h3, .categories-box-top h3 {font-size: 18px;margin-bottom: 10px !important;}
    .voting-wrapper{font-size: 17px; height: 38px;}
    .user-profile .profile-banner{height: 280px;}
    .user-profile .user-dp {
        width: 120px;
        height: 120px;
    }
    .user-profile .user-info li {
        font-size: 17px;
        margin-right: 27px;
    }
    .user-profile .user-info .field-wrapper {
        width: 470px;
        border: 1px solid #fff;
        height: 50px;
    }
    .user-profile .user-name .status{font-size: 16px;}
    .user-profile .user-name strong{font-size: 23px;}
    .user-profile .user-name .unique-user{font-size: 18px;}
    .nft-sec4 h2 span:last-child{font-size: 20px;}
    .confirmation-modal p {
        font-weight: 500;
    }

    .fixed-section {
        height: 55px;
    }

    .go-to-top-button.visible {
        width: 43px;
        height: 43px;
        font-size: 18px;
    }

    .nft-sec2 .wrapper2.right {
        min-height: 450px;
    }

    .nft-sec2 .wrapper2.right:hover .image {
        height: 390px;
    }

    #notification-wrapper .toast-notification span {
        padding: 8px 16px !important;
        font-size: 13px;
    }

    .nofifications-table a,
    .notification-section .table-wrapper .table tbody tr td:first-child {
        font-size: 14px;
    }

    .time-since {
        font-size: 12px;
    }

    .treasurebox-tabs .tabs-wrap .select-tabs-box .tags-select .react-dropdown-select-dropdown .react-dropdown-select-item {
        margin: 5px;
        padding: 9px 17px;
        font-size: 15px;
    }

    .video-wrap .upload-camera {
        font-size: 14px;
    }

    .success-popup .modal-dialog .modal-content .modal-body .main-btn-style {
        padding: 10px 18px;
        font-size: 13px;
    }

    .success-popup .modal-dialog .modal-content {
        border-radius: 16px;
    }

    .success-popup .modal-dialog {
        max-width: 670px;
    }

    .success-popup .modal-dialog .modal-content {
        min-height: 390px;
    }

    .success-popup .modal-dialog .modal-content .modal-body p {
        font-size: 16px;
    }

    .select-tabs-box ul li {
        padding: 8px 16px;
        font-size: 15px;
    }

    #tabs-select .search-wrapper input {
        font-size: 15px;
    }

    .treasurebox-tabs .tabs-wrap.active #tabs-select .search-wrapper::before {
        font-size: 18px;
    }

    .treasurebox-tabs .tabs-wrap.active {
        padding: 0;
    }

    .upload-wrapper .upload-camera {
        width: 50px;
        height: 50px;
        font-size: 21px;
    }

    .upload-wrapper {
        border-radius: 15px;
    }

    .personal-info .information .image-data .image {
        width: 180px;
        height: 180px;
    }

    .boosted-detail .boosted-icon span {
        font-size: 11px;
    }

    .boosted-detail .boosted-icon .icon {
        width: 25px;
        height: 25px;
    }

    .select-tabs-box button {
        width: 43px;
        height: 43px;
        padding: 6px;
    }

    .create-game-wrapper .form-modal {
        margin-bottom: 40px;
    }

    .create-game-wrapper .form-btn-wrap .main-btn-style {
        font-size: 16px;
        padding: 10px 20px;
    }

    .treasurebox-sec .container-mini {
        max-width: 1100px;
    }

    .purchase-item .item {
        padding: 15px 65px 14px 20px;
    }

    .purchase-item .item .content h4 {
        font-size: 17px;
        line-height: 22px;
        margin-bottom: 5px;
    }

    .purchase-item .item .image {
        width: 30px;
        height: 30px;
    }

    .nft-sec4 h2.category-h {
        /* font-size: 18px; */
    }

    .post-details .questions-container h4,
    .post-details .questions-container h6 {
        font-size: 16px;
        line-height: 23px;
    }

    .footer-sec .gamification-logo span:last-child {
        font-size: 16px;
    }

    .wrapper2 .post-bottom,
    .wrapper1 .post-bottom {
        padding: 10px;
    }

    .sd-action {
        padding: 6px 10px !important;
        font-size: 14px !important;
    }

    .setting-wrapper .wallet-address h4,
    .category-hiding-btn-top h3 {
        font-size: 17px;
    }

    .wrapper2 .inner-wrapper h3 {
        left: 7px;
    }

    .nft-sec2 .wrapper2.left {
        min-height: 330px;
    }

    .wrapper2.right .inner-wrapper h3 {
        left: 13px;
    }

    .rate-btn,
    .like-count {
        width: 38px;
        height: 38px;
        min-width: 38px;
        font-size: 16px;
    }
    
    .settings-sidenav {
        height: 580px;
    }

    .main-leaderboard .table-responsive {
        height: 490px;
    }

    .activity-main .table-responsive {
        height: 522px;
    }

    .login-wrap .card .meta-tag .wallet-img {
        width: 70px;
        height: 70px;
        margin-right: 55px;
    }

    /* .login-wrap .card .meta-tag{justify-content: space-between;} */
    .submissions-post .btn-wrapper-share.active .delete_button span:first-child {
        display: block;
        margin-right: 0 !important;
    }

    .submissions-post .btn-wrapper-share.active .delete_button span:last-child {
        display: none;
    }

    ::-webkit-scrollbar {
        width: 6px;
        height: 6px;
    }

    .post-sidebar-secondray ul li {
        font-size: 13px;
    }

    .trending-posts {
        padding: 15px;
    }

    /* .nft-meta-trending{display: block !important;} */
    .nft-meta .views-votes .views svg {
        width: 17px !important;
    }

    .trending-posts .post-creater span:last-child,
    .nft-meta span {
        font-size: 12px;
    }

    .post-sidebar .post-img {
        height: 190px;
    }

    .auther-views .auther .image {
        width: 45px;
        height: 45px;
        margin-right: 14px;
    }

    .reaction-item .bb-badge .circle {
        width: 35px;
        height: 35px;
        margin-right: 10px;
    }

    .post-details .points-detail-desc {
        font-size: 13px;
    }

    .post-details-content .trending-posts .new-trending .post-img {
        height: 240px;
    }

    .trending-posts .post-desc p {
        font-size: 16px;
    }

    .post-details-content .trending-posts .new-trending .nft-meta .views-votes {
        padding: 3px 10px;
    }

    .post-details .tags {
        margin-bottom: 10px;
    }

    .post-details .auther-views {
        padding: 13px;
    }

    .submission__control {
        width: 410px;
    }

    .submission__input-container {
        height: 38px;
    }

    .submissions-post .auther-views,
    .submissions-post .title {
        padding: 10px 14px;
    }

    .submissions-post .auther-views .image {
        width: 45px;
        height: 45px;
        margin-right: 13px;
    }

    .submissions-post .auther-views .date {
        font-size: 13px;
    }

    .submissions-post .auther-views .name a {
        font-size: 16px;
    }

    .submissions-post .tags a {
        padding: 2px;
        margin-top: 3px;
    }

    .field-wrapper,
    .personal-info .information form .form-control {
        height: 50px;
    }

    .field-wrapper p {
        font-size: 15px;
    }

    .settings-wrap {
        padding-top: 30px;
    }

    .personal-info .information form textarea {
        height: 200px !important;
    }

    .settings-sidenav {
        border-radius: 14px;
    }

    .settings-sidenav ul li {
        padding: 7px;
    }

    .wrapper4 .image {
        height: 225px;
    }

    .nft-sec4 .wrapper4 {
        height: 360px;
    }

    .nft-sec4 .wrapper4 {
        margin-bottom: 12px;
    }

    .nft-sec2 .wrapper2.right .image {
        height: 420px;
    }

    .nft-sec2 .wrapper2.left .image {
        height: 300px;
    }

    .nft-sec2 .wrapper2.left:hover .inner-wrapper .image {
        height: 270px;
    }

    /* .nft-sec2 .wrapper2{} */
    .nft-sec3 .wrapper3 .image {
        height: 260px;
    }

    .wrapper1 .tags {
        margin-bottom: 3px;
    }

    .nft-sec1 span {
        font-size: 12px;
    }

    .categories-box ul li a,
    .categories-box-top ul li a {
        font-size: 15px;
    }

    .categories-box ul li,
    .categories-box-top ul li {
        margin-bottom: 5px;
    }
    .categories-box{
        padding: 18px 13px;
    }
    .categories-box, .categories-box-top {max-height: 360px;}

    .categories-list,
    .categories-box ul {
        height: 260px;
    }

    .wrapper1 .post-bottom {
        padding: 8px 10px;
        border-radius: 12px;
        bottom: -90px;
    }

    .wrapper1:hover .inner-wrapper .image {
        height: 260px;
    }

    .post-sidebar-secondray ul li {
        padding: 4px 0;
    }

    .wrapper1 .views-votes {
        min-width: 90px;
    }

    .rank-fixed .user-rank,
    .rank-fixed .user-points,
    .rank-fixed .user-rank-no,
    .rank-fixed .user-points-no {
        font-size: 14px;
    }

    .fixed-section {
        padding: 6px 13px;
    }

    ul.leaderboard-userdetails {
        padding: 8px 12px;
    }

    ul.leaderboard-userdetails li strong {
        font-size: 17px;
    }

    .main-leaderboard .table-wrapper .table td,
    .main-leaderboard .table-wrapper .table th {
        font-size: 13px;
    }

    .main-leaderboard .table-wrapper .table th {
        white-space: nowrap;
    }

    .user-details .user-info h5 {
        font-size: 13px;
        font-weight: 600;
    }

    .user-details .image {
        width: 41px;
        height: 41px;
    }

    .referrals .referrals-head h2 {
        font-size: 27px;
    }

    .referrals-copy {
        min-height: 52px;
    }

    .referrals .referrals-head {
        max-width: 600px;
    }

    .referrals-copy .copy-link {
        font-size: 14px;
    }

    .referrals-copy span,
    .referrals .referrals-body .content .referrals-box .box-icon {
        font-size: 25px;
    }

    .referrals .referrals-body .content .referrals-box {
        border-radius: 15px;
    }

    .referrals .referrals-body .content .referrals-box .box-num {
        width: 55px;
        height: 55px;
        border-radius: 7px;
    }

    .referrals .referrals-body .content .referrals-box p {
        font-size: 15px;
    }

    .referrals .referrals-body .content .referrals-box strong {
        font-size: 15px;
    }

    .referrals .referrals-head {
        margin-bottom: 40px;
    }

    .referrals .referrals-body .content h2 {
        font-size: 25px;
    }

    .referrals .referrals-body {
        padding: 30px 20px;
    }

    .footer-sec .social-links li,
    .parent-tag .dropdown-nav section ul.social-links a {
        width: 37px;
        height: 37px;
        font-size: 17px;
    }

    .wall-cards ul li .single-card {
        padding: 10px 14px;
    }

    .wall-cards ul li .single-card {
        min-height: 120px;
    }

    .wall-cards ul li .single-card .card-icon {
        /* width: 40px;
            height: 40px; */
    }

    .wall-cards ul li .single-card .card-icon span {
        font-size: 26px;
    }

    .wall-cards ul li .single-card .card-icon span img {
        width: 30px;
    }

    .static-content {
        padding: 35px 40px;
    }

    .static-content h2 {
        margin-bottom: 30px;
    }

    .footer-links-ul a {
        font-size: 15px;
    }

    .footer-sec .footer-wrapper p {
        font-size: 16px;
    }

    .post-details .preface {
        font-size: 20px !important;
    }

    .widget_create_post:before {
        font-size: 26px;
        width: 50px;
        height: 50px;
    }

    .widget_create_post {
        /* padding: 53px 10px 10px; */
    }

    .widget .icon-bg {
        margin-bottom: 25px;
    }

    .widget {
        margin-bottom: 20px;
        height: 260px;
    }

    .widget_create_post:after {
        min-height: 155px;
    }

    .widget_create_post .text {
        font-size: 16px;
    }

    .widget_create_post a {
        font-size: 15px;
    }

    .nft-meta .author-name p span:nth-child(2) {
        font-weight: 500;
    }

    /* .post-sidebar .trending-posts.fixed{padding-right: 35px;}    */
    .wrapper2 .nft-meta .views-votes,
    .wrapper3 .nft-meta .views-votes {
        min-width: 83px;
    }

    .form-modal .custom-group {
        font-size: 15px;
    }

    .settings-wrap .main-btn-style,
    .personal-info .information form .btn-primary,
    .settings-sidenav ul li:last-child a {
        font-size: 15px;
        padding: 6px 17px;
    }

    .settings-sidenav ul li a {
        font-size: 17px;
    }

    .treasurebox-tabs ul.nav-tabs .nav-item .nav-link {
        border-radius: 7px;
        padding: 8px 20px;
        font-size: 16px;
    }

    .simple-layout {
        padding: 30px 0 20px 0;
    }

    .nft-sec1,
    .nft-sec2,
    .nft-sec3 {
        margin-bottom: 40px;
    }

    /* .nft-sec2 .wrapper2 .image{height: 250px;} */
    .post-details .post-img {
        /* height: 400px; */
    }

    .submissions-post .post-img {
        height: 275px;
    }

    .post-details .post-item .post-item-img {
        height: 460px;
    }

    .sub-post-img {
        height: 350px;
    }

    .new-trending .post-img {
        height: 270px;
    }

    .nft-meta .views svg {
        width: 18px !important;
    }

    .search-login-btn input {
        height: 39px;
    }

    .post-details .social-btns {
        font-size: 12px;
    }

    .post-details .social-btns button svg {
        /* width: 25px;
            height: 25px; */
    }

    .search-login-btn .search-icon {
        width: 39px;
        height: 39px;
    }

    .main-leaderboard .table-wrapper h2 {
        top: 22px;
    }

    .setting-wrapper {
        padding: 25px 17px;
    }

    .auther-views .name {
        font-size: 16px;
    }

    .main-leaderboard .table-wrapper,
    .activity-main .table-wrapper,
    .notification-section .table-wrapper {
        padding: 25px;
    }

    .activity-table .main-btn-style {
        font-size: 13px;
        padding: 4px 8px;
    }

    .post-details .social-btns button,
    .social-btns .facebook,
    .social-btns .twitter,
    .social-btns .tiktok,
    .social-btns .instagram,
    .social-btns .youtube {
        font-size: 12px !important;
        height: 38px;
        color: #fff;
    }

    .social-btns .instagram svg {
        width: 16px;
        height: 16px;
    }

    .main-wall {
        padding: 0px 12px 30px !important;
    }

    .static-content {
        padding: 35px 40px;
    }

    .parent-tag .dropdown-nav section::before {
        /* right: 40px; */
    }

    .static-content h2 {
        margin-bottom: 30px;
    }

    .footer-links-ul a {
        font-size: 15px;
    }

    .footer-sec .footer-wrapper p {
        font-size: 16px;
    }

    .post-details .preface {
        font-size: 20px !important;
    }

    .widget_create_post:before {
        font-size: 26px;
        width: 50px;
        height: 50px;
    }

    .widget_create_post:after {
        min-height: 155px;
    }

    .widget_create_post .text {
        font-size: 16px;
    }

    .widget_create_post .main-btn-style {
        font-size: 13px;
    }

    .nft-meta .author-name p span:nth-child(2) {
        font-weight: 500;
    }

    /* .post-sidebar .trending-posts.fixed{padding-right: 35px;}    */
    .wrapper1 {
        min-height: 350px;
    }

    .wrapper2 .nft-meta .views-votes,
    .wrapper3 .nft-meta .views-votes {
        min-width: 83px;
    }

    .form-modal .custom-group {
        font-size: 15px;
    }

    .settings-sidenav ul li a {
        font-size: 17px;
    }

    .treasurebox-tabs ul.nav-tabs .nav-item .nav-link {
        border-radius: 7px;
        padding: 8px 20px;
        font-size: 16px;
    }

    .simple-layout {
        padding: 30px 0 20px 0;
    }

    .nft-sec1,
    .nft-sec2,
    .nft-sec3 {
        margin-bottom: 40px;
    }

    /* .nft-sec2 .wrapper2 .image{height: 250px;} */
    .post-details .post-item .post-item-img {
        height: 460px;
    }

    .sub-post-img {
        height: 350px;
    }

    .new-trending .post-img {
        height: 270px;
    }

    .nft-meta .views svg {
        width: 18px !important;
    }

    .search-login-btn input {
        height: 39px;
    }

    .post-details .social-btns {
        font-size: 12px;
    }

    .post-details .social-btns button svg {
        /* width: 25px;
            height: 25px; */
    }

    .search-login-btn input,
    .search-login-btn .search-icon,
    .profile-notifications button {
        width: 40px;
        height: 40px;
        border-radius: 6px;
        font-size: 14px;
    }

    .treasurebox-header nav.navbar .main-btn-style {
        min-height: 40px;
        font-size: 14px;
        min-width: 40px;
    }

    .profile-notifications button {
        font-size: 14px;
    }

    .main-btn-style,
    .personal-info .information form .btn-primary,
    .main-btn-style:disabled {
        padding: 7px 20px;
    }

    .static-content {
        padding: 35px 40px;
    }

    .static-content h2 {
        margin-bottom: 30px;
    }

    .footer-links-ul a {
        font-size: 15px;
    }

    .footer-sec .footer-wrapper p {
        font-size: 16px;
    }

    .post-details .preface {
        font-size: 13px !important;
    }

    .widget_create_post:before {
        font-size: 26px;
        width: 50px;
        height: 50px;
    }

    .widget_create_post:after {
        min-height: 155px;
    }

    .widget_create_post .text {
        font-size: 16px;
    }

    .widget_create_post a {
        font-size: 15px;
    }

    .nft-meta .author-name p span:nth-child(2) {
        font-weight: 500;
    }

    .post-sidebar .trending-posts.fixed {
        padding-right: 35px;
    }

    .wrapper2 .nft-meta .views-votes,
    .wrapper3 .nft-meta .views-votes {
        min-width: 83px;
    }

    .form-modal .custom-group {
        font-size: 15px;
    }

    .settings-wrap .main-btn-style {
        padding: 5px 10px;
        font-size: 13px;
    }

    .settings-sidenav ul li a {
        font-size: 14px;
        padding: 7px;
    }

    .treasurebox-tabs ul.nav-tabs .nav-item .nav-link,
    .select-tabs-box button {
        border-radius: 7px;
        padding: 8px 20px;
        font-size: 16px;
    }

    #tabs-select .search-wrapper {
        padding: 8px;
    }

    .search-login-btn .input-no-width {
        width: 39px;
    }

    .parent-tag .dropdown-nav {
        width: 560px;
    }

    .nft-sec .inner-wrapper h3 {
        font-size: 17px;
    }

    .treasurebox-tabs ul.nav-tabs {
        /* padding: 13px; */
        /* padding-bottom: 6px; */
    }

    .nft-sec .nft-meta {
        /* padding: 8px 12px; */
        /* margin: -25px 13px 10px 13px; */
    }

    .upload-wrapper {
        width: 200px;
        height: 200px;
    }

    /* h2 {
        font-size: 30px;
        line-height: 40px;
    } */

    h3 {
        font-size: 19px;
        line-height: 25px;
    }

    .page-title h2 {
        /* padding: 16px 0; */
    }

    .main-dashboard .sidebar {
        max-width: 250px;
    }

    .section-dark-bg {
        padding: 20px;
    }

    .sidebar .logo {
        margin-bottom: 30px;
        padding: 0 15px;
    }

    .sidebar .logo .image {
        width: 40px;
        height: 40px;
        margin-right: 5px;
    }

    .sidebar-links li {
        /* margin-bottom: 10px; */
    }

    .sidebar-links li a {
        font-size: 12px;
        padding: 10px 15px;
    }

    .daily-rewards li {
        padding-bottom: 12px;
        margin-bottom: 15px;
    }

    .section-dark-bg .heading .view-all,
    .section.heading .view-all {
        font-size: 14px;
    }

    .activities ul li {
        padding: 12px 20px;
    }

    .section-dark-bg .heading,
    .section.heading {
        margin-bottom: 20px;
    }

    .points-list h3 {
        font-size: 24px;
    }

    .nft-list ul li {
        width: 50%;
    }

    .custom-padding {
        padding: 0 40px;
    }

    .header .navbar-nav .nav-link {
        padding: 25px 30px;
    }

    .btn-gradient-pink {
        min-width: 200px;
        margin-left: 30px;
    }

    .header .navbar-brand {
        font-size: 18px;
    }

    .badges .item .image {
        width: 100px;
    }

    .banner-title {
        padding: 30px 20px 25px;
        margin-bottom: 30px;
    }

    .banner-title h1 {
        font-size: 80px;
        line-height: 80px;
    }

    .banner-title h1 span {
        font-size: 30px;
        margin-bottom: -40px;
    }

    .banner-title p {
        font-size: 20px;
        margin-left: 50px;
    }

    .avatar-badge .avatar {
        margin-right: 20px;
    }

    .buyers-rewards .item .overview>p {
        margin-top: 10px;
        margin-bottom: 20px;
    }

    .more-items .others ul li span {
        font-size: 14px;
    }

    .main-content-area .page-title h2 {
        font-size: 28px;
        line-height: 36px;
        margin-bottom: 0px;
    }

    .coinmarket-rewards .image-box {
        max-width: 280px;
        margin-left: 100px;
        position: relative;
    }

    .coinmarket-rewards .image-box span {
        font-size: 20px;
        max-width: 150px;
        padding: 10px 15px;
    }

    .coinmarket-rewards .content .actions .btn-primary {
        height: 55px;
    }

    .fp-col1 .featured-header,
    .fp-col2 .f-col .featured-header {
        padding: 3%;
    }

    .promotion-wrapper img {
        height: 190px;
    }

    .slick-slide .nft-image img {
        /* height: 230px; */
    }

    .nft-image .sale {
        right: 16px;
        top: 16px;
    }

    .user-info .progress-stats h2 {
        font-size: 10px;
    }

    .treasurebox-header .nav-link:not(:last-child) {
        margin-right: 20px;
    }

    .treasurebox-header .nav-link {
        font-size: 14px;
    }

    .treasurebox-sec .heading h3 {
        font-size: 25px;
    }

    .treasurebox-sec .heading h1 {
        font-size: 80px;
    }



    .treasurebox-sec .box-lottie {
        width: 240px;
        height: 240px;
        margin-right: 70px;
    }

    .treasurebox-tabs ul.nav-tabs .nav-item .nav-link {
        /* margin-bottom: 5px; */
    }

    .nft-sec1 .nft-meta {
        /* margin: -20px 15px 10px 15px; */
    }

    .nft-sec1,
    .nft-sec2,
    .nft-sec3 {
        margin-bottom: 20px;
    }

    .footer-sec-padding {
        padding: 40px 35px 0px 35px;
    }

    .footer-links-wrapper h5 {
        margin-bottom: 20px;
    }

    .footer-sec .footer-wrapper p {
        margin-bottom: 40px;
    }

    .footer-links-col {
        margin-bottom: 20px;
    }

    .footer-links-ul li {
        margin-bottom: 10px;
    }

    .all-right-services {
        padding: 15px 0;
    }

    .join-discord h1 {
        font-size: 30px;
    }

    .join-discord h5 {
        font-size: 40px;
        line-height: 50px;
    }

    .join-discord {
        padding: 70px 0;
    }

    .wrapper1 .image {
        height: 295px;
    }

    .wrapper4 .image {
        width: 100%;
        /* height: 100% */
    }

    .main-leaderboard .table-wrapper h2 {
        top: 27px;
    }
    .categories-box ul li{margin: 0; margin-bottom: 4px;}
}

@media (max-width: 1199px) {
    .categories-box h3, .categories-box-top h3 {font-size: 17px;}
    .user-profile .profile-banner strong {font-size: 22px;letter-spacing: 40px;}
    .user-profile .user-info .field-wrapper{padding-left: 10px; width: 430px; height: 46px;}
    .user-profile .user-info .field-wrapper .copy-icon{width: 42px;}
    .treasurebox-sec{padding-top: 78px;}
    .rank-fixed {
        top: 85px;
    }

    .wrapper1 .post-bottom {
        bottom: -75px;
    }

    .wrapper1:hover .inner-wrapper .post-bottom {
        bottom: -73px;
    }

    .nft-sec2 .wrapper2.right {
        min-height: 405px;
    }

    .nft-sec2 .wrapper2.right:hover .image {
        height: 340px;
    }

    .public-DraftStyleDefault-block {
        font-size: 15px;
    }

    .referrals .referrals-body .content .referrals-box .box-num {
        width: 50px;
        height: 50px;
    }

    .nft-sec1 h2 {
        font-size: 22px;
        line-height: 26px;
    }
    h2 {
        font-size: 23px;
        line-height: 26px;
    }


    .user-profile-info {
        display: flex;
        justify-content: space-between;
    }

    .user-profile-info .info-wrap {
        width: 30%;
        margin: 0 7px 7px;
        padding: 6px 9px;
    }

    .upload-wrapper .upload-camera {
        width: 45px;
        height: 45px;
        font-size: 18px;
    }

    .upload-wrapper {
        width: 195px;
        height: 195px;
        /* margin: 15px auto; */
    }

    .personal-info .information .image-data .image {
        width: 170px;
        height: 170px;
    }

    .create-game-wrapper .form-btn-wrap .main-btn-style {
        font-size: 15px;
    }

    .wrapper2 .post-bottom,
    .wrapper1 .post-bottom {
        padding: 6px;
        border-radius: 10px;
    }

    .nft-sec2 .wrapper2.left .image {
        height: 260px;
    }

    .nft-sec2 .wrapper2.left {
        min-height: 290px;
    }

    .nft-sec2 .wrapper2.left:hover .inner-wrapper .image {
        height: 230px;
    }

    .categories-box-top {
        max-height: 310px;
    }

    .wrapper1 {
        min-height: 320px;
    }

    .success-box {
        width: 700px;
        padding: 40px 30px;
    }

    .create-game-wrapper .form-section {
        /* padding: 20px; */
    }

    .main-leaderboard .table-responsive {
        height: 470px;
    }

    .activity-main .table-responsive {
        height: 440px;
    }

    .settings-sidenav {
        height: 550px;
        /* margin-top: 0; */
        margin-bottom: 25px;
    }

    .settings-sidenav ul li:last-child {
        position: static;
    }

    .login-wrap .card .meta-tag .wallet-img {
        width: 60px;
        height: 60px;
    }

    .login-wrap .card {
        padding: 22px 30px;
        margin: 0 30px 5px;
    }

    .login-wrap .card .meta-tag h4 {
        margin: 0;
        font-size: 15px;
        text-align: center;
    }

    .single-main-container {
        flex-direction: column;
    }

    .post-content-wrapper {
        width: 100%;
    }

    .post-sidebar {
        width: 70%;
        margin-left: auto;
    }

    .trending-posts {
        padding: 15px;
    }

    .auther-views .views {
        font-size: 13px;
    }

    .auther-views .auther .image {
        width: 40px;
        height: 40px;
        margin-right: 12px;
    }

    .reaction-item .bb-badge .circle {
        width: 30px;
        height: 30px;
    }

    .post-details-content .trending-posts .new-trending .post-img {
        height: 200px;
    }

    .widget-title,
    .trending-posts .post-desc p {
        font-size: 15px;
    }

    .trending-posts .post-creater span:last-child,
    .post-tags {
        font-size: 13px;
    }

    .trending-posts .post-creater {
        margin-bottom: 0;
    }

    .submissions-post .title h3 {
        font-size: 16px;
    }

    .submissions-post .tags a {
        font-size: 13px;
    }

    .submission__control {
        width: 330px;
    }

    .setting-wrapper {
        border-radius: 16px;
    }

    .nft-sec4 .wrapper4 {
        height: 385px;
    }

    .rank-fixed .user-rank,
    .rank-fixed .user-points {
        font-size: 14px;
    }

    .rank-fixed,
    .rank-fixed .user-rank-no,
    .rank-fixed .user-points-no {
        height: 37px;
    }

    .fixed-section {
        top: 78px
    }

    .nft-sec2 .wrapper2.right .image {
        height: 380px;
    }

    ul.leaderboard-userdetails li strong {
        font-size: 16px;
    }

    ul.leaderboard-userdetails li span {
        font-size: 15px;
        /* display: block; */
    }

    .referrals-copy .copy-link {
        font-size: 12px;
    }

    .referrals {
        padding: 50px 30px 40px 30px;
    }

    .referrals-copy span,
    .referrals .referrals-body .content .referrals-box .box-icon,
    .field-wrapper .copy-icon {
        font-size: 18px;
    }

    .referrals .referrals-body .content .referrals-box .box-icon {
        right: 40px;
    }

    .referrals .referrals-body .content .referrals-box {
        margin-top: 25px;
    }

    .referrals .referrals-head h2 {
        font-size: 22px;
        line-height: 30px;
    }

    .referrals .referrals-body .content h2 {
        font-size: 20px;
        line-height: 28px;
    }

    .referrals .referrals-body .content .referrals-box strong {
        font-size: 15px;
    }

    .referrals .referrals-body .content .referrals-box {
        padding: 15px;
    }

    .referrals-copy span {
        width: 45px;
    }

    .referrals .referrals-body .content .referrals-box {
        margin-top: 15px;
    }

    .purchase-item .item .content h4 {
        font-size: 16px;
    }

    .wall-cards ul {
        flex-wrap: wrap;
    }

    .wall-cards ul li {
        width: 48%;
    }

    .parent-tag .dropdown-nav section::before {
        /* right: 30px */
    }

    .comment-form-comment .submit-post .btn {
        font-size: 14px;
    }

    .notification-detail::before,
    .parent-tag .dropdown-nav section::before {
        top: -10px;
    }

    .profile-notifications button {
        font-size: 15px;
    }

    .new-trending .post-img {
        height: 220px;
    }

    .create-game-wrapper .form-btn-wrap {
        /* padding-top: 15px; */
    }

    .post-details .post-img {
        /* height: 350px; */
    }

    .submissions-post .post-img {
        height: 300px;
    }

    .post-details .post-item .post-item-img {
        height: 370px;
    }

    .nft-sec .inner-wrapper h3 {
        font-size: 15px;
    }

    .sub-post-img {
        /* height: 300px; */
    }

    .wrapper1 {
        min-height: 335px;
    }

    .wrapper1:hover .inner-wrapper .image {
        height: 250px;
    }

    .wrapper1 .image {
        height: 305px;
    }

    .nft-sec2 .wrapper2 .image,
    .nft-sec3 .wrapper3 .image {
        height: 220px;
        border-radius: 8px;
    }

    .comment-form {
        padding: 10px 14px;
    }

    .parent-tag .dropdown-nav section ul {
        column-count: 3;
    }

    .treasurebox-header .main-btn-style {
        /* padding: 9px 9px; */
    }

    .treasurebox-header .navbar-brand {
        font-size: 16px;
    }

    .treasurebox-header .navbar-brand .image {
        width: 40px;
    }

    .treasurebox-header .nav-link:not(:last-child) {
        margin-right: 10px;
    }

    .post-sidebar .post-img {
        height: 365px;
    }

    .wrapper4 .image {
        height: 250px;
        border-radius: 8px;
    }

    .nft-sec2 .right .inner-wrapper {
        height: auto;
        min-height: auto;
    }

    h2 {
        font-size: 22px;
        line-height: 28px;
    }

    .others ul li span {
        font-size: 12px !important;
    }

    .level-images ul img {
        width: 98px !important;
    }

    body {
        font-size: 15px;
    }

    h4 {
        font-size: 18px;
        line-height: 26px;
    }

    .section-dark-bg .heading .view-all,
    .section.heading .view-all {
        font-size: 12px;
    }

    .header .navbar-nav .nav-link {
        padding: 20px 20px;
    }

    .header .navbar-brand {
        font-size: 15px;
    }

    .header .navbar-brand .image {
        width: 40px;
        height: 40px;
        margin-right: 5px;
    }

    .btn-gradient-pink {
        min-width: 180px;
        margin-left: 20px;
    }

    .points-list {
        margin-bottom: 20px;
    }

    .my-nfts .nft-list ul li {
        width: 50%;
    }

    .section-dark-bg {
        padding: 20px 15px;
    }

    .image .timer {
        padding: 6px 10px;
    }

    .nft-list ul li .name {
        margin-bottom: 10px;
    }

    .task-rewards {
        margin-top: 50px;
    }

    .game-list ul li {
        width: 49%;
        margin-bottom: 10px;
    }

    .game-list ul li.treasury-box {
        width: 49%;
    }

    .collectibles-list ul li {
        width: 32%;
    }

    .nft-list ul li {
        width: 48%;
    }

    .nft-list ul li .image {
        height: 200px;
    }

    .custom-padding {
        padding: 0 20px;
    }

    .collection-list ul li {
        width: 100%;
        margin-bottom: 20px;
    }

    .collection-list ul li:last-child {
        margin-bottom: 0;
    }

    .user-stats ul li:nth-child(2),
    .user-stats ul li:last-child {
        padding-left: 15px;
    }

    .notifications {
        flex-direction: column;
    }

    .notifications .list {
        padding-right: 0;
        margin-bottom: 20px;
    }

    .notice {
        max-width: 100%;
        width: 100%;
    }

    .setting-wrapper {
        padding-right: 15px;
    }

    .field-wrapper,
    .personal-info .information form .form-control {
        height: 50px;
    }

    .banner-title h1 {
        font-size: 60px;
        line-height: 60px;
    }

    .banner-title h1 span {
        font-size: 22px;
        margin-bottom: -25px;
    }

    .banner-title p {
        font-size: 18px;
        margin-left: 30px;
    }

    .coinmarket-rewards .content {
        padding-right: 0;
    }

    .coinmarket-rewards .content p {
        margin-top: 20px;
        margin-bottom: 25px;
    }

    .coinmarket-rewards .content .actions .btn-primary {
        height: 55px;
        padding: 10px 20px;
        margin-right: 20px;
    }

    .coinmarket-rewards .image-box {
        max-width: 250px;
        margin-left: 50px;
    }

    .next-rewards-list ul {
        flex-wrap: wrap;
    }

    .next-rewards-list ul li {
        flex: inherit;
        cursor: pointer;
    }

    .next-rewards-list ul li .image {
        height: 60px;
        margin-top: 10px;
        margin-bottom: 15px;
    }

    .next-rewards-list .title {
        max-width: 400px;
        padding: 15px;
        margin-top: -57px;
    }

    /* .avatar-badge .image {
            height: 70px;
            
            width: 50px;
        } */

    .avatar-badge .avatar {
        margin-right: 10px;
    }

    .buyers-rewards .item .overview {
        padding-left: 20px;
    }

    .promotion-modal .modal-dialog {
        max-width: 800px;
    }

    .promotion-modal .modal-content {
        border-radius: 20px;
        padding: 60px 0;
        background-color: transparent;
    }

    .promotion-modal .modal-content .image {
        width: 150px;
        height: 150px;
    }

    .promotion-modal .modal-content .content .action-btn {
        margin-top: 30px;
    }

    .promotion-modal .modal-content .content .btn-primary {
        padding: 10px 15px;
        font-size: 14px;
    }

    .masonry-item .content-tags {
        padding: 12px 24px;
    }

    .user-info .progress-stats h2 {
        font-size: 12px;
    }

    .treasurebox-header .nav-link:not(:last-child) {
        margin-right: 10px;
    }

    .treasurebox-header .nav-link {
        font-size: 12px;
    }

    .search-login-btn .search-icon {
        /* margin-right: 10px; */
        cursor: pointer;
    }

    .search-click {
        margin-right: 10px;
    }

    .treasurebox-sec .heading h1 {
        font-size: 60px;
    }

    .treasurebox-sec .box-lottie {
        width: 210px;
        height: 210px;
        margin-right: 20px;
    }

    .treasurebox-tabs ul.nav-tabs {
        justify-content: unset;
    }

    .treasurebox-tabs ul.nav-tabs .nav-item .nav-link {
        margin-right: 10px;
    }

    .footer-sec-padding {
        padding: 30px 12px 0px 12px;
    }

    .treasurebox-tabs ul.nav-tabs .nav-item .nav-link {
        font-size: 16px;
    }

    .nft-sec4 .wrapper4 {
        width: 25%;
    }

    .join-discord h1 {
        font-size: 25px;
    }

    .join-discord h5 {
        font-size: 30px;
        line-height: 40px;
    }

    .join-discord {
        padding: 50px 0;
    }

    .nft-sec2 .col-lg-6:nth-child(1) {
        margin-bottom: 20px;
    }

    .nft-sec4 .wrapper4 {
        width: 32%;
    }

    .footer-sec-padding .col-lg-3 {
        /* margin-bottom: 20px; */
    }
    .user-profile .user-name .user-bio{font-size: 16px;}
    .user-profile .user-name .unique-user{font-size: 17px;}
}

@media (max-width: 991.98px) {
    .show-btn{font-size: 16px;}
    .categories-box ul li{margin-bottom: 6px;}
    .comment-body .comment-user-img{width: 35px; height: 35px;}
    .water-mark-card{bottom: 37px;}
    .water-mark{width: 47px !important; height: 47px !important;}
    .user-profile .profile-banner strong {font-size: 20px;letter-spacing: 30px;}
    .user-profile .profile-banner{height: 250px;}
    .user-profile .user-name .user-bio{max-width: 60%;}
    .user-profile .user-name .user-bio{font-size: 15px;}
    .user-profile .user-name .unique-user{font-size: 16px;}
    .user-profile .user-dp {
        width: 130px;
        height: 130px;
        margin: 0 auto;
    }
    .user-profile .user-name strong {
        font-size: 22px;
    }
    .user-profile .user-info li {
        font-size: 16px;
        margin-right: 18px;
    }
    .treasurebox-sec{padding-top: 68px;}
    .confirmation-modal p {
        font-size: 16px;
    }

    .not-found.sec4-not-found {
        min-height: 350px;
    }

    .nft-sec2 .wrapper2.right:hover .image {
        height: 310px;
    }

    .wrapper2.right .post-bottom {
        top: calc(100% + -91px);
    }

    #notification-wrapper .toast-notification span {
        font-size: 12px;
    }

    .nft-sec1 {
        padding-bottom: 15px;
    }

    .treasurebox-tabs .tabs-wrap .select-tabs-box .tags-select .react-dropdown-select-dropdown .react-dropdown-select-item {
        margin: 5px;
        padding: 7px 13px;
        font-size: 14px;
        border-radius: 6px;
    }

    .treasurebox-tabs .tabs-wrap .select-tabs-box .tags-select .react-dropdown-select-content input {
        font-size: 13px;
        font-family: "Montserrat";
    }

    .treasurebox-tabs .tabs-wrap .select-tabs-box .tags-select .react-dropdown-select-content {
        padding: 10px 39px;
    }

    .tabs-wrap.active .select-tabs-box button.close-select {
        right: 13px;
    }

    .tabs-wrap .select-tabs-box button.close-select {
        width: 20px;
        height: 20px;
        font-size: 12px;
    }

    .treasurebox-tabs .tabs-wrap .select-tabs-box .tags-select .react-dropdown-select-content::before {
        font-size: 17px;
        left: 13px;
    }

    .settings-sidenav ul li:last-child {
        margin-top: 18px;
    }

    /* .settings-sidenav{background: #323239;} */
    .category-hiding-btn-top h3, .category-hiding-btn-top {
        display: none;
    }

    .fixed-section-bg.show {
        background: transparent;
    }

    .video-wrap .upload-camera .icon svg {
        font-size: 17px;
        color: #2cfafe;
    }

    .create-game-wrapper .form-modal .form-wrapper {
        padding: 0 20px;
    }

    .success-popup .modal-dialog {
        max-width: 600px;
    }

    .success-popup .modal-dialog .modal-content {
        min-height: 360px;
    }

    .upload-wrapper {
        width: 220px;
        height: 220px;
        margin: 0 auto;
    }

    .create-game-wrapper .form-modal .form-wrapper .fixed-side {
        border-right: 0;
        border-bottom: 1px solid #ffffff26;
    }

    .create-game-wrapper .form-modal .scroll-side {
        height: 100%;
    }

    .create-game-wrapper .form-btn-wrap .main-btn-style {
        padding: 8px 18px;
    }

    .not-found .post-img {
        width: 80px;
        height: 80px;
    }

    .not-found p {
        font-size: 15px;
    }



    .purchase-item .item {
        padding: 12px 60px 12px 17px;
    }

    .purchase-item .item::after {
        right: 15px;
        font-size: 20px;
    }

    .purchase-item .item .image {
        width: 25px;
        height: 25px;
    }

    .wrapper2 .post-bottom,
    .wrapper1 .post-bottom {
        padding: 10px;
    }

    .post-details .questions-container h4,
    .post-details .questions-container h6 {
        font-size: 15px;
    }

    .footer-sec .gamification-logo span:first-child {
        width: 40px;
        height: 40px;
    }

    /* .nft-sec2 .wrapper2{} */
    .nft-sec2 .wrapper2.left {
        min-height: 350px;
        margin-bottom: 15px;
    }

    .nft-sec2 .wrapper2.right {
        min-height: 380px;
    }

    .wrapper2 .post-bottom {
        opacity: 1;
        top: calc(100% + -88px);
    }

    .nft-sec2 .wrapper2.left:hover .inner-wrapper .image {
        height: 290px;
    }

    .nft-sec2 .wrapper2.left .inner-wrapper .image {
        height: 290px;
    }

    .category-hiding-btn-top:hover .categories-box-top {
        top: 30px;
    }

    .wrapper1 {
        min-height: 400px;
    }

    .success-box {
        width: 600px;
        padding: 35px 25px;
    }

    .settings-sidenav {
        height: auto;
        border-color: #323239;
        margin-top: 0;
    }

    .point-btn {
        margin-right: 10px;
    }

    .search-login-btn input {
        opacity: 1;
    }

    .active.btn-wrapper-share .edit-boost {
        right: 20px;
        top: -13px;
        display: block;
    }

    .btn-wrapper-share .edit-boost button,
    .post-details .tags a {
        padding: 4px;
        font-size: 10px;
    }

    .profile-notifications .nav-link {
        margin: 0 !important;
    }

    .nft-meta-trending {
        display: flex !important;
    }

    .trending-sidebar-wrapper {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }

    .trending-sidebar-wrapper a {
        width: 47%;
    }

    .trending-posts {
        padding: 25px;
    }

    .post-sidebar #trend {
        width: 100%;
    }

    .post-details-content .trending-posts .new-trending .post-img {
        height: 260px;
    }

    .wall-cards ul li .single-card {
        min-height: 100px;
    }

    .main-leaderboard .table-responsive::-webkit-scrollbar {
        width: 5px;
    }

    .main-leaderboard .table-wrapper,
    .activity-main .table-wrapper,
    .notification-section .table-wrapper {
        padding: 20px;
    }

    .main-leaderboard .table-wrapper .table {
        border-spacing: 0 8px;
    }

    .nft-sec4 .wrapper4 {
        /* height: 355px; */
        height: auto;
    }

    .personal-info .information form .form-control {
        height: 45px;
    }

    .categories-box {
        margin-bottom: 20px;
        overflow: hidden;
    }

    .rank-fixed,
    .rank-fixed .user-rank-no,
    .rank-fixed .user-points-no {
        height: 35px;
    }

    .rank-fixed .user-rank-no,
    .rank-fixed .user-points-no,
    .rank-fixed .user-rank,
    .rank-fixed .user-points {
        font-size: 13px;
    }

    .rank-fixed .user-rank,
    .rank-fixed .user-points {
        padding: 7px 10px;
    }

    /* .nft-sec2 .wrapper2.right .image {height: 300px;} */
    .leaderboard-userdetails {
        /* max-width: 300px; */
    }

    .referrals .referrals-body .content .referrals-box .box-icon {
        right: 40%;
    }

    .wall-cards ul li {
        width: 47%;
    }

    .static-content ul li h4 {
        margin-bottom: 10px;
    }

    .static-content h2 {
        margin-bottom: 20px;
    }

    .static-content {
        padding: 30px 30px;
    }

    .footer-wrapper {
        margin-bottom: 20px;
    }

    .wrapper4 .image {
        height: 230px;
    }

    .widget_create_post {
        width: 600px;
        max-width: 100%;
    }

    .post-sidebar .trending-posts.fixed {
        padding-right: 0;
        padding-top: 30px;
    }

    .post-sidebar-secondray .categories-box.fixed,
    .post-sidebar .trending-posts.fixed {
        position: static;
    }

    .post-sidebar .trending-posts.fixed,
    .post-sidebar .trending-posts {
        width: 100% !important;
    }

    .notification-detail,
    .notification-detail::before {
        right: 0;
        transform: none;
        left: auto;
    }

    .notification-detail::before {
        right: 8px;
    }

    .settings-wrap {
        /* padding: 40px 0 30px 0; */
    }

    .post-details .post-img {
        /* height: 420px; */
    }

    .submissions-post .post-img {
        height: 275px;
    }

    .post-details .post-item .post-item-img {
        height: 330px;
    }

    .new-trending .post-img {
        height: 270px;
    }

    .user-profile-info .info-wrap {
        width: 30%;
        margin: 0 7px 7px;
        padding: 6px 9px;
    }

    .treasurebox-tabs ul.nav-tabs .nav-item .nav-link {
        font-size: 15px;
    }

    .post-sidebar .post-img {
        height: 380px;
        max-width: 600px;
        width: 100%;
    }

    .footer-sec-padding {
        padding: 30px 12px 0px 12px;
    }

    .post-content-wrapper .post-sidebar-secondray,
    .post-content-wrapper .post-main-content.post-content-wrapper .post-main-content,
    .post-content-wrapper .post-main-content {
        width: 100%;
    }

    .post-sidebar {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }

    .post-content-wrapper {
        display: flex;
        flex-direction: column;
        width: 100%;
    }

    .personal-info .information .image-data .image {
        width: 150px;
        height: 150px;
    }

    .custom-modal .modal-content .modal-body .modal-title {
        font-size: 22px;
    }

    .post-details .post-img video {
        /* height: 300px; */
    }

    .nft-sec4 .wrapper4 {
        width: 32%;
    }

    .footer-sec-padding .col-lg-3 {
        /* margin-bottom: 20px; */
    }

    .fixed-section {
        top: 68px;
    }

    .parent-tag .dropdown-nav {
        transform: translateX(0);
    }

    .static-content ul li h4 {
        margin-bottom: 10px;
    }

    .static-content h2 {
        margin-bottom: 20px;
    }

    .static-content {
        padding: 30px 30px;
    }

    .footer-wrapper {
        margin-bottom: 20px;
    }

    .wrapper4 .image {
        height: 230px;
    }

    .widget_create_post {
        width: 100%;
    }

    .post-sidebar .trending-posts.fixed {
        padding-right: 0;
        padding-top: 30px;
    }

    .post-sidebar-secondray .categories-box.fixed,
    .post-sidebar .trending-posts.fixed {
        position: relative;
    }

    .post-sidebar .trending-posts.fixed,
    .post-sidebar .trending-posts {
        width: 100% !important;
    }

    .notification-detail,
    .notification-detail::before {
        right: 0;
        transform: none;
        left: auto;
    }

    .notification-detail::before {
        right: 8px;
    }

    .settings-wrap {
        /* padding: 115px 0 30px 0; */
    }

    .post-details .post-item .post-item-img {
        height: 330px;
    }

    .new-trending .post-img {
        height: 270px;
    }



    .treasurebox-tabs ul.nav-tabs .nav-item .nav-link {
        font-size: 15px;
        border-radius: 5px;
    }

    .wrapper1 .post-bottom,
    .wrapper1:hover .inner-wrapper .post-bottom {
        opacity: 1;
        bottom: -75px;
    }

    .nft-sec1 .wrapper1 .image,
    .nft-sec2 .wrapper2 .image,
    .nft-sec3 .wrapper3 .image,
    .nft-sec2 .wrapper2.right .image {
        height: 310px;
    }

    .wrapper1:hover .inner-wrapper .image {
        height: 310px;
    }

    .post-sidebar .post-img {
        height: 280px;
        max-width: 100%;
        width: 100%;
    }

    .post-content-wrapper .post-sidebar-secondray {
        width: 100%;
        float: unset;
        margin-bottom: 20px;
    }

    .post-content-wrapper .post-sidebar-secondray,
    .post-content-wrapper .post-main-content.post-content-wrapper .post-main-content,
    .post-content-wrapper .post-main-content {
        width: 100%;
    }

    .post-sidebar {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }

    .post-content-wrapper {
        /* display: flex;
            flex-direction: column;
            width: 100%; */
    }

    .custom-modal .modal-content .modal-body .modal-title {
        font-size: 22px;
    }

    .post-details .post-img video {
        /* height: 300px; */
    }

    .parent-tag .dropdown-nav section::before {
        left: 35px;
        /* border-color: transparent transparent #11D6B9 transparent; */
        border-color: transparent transparent #fff transparent;
    }

    .parent-tag .dropdown-nav {
        left: 0;
        padding-top: 8px;
    }

    .search-login-btn .input-width {
        /* width: 450px; */
        padding: 5px 35px 5px 10px;
    }

    .treasurebox-header .navbar-nav {
        display: block !important;
    }

    .search-login-btn .search-btn-block,
    .search-login-btn .refresh-search {
        /* margin-left: 6px;
            width: 39px;
            height: 39px;
            display: flex !important;
            justify-content: center;
            align-items: center;
            padding: 9px; */
    }

    .search-login-btn .search-btn-block {
        background: transparent;
        border: 2px solid #1554F0;
        color: #fff;
        font-size: 12px;
    }

    .treasurebox-header nav.navbar .nav-btn.main-btn-style {
        /* padding: 10px 20px; */
        /* margin-left: 0 !important; */
    }

    .search-login-btn .search-icon {
        margin-left: 6px;
        height: 39px;
        width: 39px;
        font-size: 16px;
    }

    .search-login-btn .refresh-search {
        top: 83px;
        right: 0;
        /* position: static;
            background: #1554F0;
            color: #fff; */
    }

    .treasurebox-header nav.navbar .nav-btn.main-btn-style {
        padding: 8px;
        width: 40px;
        height: 40px;
        justify-content: center;
        align-items: center;
        display: flex;
        border-radius: 6px;
    }

    .search-login-btn input {
        background: #fff;
        color: #000;
    }

    .treasurebox-header .nav-link {
        font-size: 14px;
        margin-bottom: 14px;
    }

    .treasurebox-header .nav-link:not(:last-child) {
        margin-right: 0;
    }

    .parent-tag .treasurebox-header .nav-link {
        margin-bottom: 8px;
        width: fit-content;
    }

    .parent-tag .treasurebox-header .nav-link {
        margin-bottom: 0;
    }

    .nft-sec3 .inner-wrapper {
        min-height: auto;
        height: auto;
    }

    .nft-sec3 .wrapper3 {
        min-height: auto;
        margin-bottom: 12px;
    }

    .sd-matrixdynamic__footer .sd-action {
        /* margin-left: 1px !important; */
    }

    .create-game-wrapper .form-btn-wrap {
        /* padding-top: 15px; */
        /* padding-bottom: 10px; */
    }

    .sd-action.sd-paneldynamic__btn.sd-action--negative.sd-paneldynamic__remove-btn {
        margin-bottom: 10px !important;
    }

    .others ul li span {
        font-size: 12px;
    }

    .level-images ul img {
        width: 100px;
    }

    .header .navbar-collapse {
        position: absolute;
        left: 0;
        top: 60px;
        width: 100%;
        background: #000;
        z-index: 9;
        padding-bottom: 20px;
    }

    .header .navbar-collapse .btn-gradient-pink {
        margin: 0 auto;
        width: calc(100% - 40px);
    }

    .header .navbar-nav .nav-link {
        padding: 10px 20px;
    }

    .main-dashboard .main-content-area {
        padding-top: 0;
        position: relative;
    }

    .main-dashboard .sidebar,
    .main-dashboard .right-sidebar {
        max-width: 100%;
        margin-bottom: 20px;
        position: relative;
    }

    .main-dashboard {
        padding: 0 15px;
        max-width: 500px;
        margin-left: auto;
        margin-right: auto;
    }

    .sidebar .logo {
        margin-bottom: 0;
    }

    .toggle-button {
        display: flex;
    }

    .sidebar-links {
        display: none;
        position: absolute;
        width: calc(100% - 30px);
        left: 15px;
        background: #000;
        z-index: 9;
        padding: 20px;
        top: 100px;
    }

    .sidebar.active .sidebar-links {
        display: block;
    }

    .header .navbar-brand {
        font-size: 16px;
    }

    .header .navbar-brand .image {
        width: 30px;
        height: 30px;
    }

    .header .custom-padding {
        padding: 10px 15px;
    }

    .header .navbar-toggler {
        color: rgba(0, 0, 0, 0.55);
        border-color: rgb(255 252 252 / 55%);
        padding: 3px 7px;
    }

    .main-profile {
        max-width: 500px;
        margin-left: auto;
        margin-right: auto;
    }

    .notifications .list {
        padding-right: 0;
        margin-bottom: 20px;
        overflow: hidden;
        overflow-x: auto;
    }

    .left-sidebar {
        margin-bottom: 20px;
    }

    .main-dashboard .main-content-area .container-fluid {
        padding: 0;
    }

    .notifications {
        padding-right: 0;
    }

    .personal-info .information {
        flex-direction: column;
    }

    .personal-info .information .image {
        margin: 0 auto 15px;
    }

    .field-wrapper {
        height: 50px;
        padding: 0px 50px 0px 12px;
    }

    .field-wrapper .copy-icon {
        width: 40px;
    }

    .field-wrapper p {
        font-size: 12px;
    }

    .personal-info .information form .form-control {
        /* padding: 5px 15px; */
        font-size: 13px;
        /* height: 45px; */
    }

    .setting-wrapper {
        padding-right: 12px;
        border-radius: 12px;
    }

    .main-dashboard .sidebar {
        min-height: auto;
    }

    .breadcrum.daily {
        margin-left: 0;
    }

    .coinmarket-rewards {
        flex-wrap: wrap;
        padding: 0;
    }

    .coinmarket-rewards .content {
        width: 100%;
        flex: inherit;
    }

    .coinmarket-rewards .image-box {
        max-width: 250px;
        margin: 20px auto 40px;
    }

    .next-rewards-list ul {
        justify-content: flex-start;
    }

    .next-rewards-list ul li {
        margin-bottom: 20px;
        padding: 0 10px;
        width: 25%;
    }

    .buyers-rewards .item {
        margin-top: 30px;
        padding-bottom: 20px;
        flex-direction: column;
    }

    .avatar-badge {
        margin-bottom: 20px;
        width: 100%;
    }

    .buyers-rewards .item .overview {
        padding-left: 0;
        width: 100%;
    }

    h2 {
        font-size: 22px;
        line-height: 32px;
    }

    .filters .btn-secondary {
        padding: 5px 15px;
    }

    .contest-card {
        max-width: 400px;
        margin: 30px auto;
        height: auto;
    }

    .avatar-badge .image {
        height: 100px;
        width: 80px;
        margin-bottom: 10px;
    }

    .promotion-modal .modal-dialog {
        max-width: 650px;
    }

    .promotion-modal .modal-content {
        padding: 30px 0;
    }

    .promotion-modal .modal-content .image {
        width: 120px;
        height: 120px;
    }

    .promotion-modal .modal-content .content p,
    .promotion-modal .modal-content .content li {
        font-size: 14px;
    }

    .posts-myntist .container1 .featured-area-wrapper {
        flex-direction: column;
        height: unset;
    }

    .posts-myntist .container1 .featured-area-wrapper .col1 {
        width: 100%;
        margin-bottom: 10px;
    }

    .posts-myntist .container1 .featured-area-wrapper .fp-col2 {
        width: 100%;
    }

    .treasurebox-header .navbar-toggler {
        color: rgba(0, 0, 0, 0.55);
        border-color: rgb(255 252 252 / 55%);
        padding: 3px 6px;
    }

    .treasurebox-header .navbar-toggler-icon {
        filter: invert(1);
    }

    .treasurebox-header .navbar-nav {
        align-items: flex-start !important;
    }

    .treasurebox-header .navbar-brand .image {
        width: 40px;
        margin-right: 7px;
    }

    .navbar-collapse {
        position: absolute;
        top: 100%;
        width: 100%;
        background: #181515;
        padding: 14px;
        z-index: 9;
        border-radius: 0 0 15px 15px;
    }

    .treasurebox-sec .box-lottie {
        width: 180px;
        height: 180px;
    }

    .treasurebox-header nav.navbar {
        padding-top: 10px;
    }

    .search-login-btn .input-no-width {
        /* width: 450px; */
    }

    /* .search-login-btn .search-icon{margin-left: auto;} */
    .search-login-btn input {
        /* position: static; */
    }

    .search-login-btn {
        /* margin-bottom: 20px; */
        position: static;
    }

    .search-login-btn input {
        top: 80px;
    }

    .search-login-btn .input-no-width {
        padding: 8px 10px;
        opacity: 0;
    }

    .main-leaderboard .table-wrapper {
        /* padding: 80px 30px 40px; */
    }

    .main-leaderboard .table-wrapper::before {
        /* height: 70px; */
    }

    .main-leaderboard .table-wrapper::after {
        /* height: 25px; */
    }

    .main-leaderboard .table-wrapper h2 {
        top: 14px;
    }
}

@media (max-width: 767px) {
    .user-comment-name{font-size: 14px;}
    .user-written-comment{font-size: 13px;}
    .comment-body .date-edit{font-size: 10px;}
    .comment-body .comment-details{margin-left: 17px;}
    .comment-body .comment-user-img, .replies .comment-body .comment-user-img{width: 32px; height: 32px;}
    .user-profile .profile-banner strong {font-size: 19px;letter-spacing: 16px;}
    .user-profile .user-name strong{font-size: 18px;}
    .user-profile .profile-banner{height: 230px;}
    .posts-header .submission__control{margin-bottom: 0; width: 330px; max-width: 100%;}
    .user-profile .user-name .user-bio{max-width: 100%;}
    .user-profile .user-info .field-wrapper{width: 100%;}
    .btn-wrapper-share:hover .dots-hover{font-size: 13px; min-width: 95px;}
    .video-button::before {
        width: 43px;
        height: 43px;
        font-size: 22px;
    }

    .wrapper2.right .video-button::before {
        top: 35%;
    }

    .not-found.sec4-not-found {
        min-height: 300px;
    }

    .nft-sec2 .wrapper2.right:hover .image {
        height: 255px;
    }

    .login-wrap .card .meta-tag h4 {
        font-size: 14px;
    }

    .create-game-wrapper .form-section .custom-select__control .custom-select__indicators .custom-select__clear-indicator {
        display: none;
    }

    .notification-detail ul li a {
        font-size: 13px;
    }

    .submission__control {
        width: 100%;
    }

    .treasurebox-header .nav-link {
        font-size: 13px;
    }

    .success-popup .modal-dialog .modal-content .modal-body .main-btn-style {
        padding: 9px 16px;
    }

    .success-popup .modal-dialog .modal-content .connect-btn {
        width: 30px;
        height: 30px;
    }

    .success-popup .modal-dialog .modal-content .connect-btn svg {
        font-size: 18px;
    }

    .success-popup .modal-dialog .modal-content .modal-body .modal-title h2 {
        margin-bottom: 12px;
    }

    .success-popup .modal-dialog {
        max-width: 560px;
    }

    .success-popup .modal-dialog .modal-content {
        min-height: 330px;
    }

    .select-tabs-box button {
        width: 37px;
        height: 37px;
        padding: 4px;
        font-size: 15px;
    }

    .create-game-wrapper .form-modal .section-title {
        padding: 20px;
    }

    .create-game-wrapper .form-btn-wrap .main-btn-style {
        font-size: 14px;
    }

    .nft-sec4 h2.category-h {
        /* font-size: 16px; */
    }

    .nft-sec2 .wrapper2.left .inner-wrapper .image,
    .nft-sec2 .wrapper2.left:hover .inner-wrapper .image {
        height: 255px;
    }

    .nft-sec2 .wrapper2.left {
        min-height: 320px;
    }

    .nft-sec .inner-wrapper h3 {
        font-size: 14px;
    }

    .sd-action {
        padding: 5px 8px !important;
        font-size: 13px !important;
    }

    .category-hiding-btn-top h3 {
        font-size: 14px;
    }

    .category-hiding-btn-top h3::after {
        right: 0;
        top: 0;
        font-size: 17px;
    }

    .profile-notifications .notifi-digit {
        width: 18px;
        height: 18px;
    }

    .profile-notifications button {
        padding: 3px 7px;
        /* border-radius: 4px; */
    }

    .treasurebox-header .navbar-toggler img {
        width: 25px;
        height: 25px;
    }

    .main-leaderboard .table-wrapper .table td,
    .main-leaderboard .table-wrapper .table th,
    .activity-main .table td,
    .activity-main .table th,
    .notification-section .table-wrapper .table td,
    .notification-section .table-wrapper .table th {
        padding: 8px;
    }

    .post-details-content .trending-posts .new-trending .post-img {
        height: 180px;
    }

    .post-details-content .trending-posts {
        padding: 15px;
    }

    .submission__control {
        margin-left: auto;
        margin-bottom: 20px;
    }

    .wall-cards ul li .single-card .card-icon span {
        font-size: 22px;
    }

    .settings-wrap {
        padding-top: 25px;
    }

    .wrapper1 {
        min-height: 340px;
        margin-bottom: 12px;
    }

    .wall-cards ul li {
        width: 46%;
    }

    .filter-dropdown {
        width: 260px;
    }

    .filter-dropdown .submission__control {
        min-height: 44px !important;
    }

    .filter-dropdown .submission__control .submission__value-container {
        min-height: 42px;
    }

    ul.leaderboard-userdetails {
        display: block;
    }

    ul.leaderboard-userdetails li span,
    ul.leaderboard-userdetails li strong {
        font-size: 14px;
        margin-bottom: 5px;
    }

    .treasurebox-header .nav-link {
        margin-bottom: 8px;
        padding-left: 0;
    }

    .treasurebox-header .nav-link:hover::before {
        display: none;
    }

    .main-wall {
        padding: 0px 12px 20px !important;
    }

    .treasurebox-tabs ul.nav-tabs {
        width: 100%;
    }

    .treasurebox-tabs .tabs-wrap {
        /* display: block; */
    }

    .select-tabs-box {
        /* display: flex;
            justify-content: end; */
    }

    .point-btn,
    .like-count {
        width: 35px;
    }

    .footer-sec .footer-wrapper h5 {
        margin-bottom: 10px;
    }

    .footer-sec .footer-wrapper h5 {
        margin-bottom: 10px;
    }

    .footer-sec .footer-wrapper p {
        margin-bottom: 20px;
    }

    .all-right-services {
        padding: 8px 0;
    }

    .post-details .post-img {
        /* height: 350px; */
    }

    .post-sidebar .post-img {
        height: 205px;
    }

    .submissions-post .post-img {
        height: 220px;
        border-radius: 10px;
    }

    .submissions-post {
        border-radius: 10px;
    }

    .nft-sec1 .wrapper1 .image {
        height: 250px;
        margin-bottom: 10px;
    }

    .nft-sec1 .wrapper1:hover .inner-wrapper .image {
        height: 250px;
    }

    .nft-sec3 .wrapper3 .image,
    .nft-sec2 .wrapper2.right .image {
        height: 255px;
    }

    .nft-sec2 .wrapper2.right {
        min-height: 320px;
    }

    .post-details .post-item .post-item-img {
        height: 280px;
    }

    .nft-sec .nft-meta {
        /* margin-left: 0; margin-right: 0; */
    }

    .post-details .post-img video {
        /* height: 250px; */
    }

    .parent-tag .dropdown-nav {
        width: 430px;
    }

    .wrapper3 .image {
        height: 300px;
    }

    .treasurebox-tabs ul.nav-tabs .nav-item .nav-link {
        padding: 5px 10px;
        /* border-radius: 8px; */
    }

    .upload-wrapper {
        width: 190px;
        height: 190px;
    }

    .sd-element--with-frame:not(.sd-element--collapsed) {
        padding: 0 !important;
    }

    .main-content-area select {
        width: 100%;
    }

    .achievement-content {
        flex-wrap: wrap;
        width: 100%;
    }

    .filters {
        flex-direction: column;
    }

    .filters .custom-picker {
        margin-top: 20px;
    }

    h2 {
        font-size: 20px;
        line-height: 35px;
    }

    .treasurebox-sec .heading h3 {
        font-size: 20px;
    }

    .treasurebox-sec .heading h1 {
        font-size: 40px;
    }

    .heading-img-sec {
        flex-direction: column;
        align-items: flex-start !important;
    }

    .treasurebox-sec .box-lottie {
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
        width: 100%;
    }

    .nft-sec1 .wrapper1 .image img,
    .nft-sec2 .wrapper2 .image img {
        width: 100%;
    }

    .nft-sec1 .nft-meta {
        /* margin: -20px 5px 8px 5px;
        padding: 10px 12px; */
    }

    .nft-sec3 .wrapper3 .image:nth-child(1) {
        margin-bottom: 10px;
    }

    .nft-sec4 .wrapper4 {
        width: 49%;
    }

    .main-leaderboard .table-wrapper {
        /* padding: 60px 20px 30px; */
    }

    .main-leaderboard .table-wrapper::before {
        /* height: 50px; */
    }

    .main-leaderboard .table-wrapper h2 {
        top: 5px;
    }
}

@media (max-width: 575px) {
    .show-btn{font-size: 15px;}
    .comment-body .comment-details{margin-left: 12px;}
    .comment-body .comment-user-img, .replies .comment-body .comment-user-img{width: 30px; height: 30px;}
    .points-tooltip{width: 220px; font-size: 13px !important; padding: 7px !important;}
    .user-profile .user-name .status{font-size: 13px;}
    .user-profile .user-name strong {
        font-size: 16px;
    }
    .user-profile .user-info li {
        font-size: 14px;
        margin-right: 12px;
    }
    .user-profile .user-dp {
        width: 110px;
        height: 110px;
    }
    .user-profile .treasurebox-sec{margin-top: 20px;}
    .user-profile .profile-banner{height: 200px;}
    .posts-header {
        padding: 16px 12px 0px;
    }
    .posts-header .submission__control{width: 100%;}
    .btn-wrapper-share:hover .dots-hover{font-size: 12px; min-width: 90px;}
    .video-button::before {
        top: 34%;
    }

    .simple-layout.layout3 .treasurebox-sec {
        padding-top: 50px;
    }

    .rank-fixed {
        top: 75px;
    }

    .comment-list .replies {
        padding-left: 0;
    }

    .nft-sec2 .wrapper2.right:hover .image {
        height: 300px;
    }

    .nft-sec2 .wrapper2.right {
        min-height: 360px;
    }

    .login-wrap .card {
        margin: 0 15px 5px;
        padding: 16px;
    }

    .login-wrap .card .meta-tag h4 {
        font-size: 12px;
    }

    #notification-wrapper .toast-notification {
        display: none;
    }

    .create-game-wrapper .form-btn-wrap .main-btn-style {
        font-size: 13px;
    }

    .treasurebox-tabs .tabs-wrap .select-tabs-box .tags-select .react-dropdown-select-content {
        padding: 12px 39px;
    }

    .notification-detail ul li a {
        padding-bottom: 10px;
    }

    .notification-detail ul li a,
    .nofifications-table a {
        font-size: 12px;
    }

    .notification-section .table-wrapper .table td {
        padding: 5px;
    }

    .notification-detail h5 {
        padding: 14px 10px;
        font-size: 15px;
    }

    .notification-detail ul li {
        padding: 10px 20px 10px 25px;
    }

    .time-since {
        font-size: 11px;
    }

    .social-btns .tiktok {
        margin-right: 0;
    }

    .treasurebox-tabs .tabs-wrap {
        align-items: center;
        padding: 5px;
    }

    .treasurebox-tabs .tabs-wrap .select-tabs-box .tags-select .react-dropdown-select-content {
        font-size: 13px;
        font-family: "Montserrat";
    }

    .treasurebox-tabs .tabs-wrap .select-tabs-box .tags-select .react-dropdown-select-dropdown .react-dropdown-select-item {
        margin: 4px;
        padding: 6px 11px;
        font-size: 11px;
        border-radius: 5px;
    }

    .treasurebox-header .nav-link {
        font-size: 12px;
    }

    .category-hiding-btn-top h3::after {
        right: 0px;
        font-size: 15px;
    }

    .fixed-section {
        top: 58px;
    }

    .create-game-wrapper .form-modal .form-wrapper {
        padding: 0 15px;
    }

    .success-popup .modal-dialog .modal-content {
        min-height: 310px;
        background-size: cover;
    }

    .purchase-item .item .image {
        width: 20px;
        height: 20px;
        margin-right: 20px;
    }

    .purchase-item .item::after {
        right: 8px;
        font-size: 18px;
    }

    .purchase-item .item {
        padding: 10px 45px 10px 15px;
    }

    .purchase-item .item .content p {
        font-size: 13px;
    }

    .purchase-item .item .content h4 {
        font-size: 14px;
        line-height: 18px;
    }

    .search-login-btn input,
    .search-login-btn .search-icon,
    .profile-notifications button,
    .treasurebox-header .navbar-toggler {
        width: 35px;
        height: 35px;
        border-radius: 5px;
        font-size: 12px;
        border-width: 1px;
    }

    .search-login-btn .search-btn-block,
    .search-login-btn .search-icon {
        border-width: 1px;
        width: 35px;
        min-width: 35px;
        height: 35px;
    }

    .treasurebox-header nav.navbar .main-btn-style {
        min-height: 35px;
        min-width: 35px;
        padding: 5px;
        font-size: 13px;
    }

    .treasurebox-header nav.navbar .nav-btn.main-btn-style {
        padding: 5px;
        border-radius: 5px;
        width: 35px;
        height: 35px;
    }

    .nft-sec4 h2.category-h {
        /* font-size: 15px; */
    }

    .search-login-btn input,
    .search-login-btn .refresh-search {
        top: 70px;
    }

    .search-login-btn .input-width {
        width: 100%;
    }

    .sd-action {
        padding: 3px 6px !important;
        font-size: 12px !important;
        border-radius: 4px !important;
    }

    .form-modal h2,
    .form-modal h5 {
        margin-bottom: 10px;
    }

    .login-wrap .card .meta-tag {
        flex-direction: column;
    }

    .category-hiding-btn-top:hover .categories-box-top {
        width: 260px;
        padding: 0;
        top: 30px;
    }

    .success-box {
        padding: 20px;
    }

    .rank-fixed,
    .rank-fixed .user-rank-no,
    .rank-fixed .user-points-no {
        height: 31px;
    }

    .login-wrap .card .meta-tag .wallet-img {
        width: 50px;
        height: 50px;
        margin-bottom: 15px;
        margin-right: 0;
    }

    .login-wrap .card {
        padding: 20px 25px;
    }

    .connect-wallet .modal-content .modal-body {
        padding: 12px;
    }

    .post-details .social-btns #app-main {
        /* width: 100%; */
    }

    .like-count,
    .social-btns .facebook,
    .social-btns .twitter,
    .social-btns .tiktok,
    .social-btns .instagram,
    .social-btns .youtube {
        width: 38px;
        color: #fff
    }

    .modal-social-btns .facebook,
    .modal-social-btns .twitter,
    .modal-social-btns .tiktok,
    .modal-social-btns .instagram,
    .modal-social-btns .youtube {
        width: fit-content;
        color: #fff
    }

    .post-details .social-btns button,
    .social-btns .facebook,
    .social-btns .twitter,
    .social-btns .tiktok,
    .social-btns .instagram,
    .social-btns .youtube {
        margin-right: 8px;
    }

    .trending-posts {
        padding: 15px;
    }

    .post-details .auther-views .share-menu {
        background-color: transparent;
        border-radius: 0;
        padding: 0;
    }

    .reaction-stat-count {
        margin-left: 10px;
    }

    .reaction-stat-count span {
        font-size: 15px;
        font-weight: 600;
    }

    .post-content-wrapper {
        padding: 0;
    }

    .post-details-content .trending-posts .new-trending .post-img {
        height: 260px;
    }

    .categories-box-top::after {
        display: none;
    }

    .main-leaderboard .table-wrapper,
    .activity-main .table-wrapper,
    .notification-section .table-wrapper {
        padding: 17px;
    }

    .information form .name-row,
    .information form .email-row {
        width: 100%;
        margin-bottom: 10px;
    }

    .information form label {
        margin-bottom: 4px;
    }

    .personal-info .information form textarea {
        height: 175px !important;
    }

    .nft-sec4 .wrapper4 {
        height: auto;
    }

    .wrapper4 .tags,
    .wrapper1 .tags {
        height: auto;
    }

    .treasurebox-header .navbar-brand {
        font-size: 14px;
    }

    .treasurebox-header .navbar-brand .image {
        width: 30px;
    }

    .nft-sec1 .wrapper1 .image {
        height: 300px;
        margin-bottom: 10px;
    }

    .nft-sec1 .wrapper1:hover .inner-wrapper .image {
        height: 300px;
    }

    .wrapper1 {
        min-height: 390px;
    }

    .nft-sec1 {
        margin-bottom: 15px;
    }

    .rank-fixed .rank-wrap,
    .rank-fixed .points-wrap {
        justify-content: space-between;
        height: 27px;
    }

    .rank-fixed .d-flex:first-child {
        border-bottom: 1px solid #868686;
    }

    .rank-fixed .user-rank-no,
    .rank-fixed .user-points-no,
    .rank-fixed .user-rank,
    .rank-fixed .user-points {
        font-size: 11px;
        /* width: 70px; */
    }

    .treasurebox-sec {
        /* padding: 105px 5px 0; */
    }

    .treasurebox-sec {
        /* padding-top: 120px; */
    }

    .treasurebox-sec-home {
        /* padding-top: 25px; */
    }

    .settings-wrap {
        padding-top: 25px;
    }

    .treasurebox-tabs ul li {
        margin: 4px;
    }

    .treasurebox-sec .heading h3 {
        font-size: 17px;
    }

    .treasurebox-sec .heading h1 {
        font-size: 34px;
    }

    .leaderboard-userdetails {
        /* max-width: 270px; */
    }

    .settings-wrap .main-btn-style,
    .personal-info .information form .btn-primary {
        font-size: 12px;
    }

    .main-btn-style,
    .personal-info .information form .btn-primary,
    .settings-sidenav ul li:last-child a .main-btn-style:disabled {
        font-size: 14px;
        border-width: 1px;
    }

    .referrals-copy {
        border-width: 1px;
        font-weight: 400;
        padding-left: 10px;
        min-height: 48px;
    }

    .referrals-copy span {
        width: 36px;
        font-size: 16px;
    }

    .referrals .referrals-body {
        padding: 25px 17px;
    }

    .referrals {
        padding: 35px 20px 30px 20px;
    }

    .referrals .referrals-head h2 {
        font-size: 20px;
    }

    .referrals .referrals-body .content h2 {
        font-size: 18px;
    }

    .referrals .referrals-body .content .referrals-box .box-icon {
        right: 30%;
    }

    .wall-cards ul li {
        width: 100%;
    }

    .wall-cards ul {
        /* display: block !important; */
    }

    .wall-cards ul li .single-card {
        min-height: 100px;
    }

    .custom-modal .modal-content .modal-body .main-btn-style {
        font-size: 14px !important;
        border-width: 1px;
    }

    .point-btn,
    .like-count {
        width: 40px;
    }

    .static-content {
        padding: 30px 15px;
    }

    .footer-sec-padding {
        padding: 20px 8px 0px 8px;
    }

    .create-game-wrapper .form-section {
        /* padding: 20px 14px; */
    }

    .notification-detail {
        /* width: 400px; */
    }

    .post-sidebar .post-img {
        height: 300px;
    }

    .trending-sidebar-wrapper {
        display: block;
    }

    .trending-sidebar-wrapper a {
        width: 100%;
    }

    .post-details .post-img{
        /* height: 300px; */
    }
    .sub-post-img {
        height: 300px;
    }

    .submissions-post .post-img {
        height: 300px;
    }

    .post-details .post-item .post-item-img {
        height: 260px;
    }

    .user-profile-info {
        display: block;
    }

    .user-profile-info .info-wrap {
        width: 100%;
        margin-left: 0;
        margin-right: 0;
    }

    .nft-sec2 .wrapper2.left .inner-wrapper .image,
    .nft-sec2 .wrapper2.left:hover .inner-wrapper .image {
        height: 300px;
    }

    .nft-sec2 .wrapper2.left {
        min-height: 365px;
    }

    .nft-sec2 .wrapper2.right .image,
    .wrapper3 .image,
    .wrapper4 .image,
    .nft-sec2 .wrapper2 .image {
        height: 300px;
        /* height: 260px; */
    }

    .wrapper4 .image video,
    .wrapper4 .image img {
        /* object-fit: contain; */
    }

    .nft-sec .nft-meta {
        /* margin-left: 12px;
        margin-right: 12px; */
    }

    .nft-sec2 .nft-meta {
        flex-wrap: wrap;
    }

    .search-login-btn .input-no-width {
        width: 100%;
        height: 38px;
    }

    .post-details .post-img video {
        /* height: 230px; */
    }

    .create-game-wrapper label.sd-context-btn.sd-file__choose-btn--icon::after,
    .create-game-wrapper .sd-context-btn--negative::after {
        display: none !important;
    }

    .social-btns .facebook,
    .social-btns .twitter,
    .social-btns .tiktok,
    .social-btns .instagram,
    .social-btns .youtube {
        margin-bottom: 10px;
        padding: 3px 8px !important;
        color: #fff
    }

    .social-btns {
        /* display: block !important; */
    }

    .search-click {
        margin-right: 0;
        margin-bottom: 10px;
    }

    .sd-element--nested.sd-element--complex>.sd-element__header--location-top:after {
        display: none !important;
    }

    .level-images .image img {
        margin: 0px 5px;
        width: 90px;
        display: flex;
        align-items: center;
        justify-content: space-around;
    }

    .others ul li span {
        font-size: 8px !important;
    }

    .custom-modal-content .form-check-input[type=checkbox] {
        width: 50px;
        height: 42px;
    }

    .custom-modal-content label {
        font-size: 11px;
    }

    .sidebar .logo .image {
        width: 40px;
        height: 40px;
        margin-right: 5px;
    }

    h5 {
        font-size: 16px;
        line-height: 24px;
    }

    .sidebar .logo .name span {
        font-size: 12px;
        opacity: 0.5;
    }

    .toggle-button {
        width: 40px;
        height: 35px;
    }

    .toggle-button span {
        width: 20px;
        height: 2px;
        margin-bottom: 5px;
    }

    .section-dark-bg {
        padding: 15px;
    }

    .my-nfts .nft-list ul li,
    .nft-list ul li {
        width: 100%;
        margin-bottom: 40px;
    }

    .game-list ul li {
        padding: 15px;
        width: 100%;
        padding-bottom: 50px;
    }

    .game-list ul li h3 {
        height: auto;
        margin-bottom: 10px;
        font-size: 20px;
        line-height: 30px;
    }

    .game-list ul li.treasury-box {
        width: 100%;
    }

    .collectibles {
        margin-bottom: 20px;
    }

    .collectibles .section-dark-bg {
        height: auto;
    }

    .collectibles-list ul li {
        width: 48%;
    }

    .stats-sec {
        margin-top: 20px;
    }

    .stats-sec .section-dark-bg .heading {
        flex-direction: column;
        align-items: flex-start;
    }

    .stats-sec .section-dark-bg .heading h4 {
        margin-bottom: 20px;
    }

    .collection-sec {
        margin-top: 20px;
    }

    .notifications .list ul {
        width: 450px;
    }

    .notice {
        padding: 15px;
    }

    .nft-list ul li .image {
        height: 250px;
    }

    .field-wrapper {
        height: 45px;
        padding: 0px 40px 0px 10px;
    }

    .wallet-address .field-wrapper p {
        /* overflow: hidden; */
    }

    .main-content-area .page-title h2 {
        font-size: 24px;
        margin-bottom: 20px;
    }

    .banner-title {
        padding: 15px;
        margin-bottom: 30px;
        flex-wrap: wrap;
    }

    .banner-title h1 {
        font-size: 50px;
        line-height: 50px;
    }

    .banner-title p {
        font-size: 16px;
        margin-left: 0;
        margin-top: 20px;
        text-align: center;
    }

    .next-rewards-list ul li {
        width: 50%;
        padding: 0;
        margin-bottom: 30px;
    }

    .next-rewards-list {
        padding: 30px 15px;
    }

    h2 {
        font-size: 20px;
        line-height: 30px;
    }

    h3 {
        font-size: 17px;
        line-height: 25px;
    }

    .posts-myntist .container1 .featured-area-wrapper .col2,
    .posts-myntist .container1 .featured-area-wrapper .col3,
    .posts-myntist .container1 .featured-area-wrapper .col4,
    .posts-myntist .container1 .featured-area-wrapper .col5 {
        width: 100%;
    }

    .featured-header {
        font-size: 12px;
    }

    .setting-wrapper .address-wrapper {
        flex-direction: column;
    }

    .address-wrapper .share-btns {
        width: 100%;
        padding-left: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 10px;
    }

    .address-wrapper .copy-wrapper {
        width: 100%;
    }

    .setting-wrapper {
        padding: 25px 12px;
    }

    .padding25 {
        /* padding: 0 12px; */
    }

    .nft-sec1,
    .nft-sec2,
    .nft-sec3 {
        margin-bottom: 15px;
    }

    .nft-sec4 .wrapper4 {
        width: 100%;
        margin: 0 auto 20px;
    }

    .nft-sec4 .wrapper4 .image {
        margin-bottom: 15px;
    }

    .nft-sec4 .wrapper4 .nft-meta {
        margin-bottom: 7px;
    }

    .join-discord h1 {
        font-size: 20px;
    }

    .join-discord h5 {
        font-size: 25px;
        line-height: 35px;
    }

    .footer-sec .footer-wrapper p {
        margin-bottom: 20px;
    }

    .footer-sec .footer-wrapper h5 {
        margin-bottom: 15px;
    }

    .footer-links-wrapper h5 {
        margin-bottom: 10px;
    }

    .footer-sec .footer-wrapper p {
        font-size: 14px;
    }

    .footer-links-ul a {
        font-size: 13px;
    }

    .main-leaderboard .table-wrapper::after {
        height: 15px;
    }

    .main-leaderboard .table-wrapper h2 {
        top: 2px;
    }

    .post-details .preface {
        font-size: 16px !important;
    }
    .categories-box ul li{margin: 0; margin-bottom: 4px;}
}

@media (max-width: 475px) {
    .points-tooltip{width: 200px; font-size: 12px !important; padding: 5px !important;}
    .user-profile .user-info li{font-size: 13px;}
    .nft-sec2 .wrapper2.right:hover .inner-wrapper .post-bottom {
        top: calc(100% + -106px);
    }

    .nft-sec2 .wrapper2.right:hover .image {
        height: 280px;
    }

    .wrapper2.right .post-bottom {
        top: calc(100% + -106px);
    }

    .login-wrap .card {
        margin: 0 0 5px;
    }

    .create-game-wrapper .form-btn-wrap .main-btn-style {
        font-size: 12px;
    }

    /* .nft-sec4 h2{font-size: 16px; line-height: 20px;}
        .nft-sec4 h2.category-h {
            font-size: 13px;
        } */
    .rank-fixed .user-rank-no,
    .rank-fixed .user-points-no {
        min-width: 45px;
    }

    /* .rank-fixed .user-rank, .rank-fixed .user-points{padding: 4px 5px;} */
    .rank-fixed {
        /* display: block;
            height: auto; */
    }

    .wrapper1 {
        min-height: 340px;
    }

    .nft-sec2 .wrapper2.left {
        min-height: 310px;
    }

    .post-details .post-img{
        /* height: 210px; */
    }
    .sub-post-img {
        height: 210px;
    }

    .post-details-content .trending-posts .new-trending .post-img {
        height: 190px;
    }

    .nft-sec2 .wrapper2.right .image,
    .wrapper3 .image,
    .wrapper4 .image,
    .nft-sec2 .wrapper2 .image {
        height: 280px;
    }

    .footer-sec .navbar-brand {
        margin-bottom: 16px;
    }

    .post-sidebar .post-img {
        height: 230px;
    }

    .notification-detail {
        /* width: 260px; */
    }

    .setting-wrapper h3 {
        font-size: 15px;
    }

    .nft-sec1 .wrapper1 .image,
    .nft-sec1 .wrapper1:hover .inner-wrapper .image,
    .nft-sec2 .wrapper2.left .inner-wrapper .image,
    .nft-sec2 .wrapper2.left:hover .inner-wrapper .image {
        height: 250px;
    }

    .parent-tag .dropdown-nav {
        width: 320px;
    }

    .level-images ul img {
        width: 75px !important;
    }

    .level-images ul li .image span {
        display: flex;
        align-items: start;
        justify-content: start;
    }

    .social-btns {
        /* flex-direction: column; */
    }

    .social-btns div {
        /* width: 100%;
        margin-bottom: 10px;
        display: flex;
        justify-content: center; */
    }

    .social-btns div:nth-child(1) {
        /* margin-right: 0 !important; */
    }

    .notification-detail {
        width: 255px;
    }

    .notification-detail ul li {
        padding: 5px 18px 5px 25px;
    }

    .notification-detail h5,
    .see-all-activities {
        padding: 10px;
    }
}

@media (max-width: 400px) {
    .points-tooltip{width: 190px;}
    .custom-modal .modal-content .modal-header,
    .connect-wallet .modal-content .modal-header {
        margin-bottom: 25px;
    }

    .sd-element--nested.sd-panel:not(.sd-element--collapsed) {
        width: 400px;
        overflow-x: auto !important;
    }

    .search-login-btn .search-btn-block,
    .search-login-btn .search-icon,
    .treasurebox-header nav.navbar .nav-btn.main-btn-style,
    .profile-notifications button,
    .treasurebox-header .navbar-toggler {
        width: 32px;
        height: 32px;
        font-size: 12px;
        margin-left: 5px !important;
    }

    .treasurebox-header nav.navbar .main-btn-style {
        min-width: 32px;
        min-height: 32px;
        font-size: 12px;
    }

    .category-hiding-btn-top h3 {
        font-size: 13px;
    }

    .active.btn-wrapper-share .edit-boost {
        right: 10px;
    }

    .submissions-post .post-img {
        height: 250px;
    }

    .connect-wallet .modal-header .modal-title {
        margin-right: 24px;
    }

    .notification-detail {
        /* width: 230px; */
    }

    .post-sidebar .post-img {
        height: 170px;
    }

    .level-images ul img {
        width: 60px !important;
    }

    .level-images ul {
        display: flex;
        align-items: center;
        justify-content: space-evenly !important;
    }

    .heading h4 {
        font-size: 16px;
    }

    .score-content .d-flex {
        flex-direction: column;
    }

    .notification-detail {
        right: -30px;
    }

    .notification-detail::before {
        right: 33px;
    }
}

@media (max-width: 325px) {
    .notification-detail {
        width: 200px;
    }

    .parent-tag .dropdown-nav {
        width: 270px;
    }

    .level-images ul img {
        width: 55px !important;
    }
}